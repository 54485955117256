import React, { useState, useEffect } from "react";
import { activeUsersPath } from "./BreadcrumbData";
import SettingsNav from "./SettingsNav";
import SettingsHeader from "./SettingsHeader";
import { getAccountDetails } from "../../services/registrationService";
import NewUserForm from "./NewUserForm";
import ExistingUser from "./ExistingUser";
import Breadcrumbs from "./Breadcrumbs";

const SettingsUsers = (props) => {
  const [companyKey] = useState(props.companyKey);
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const resp = await getAccountDetails({ companyKey });
        if (resp.data.data) {
          const users = Object.values(resp.data.data.Users);
          setUsers(users);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [companyKey]);

  const handleAddUser = () => {
    setForm(!form);
  };

  return (
    <div className='settings-container'>
      {/* Aside */}
      <SettingsNav />
      {/* Main */}
      <main className='settings-view'>
        {/* Header  */}
        <SettingsHeader />
        <div className='container-fluid settings-main'>
          {/* breadcrumbs  */}
          <Breadcrumbs items={activeUsersPath} />

          <div className='row profile'>
            <div className='row container-fluid'>
              <div className='col-sm-2 profile-label'>Active Users</div>
              <div className='col-sm-2 offset-sm-7'>
                <span className='add-user' onClick={handleAddUser}>
                  {" "}
                  + Add User
                </span>
              </div>
            </div>
            {form && <NewUserForm handleAddUser={handleAddUser} notify={props.notify}/>}
            <ExistingUser users={users} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default SettingsUsers;
