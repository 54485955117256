import React from 'react';
import Accordion from './Accordion/Accordion';

function RenderMarketAttr({mpFields, dragStart, selectedMp}) {
  const setObj = () => {
     if(selectedMp){
         return { selectedMp : mpFields[selectedMp]}
     }
     else 
        return mpFields
  }
  const obj = setObj()
  return (
    <div className="map-col_mp">
        {Object.entries(obj).map((mp, key)=> {
            return( 
              <Accordion 
                title={mp[0]}
                fields={mp[1]}
                dragStart={dragStart}
                key={key}
                type={'mp'}
                selectedMp={selectedMp}
              />
            )
        })}
    </div>
  );
}

export default RenderMarketAttr;