import React from "react"
import ImageContainer from "./ImageContainer"
import ScoreIcon from "../../ScoreIcon"
import { setNumbers } from "../../../../common-functions/setNumbers"
import Links from "./Links"
import LoadingContent from "../../../common/LoadingContent"

function ProductIntro({vitalInfoObject, links, score, images, currencySymbol, dataPoints,loadingStatus}) {
  return (
    <div className='container-fluid product-intro-container'>
      <div className='row row_1'>
        <div className='col-12 col-md-5 col-lg-2 mt-3'>
          <ImageContainer images={images} />
        </div>
        <div className='col-9 col-md-5 col-lg-9 mt-3'>
          <h5>{vitalInfoObject.title.value ? vitalInfoObject.title.value : 'N/A' }</h5>
          <h6>{vitalInfoObject.brand.value}</h6>
          <Links links={links}/>
          <p>{dataPoints["Age"]}</p>
          <p>
             <span className="label">First Sold At : </span>
             <span className="value">{dataPoints["First Sold At"]}</span>
             <span className="label">Last Sold At : </span>
             <span className="value">{dataPoints["Last Sold At"]}</span>
          </p>
        </div>
        <div className='col-3 col-md-2 col-lg-1 score'>
          {loadingStatus === true ? null : (
            <ScoreIcon
              category={dataPoints["Product Category"]}
              scoreDetails={score}
              zoom={"zoom_in"}
            />
          )}
        </div>
      </div>
      <div className='row row_2'>
        <div className='col-12 col-md-3 row_2-cols'>
            <div className='data_points'>
                {loadingStatus ? 
                    <LoadingContent 
                        width={'65%'} 
                        marginTop={'10px'} 
                        height={'10px'} 
                        marginLeft={'30px'}/>
                    :
                    <h5>
                      {currencySymbol}
                      {setNumbers(dataPoints["Average Sale Price"])}
                    </h5>
                }
                {loadingStatus ? 
                    <LoadingContent 
                        width={'50%'} 
                        marginTop={'10px'} 
                        height={'10px'} 
                        marginLeft={'30px'}/>
                    :
                    <p>Avg. Sale Price</p>
                }
              </div>
          
        </div>
        <div className='col-12 col-md-3 row_2-cols'>
              <div className='data_points'>
                {loadingStatus ? 
                    <LoadingContent 
                        width={'65%'} 
                        marginTop={'10px'} 
                        height={'10px'} 
                        marginLeft={'30px'}/>
                  :
                  <h5>
                    {currencySymbol}
                    {setNumbers(dataPoints["Average Cost Price"])}
                  </h5>
                }
                {loadingStatus ? 
                    <LoadingContent 
                        width={'50%'} 
                        marginTop={'10px'} 
                        height={'12px'} 
                        marginLeft={'30px'}/>
                    :
                  <p>Avg. Cost Price</p>
                }
              </div>
        </div>
        <div className='col-12 col-md-3 row_2-cols'>
              <div className='data_points'>
                {loadingStatus ? 
                  <LoadingContent 
                      width={'65%'} 
                      marginTop={'10px'} 
                      height={'10px'} 
                      marginLeft={'30px'}/>
                  :
                    <h5>{dataPoints["Return on Investment %"]}%</h5>
                }
                {loadingStatus ? 
                    <LoadingContent 
                        width={'50%'} 
                        marginTop={'10px'} 
                        height={'10px'} 
                        marginLeft={'30px'}/>
                    :
                    <p>Return on Investment</p>
                }
              </div>
        </div>
        <div className='col-12 col-md-3 row_2-cols'>
            <div className='data_points'>
              {loadingStatus ? 
                <LoadingContent 
                    width={'65%'} 
                    marginTop={'10px'} 
                    height={'10px'} 
                    marginLeft={'30px'}
                    
                />
                :
                <h5>{dataPoints["Rate of Sale"]}</h5>
              }
              {loadingStatus ? 
                    <LoadingContent 
                        width={'50%'} 
                        marginTop={'10px'} 
                        height={'10px'} 
                        marginLeft={'30px'}
                        marginBottom={'12px'}
                    />
                    :
                <p>Rate of Sale</p>
              }
            </div>
        </div>
      </div>
    </div>
  );
}

export default ProductIntro;
