import React, {useState} from 'react';
import { Image } from 'react-bootstrap';

function ErrorPage(props) {
  const {errorType} = props

  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const DisplayImage = () => {
     if(errorType ==="server"){
        setTitle('Server Error')
        setMessage('Sorry, we could not upload your data.')
        return <Image src={require('../../assets/server-error.PNG')} />
     }
     else if(errorType === 'Not found'){
        setTitle('404 Error')
        setMessage('Sorry, the page is not found.')
        return <Image 
                  src={require('../../assets/not-found.PNG')} 
                  style={{width:'300px', marginLeft:'100px'}} 
                />
     }
     return null
  }

  return (
    <div className="container-fluid error-page">
        <div className="row headers">
            <h4>{title}</h4>
            <h5>{message}</h5>
            <a href="/dashboard">Try again</a>
        </div>
        <div className="row img-display">
            <DisplayImage />
        </div>
    </div>
  );
}

export default ErrorPage;