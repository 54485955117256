import React, { useEffect } from 'react';
import ProductCardTemplate from './Card';

function ProductCard({products_array, loadingDataPoints, currencySymbol, enlargeImage, onProductSelect}) {

  useEffect(() => {
  },[products_array])

  if(products_array.length === 0)
      return null

  return (
    products_array.map((product, key) => {
      return (
        <ProductCardTemplate
            product={product}
            enlargeImage={enlargeImage}
            loadingDataPoints={loadingDataPoints}
            onProductSelect={onProductSelect}
            id={product.baseSku}
            currencySymbol={currencySymbol}
        />
    )})
  )
  
}

export default ProductCard;