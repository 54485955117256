import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import ReactTooltip from 'react-tooltip';
import { setNumbers } from '../../common-functions/setNumbers';

function ScoreIcon(props) {
	const [scoreDetails, setScoreDetails] = useState({})
  const goodScoreColor = '#4caf50'
  const poorScoreColor = '#f44336'
	const avgScoreColor = '#f4c75b'
	const mpTotal = 10
	const salesTotal = 60
	const returnsTotal = 10
	const invTotal = 40

	useEffect(() => {
		setScoreDetails({...props.scoreDetails})
	}, [props.scoreDetails])

	const BarrenStyles = {
		zoom_in : {
			scoreStyle :{
				top: '29px',
				right: '15px'
			},
			iconStyle: {
				top: '0px',
				right: '25px'
			}
		},
		zoom_out: {
			scoreStyle :{
				top: '27px',
				right: '25px'
			},
			iconStyle: {
				top: '6px',
				right: '30px'
			}
		}
	}

	const TreeStyles = {
		zoom_in: {
			scoreStyle :{
				top: '37px',
				right: '18px'
			},
			iconStyle: {
				top: '6px',
				right:'30px'
			}
		},
		zoom_out: {
			scoreStyle :{
				top: '27px',
				right: '25px'
			},
			iconStyle: {
				top: '6px',
				right:'30px'
			}
		}
	}

	const SproutStyles = {
		zoom_in: {
			scoreStyle :{
				top: '32px',
				right: '26px'
			},
			iconStyle: {
				top: '6px',
				right: '30px'
			}
		},
		zoom_out : {
			scoreStyle :{
				top: '22px',
				right: '26px'
			},
			iconStyle: {
				top: '6px',
				right: '30px'
			}
		}
	}

	const SeedStyles = {
		zoom_in: {
			scoreStyle :{
				top: '14px',
				right: '35px'
			},
			iconStyle: {
				top: '6px',
				right: '0px'
			}
		},
		zoom_out: {
			scoreStyle :{
				top: '10px',
				right: '43px'
			},
			iconStyle: {
				top: '6px',
				right: '20px'
			}
		}
	}

	const addBackground = (score) => {
			if(score > 75 ){
					return goodScoreColor
			}
			else if(score <= 75 && score > 60)
					return avgScoreColor
			else 
					return poorScoreColor
	}

  const setProductIcon = (scoreDetails) => {
		const score = scoreDetails.Score
		const {category, zoom, _id} = props

		if (category === 'Barren') {
			return (
				<React.Fragment>
					<span
							key={_id}
							className={`color-div-score  ${zoom === 'zoom_in' ? `zoom` : ``}`}
						  style={{...BarrenStyles[zoom].scoreStyle, background: addBackground(score)}}
							data-tip 
							data-for={'score-composition_'+_id}>
						  {score} {setComposition(scoreDetails, _id)}
					</span>
					<span className={`category-icon  ${zoom === 'zoom_in' ? `zoom` : ``}`} style={{...BarrenStyles[zoom].iconStyle}}>
						<Image src={require('../../assets/barren.PNG')} thumbnail />
					</span>
				</React.Fragment>
			)
		}
		else if (category === 'Tree') {
			return (
				<React.Fragment>
					<span
							key={_id}
							className={`color-div-score  ${zoom === 'zoom_in' ? `zoom` : ``}`}
							style={{...TreeStyles[zoom].scoreStyle, background: addBackground(score)}}
							data-tip 
							data-for={'score-composition_'+_id}
					>
						{score} {setComposition(scoreDetails, _id)}
					</span>
					<span className={`category-icon  ${zoom === 'zoom_in' ? `zoom` : ``}`} style={{...TreeStyles[zoom].iconStyle}}>
						<Image src={require('../../assets/tree.PNG')} thumbnail />
					</span>
				</React.Fragment>
			)
		}
		else if (category === 'Seed') {
			return (
				<React.Fragment>
					<span
						key={_id}
						className={`color-div-score  ${zoom === 'zoom_in' ? `zoom` : ``}`}
						style={{...SeedStyles[zoom].scoreStyle, background: addBackground(score)}}
						data-tip 
						data-for={'score-composition_'+_id}
					>
						{score} {setComposition(scoreDetails, _id)}
					</span>
					<span className={`category-icon  ${zoom === 'zoom_in' ? `zoom` : ``}`} style={{...SeedStyles[zoom].iconStyle}}>
						<Image src={require('../../assets/seed.PNG')} thumbnail />
					</span>
				</React.Fragment>
			)
		}
		else if (category === 'Sprout') {
			return (
				<React.Fragment>
					<span
						key={_id}
						className={`color-div-score ${zoom === 'zoom_in' ? `zoom` : ``}`}
						style={{...SproutStyles[zoom].scoreStyle, background: addBackground(score)}}
						data-tip 
						data-for={'score-composition_'+_id}
					>
						{score} {setComposition(scoreDetails, _id)}
					</span>
					<span className={`category-icon ${zoom === 'zoom_in' ? `zoom` : ``}`} style={{...SproutStyles[zoom].iconStyle}}>
						<Image src={require('../../assets/sprout.PNG')} thumbnail />
					</span>
					
				</React.Fragment>
			)
		}
		else {
			return null
		}
	}

	const getScorePercent = (type, scoreDetails) => {
			if(type ==='marketplace'){
				  if(scoreDetails['scoreAvailability'])
						  return setNumbers(scoreDetails['scoreAvailability']['value']/mpTotal) *100+'%'
				  return 'N/A'
			}
			else if(type === 'sales'){
				 if((scoreDetails['3 Days Sales'] && scoreDetails['7 Days Sales'] && scoreDetails['30 Days Sale'])){
						 let value = getValues(scoreDetails['3 Days Sales'], scoreDetails['7 Days Sales'], scoreDetails['30 Days Sale'], salesTotal)
						 return value+'%'
				 }
				 return 'N/A'
			}
			else if(type === 'inventory'){
				if(scoreDetails['Broken Products'] && scoreDetails['Inventory Score'] && scoreDetails['30 Days Stockout']){
						let value = getValues(scoreDetails['Broken Products'], scoreDetails['Inventory Score'], scoreDetails['30 Days Stockout'], invTotal)
						return value +'%'
				}
				return 'N/A'
			}
			else if(type === 'returns'){
				if(scoreDetails['score30Retention'])
						return setNumbers(scoreDetails['score30Retention']['value']/returnsTotal)*100 +'%'
				return 'N/A'
		  }
	}

	const setComposition = (scoreDetails, _id) => {
			return(
				<ReactTooltip id={'score-composition_'+_id} aria-haspopup='true' backgroundColor='#f5f5f5'>
						<div className="score-composition-div">
								<h5>Score Composition</h5>
								<div className="d-flex flex-column">
									  <div className="d-flex justify-content-around score-distribution">
												<div className="p-2 label">
													 	Sales Revenue :
												</div>
												<div className="p-2 value">
														{getScorePercent('sales', scoreDetails)}
												</div>
										</div>
										<div className="d-flex justify-content-around score-distribution">
												<div className="p-2 label">
													 	Returns :
												</div>
												<div className="p-2 value">
													  {getScorePercent('returns', scoreDetails)}
												</div>
										</div>
										<div className="d-flex justify-content-around score-distribution">
												<div className="p-2 label">
													 	Inventory :
												</div>
												<div className="p-2 value">
													  {getScorePercent('inventory', scoreDetails)}
												</div>
										</div>
										<div className="d-flex justify-content-around score-distribution">
												<div className="p-2 label">
													 	Marketplace :
												</div>
												<div className="p-2 value">
													  {getScorePercent('marketplace', scoreDetails)}
												</div>
										</div>
								</div>
					</div>
				</ReactTooltip>
			)
	}

	const getValues = (a, b, c, total) => {
		 return setNumbers(((a['value'] + b['value'] + c['value'])/total)*100)
	}

  return (
    <div className="score-icon">
         {setProductIcon(scoreDetails)} 				 
    </div>
  );
}

export default ScoreIcon;