import React, { Component } from "react";
import {UncontrolledButtonDropdown, DropdownToggle, DropdownMenu} from "reactstrap";
import moment from "moment";
import Pagination from "../common/Pagination";
import CollectionFilter from "../common/Filters/CollectionFilter";
import Header from "../common/Header";
import {getFilters, getOrdersList } from "../../services/orderService";
import { setNumbers } from "../../common-functions/setNumbers";
import AddFields from "./AddFields";
import OrderList from "./OrderList";
import ErrorPage from "../common/ErrorPage";

class Orders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      companyKey: this.props.companyKey,
      currency: this.props.companyInfo
        ? this.props.companyInfo["Currency Symbol"]
        : "$",
      currentPage: 1,
      total_count: 0,
      ordersArray: [],
      currentOrders: [],
      showAddFields: false,
      defaultChecked: true,
      totalFields: ["Date", "Customer", "State", "Platform", "Price", "Status"],
      defaultFields: ["Order No."], //Permanent Fields
      selectedFields: [
        "Date",
        "Customer",
        "State",
        "Platform",
        "Price",
        "Status",
      ],
      unselectedFields: [],
      csvHeader: [],
      csvRows: [],
      loading: true,
      loadingOrder: true,
      Order: "", 
      Customer: "", 
      search: "",
      searchKey: "",
      searchFilters: {},
      filters: [],
      orderKey: [],
      orderType: [],
      filtersSelected: {},
    }

    this.onLinkClick = this.onLinkClick.bind(this);
    this.openAddFieldDiv = this.openAddFieldDiv.bind(this);
  }
  componentDidMount() {
    const { currentPage } = this.state
    this.setPageStatus("loadingOrder", true, "")
    this.setPageStatus("loading", true, "")
    this.getFilters()
    this.sendAxiosRequest(currentPage - 1)
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate){
      const { currentPage } = this.state
      this.setPageStatus("loadingOrder", true, "")
      this.setPageStatus("loading", true, "");
      this.sendAxiosRequest(currentPage - 1)
      // this.generateCSV()
    }
    if(prevProps.companyKey !== this.props.companyKey){
      const { currentPage } = this.state
      this.setPageStatus("loadingOrder", true, "");
      this.setPageStatus("loading", true, "");
      this.sendAxiosRequest(currentPage - 1)
      // this.generateCSV()
    }
    if(prevState.filtersSelected !== this.state.filtersSelected){
      const { currentPage } = this.state
      this.setPageStatus("loadingOrder", true, "");
      this.setPageStatus("loading", true, "");
      this.sendAxiosRequest(currentPage - 1)
    }
  }
  getFilters = async () => {
    try{
      const formData = {
          companyKey: this.props.companyKey,
      }
      const res = await getFilters(formData)
      console.log(res.data)
      for(let [key, value] of Object.entries(res.data)){
          if(key === 'select')
              this.setSelectFilters(value)
          else if(key === 'range')
              this.setRangeFilters(value)
          // else if(key === 'hybrid')
          //     this.setHybridFilters(value)
      }
    }
    catch(ex){
      console.log(ex)
      this.setState({ error: true })
    }
  }
  setSelectFilters = (data) => {
    let { filters } = {...this.state}

    for(let [key, value] of Object.entries(data[0])){
        let arr = key.split(":")

        filters.push({
           name : arr[0],
           title : arr[1],
           value: [...this.setCheckBoxes(value)],
           type: "select",
        })
    }
    this.setState({filters})
  }
  setCheckBoxes = (obj) => {
    let arr = obj.split(",")
    let temp = []
    for(let i=0; i < arr.length; i++){
        temp.push({
          name : arr[i],
          checked : false
        })
       
    }
    return temp
  }
  setRangeFilters = (data) => {
    let { filters } = {...this.state}

    let temp_obj = {
      name : 'range',
      title : 'Range',
      type : 'range',
      value : []
    }
    for(let [key, value] of Object.entries(data[0])){
        let arr = key.split(":")
        let arr2 = value.split(":")
        temp_obj.value.push({
          name : arr[0],
          label : arr[1],
          value : {min: arr2[0], max:arr2[1]},
          range : {min: arr2[0], max:arr2[1]}
        }) 
    }
    filters.push(temp_obj)
    this.setState({...filters})
  }
  onCheckboxClick = (e, filterType, index, index2) => {
    const { name, checked } = e.target
    let {filters, filtersSelected} = this.state
    filters[index].value[index2].checked= checked

		if (checked === true) {
			filtersSelected = {
				...filtersSelected,
				[filterType]:
					filtersSelected[filterType] === undefined ? name : filtersSelected[filterType].concat(',' + name)
			};
		} else {
			let temp_arr = filtersSelected[filterType].split(',');
			if (temp_arr.length > 1) {
				temp_arr.splice(temp_arr.indexOf(name), 1);
				filtersSelected = {
					...filtersSelected,
					[filterType]: temp_arr.toString()
				};
			} else {
				delete filtersSelected[filterType];
			}
    }
    this.setState({
        filtersSelected,
        currentPage: 1,
        loadingOrder: true,
        filters
      })
  }
  handleRangeSlide = (index, data) => {
    let {filters} = this.state
    filters[3].value[index].value = {...data}
    this.setState({filters})
  }
  applyRange = (rangeArr) => {
    let {filters, filtersSelected} = this.state

    for(let i=0; i < rangeArr.length; i++){
      let filterType = filters[3].value[rangeArr[i].index].name
      filtersSelected = {
        ...filtersSelected,
        [filterType]: rangeArr[i].data.min+":"+rangeArr[i].data.max
      }
    }
    this.setState({filters, filtersSelected})
  }
  sendAxiosRequest = async ( page) => {
    let { filtersSelected, orderKey, orderType, searchFilters} = this.state;
    const {fromDate, toDate, companyKey} = this.props
    let formData = {}

    if(Object.keys(filtersSelected).length === 0 && orderKey.length === 0) {
      if (Object.keys(searchFilters).length === 0) {
        formData = {
          companyKey: companyKey,
          from: fromDate,
          to: toDate,
          offset: page,
          limit: 30,
        };
      } else {
        let filters = { ...searchFilters };
        formData = {
          companyKey: this.state.companyKey,
          from: fromDate,
          to: toDate,
          offset: page,
          limit: 30,
          filters,
        };
      }

      console.log(1, JSON.stringify(formData));
    } 
    else if (
      Object.keys(filtersSelected).length !== 0 &&
      orderKey.length === 0
    ) {
      let filters = {};

      for (let [key, value] of Object.entries(filtersSelected)) {
        let arr = [...value.split(",")];
        filters[key] = arr;
      }
      filters = {
        ...filters,
        ...searchFilters,
      };

      formData = {
        companyKey: companyKey,
        from: fromDate,
        to: toDate,
        offset: page,
        limit: 30,
        filters,
      }
    } 
    else if (
      Object.keys(filtersSelected).length === 0 &&
      orderKey.length !== 0
    ) {
      if (Object.keys(searchFilters).length === 0) {
        formData = {
          companyKey: companyKey,
          from: fromDate,
          to: toDate,
          offset: page,
          limit: 30,
          orderKey,
          orderType,
        };
      } else {
        let filters = { ...searchFilters };
        formData = {
          companyKey: companyKey,
          from: fromDate,
          to: toDate,
          offset: page,
          limit: 30,
          orderKey,
          orderType,
          filters,
        }
      }
    } 
    else if (
      Object.keys(filtersSelected).length !== 0 &&
      orderKey.length !== 0
    ) {
      let filters = {};

      for (let [key, value] of Object.entries(filtersSelected)) {
        let arr = [...value.split(",")];
        filters[key] = arr;
      }
      filters = { ...filters, ...searchFilters };
      formData = {
        companyKey: companyKey,
        from: fromDate,
        to: toDate,
        offset: page,
        limit: 30,
        filters,
        orderKey,
        orderType,
      }
    }

    formData = JSON.stringify(formData);
    try {
      const response = await getOrdersList(formData)

      if (response.result === "error") {
        this.setState({ error: true });
      } else {
        let orders = [...response.data.orders];
        let ordersArray = [];
        for (let i = 0; i < orders.length; i++) {
          ordersArray.push({
            id: orders[i].Id,
            orderNo: orders[i].OrderNo,
            createdAt: orders[i].Date,
            email: orders[i].Customer.Email,
            customerName: orders[i].Customer.Name,
            customerState: orders[i].Billing.State,
            platform: orders[i].Marketplace,
            orderStatus: orders[i].Status,
            totalPrice: orders[i].TotalPrice,
            checked : false
          });
        }
        this.setState(
          {
            ordersArray: ordersArray,
            totalPages: response.data.totalPages,
            total_count: response.data.totalCount,
          },
          () => {
            this.setPageStatus("loadingOrder", false, "");
            this.setPageStatus("loading", false, "");
          }
        );
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }
  setPageStatus = (loadingLabel, loading, error) => {
    this.setState({
      [loadingLabel]: loading,
      error: error,
    });
  }
  onPageChanged = (pageNo) => {
    this.setPageStatus("loadingOrder", true, "");
    this.sendAxiosRequest(pageNo - 1);
    this.setState({ currentPage: pageNo });
  }
  onLinkClick = (id) => {
    localStorage.setItem("orderId", id);
  }  
  openAddFieldDiv = () => {
    this.setState({
      showAddFields: !this.state.showAddFields,
    })
  }
  
  handleCheckBox = ({ target }) => {
    const { selectedFields, unselectedFields } = this.state;
    if (!target.checked) {
      //selected field removed
      let index = selectedFields.indexOf(target.name);
      selectedFields.splice(index, 1);
      unselectedFields.push(target.name);
      this.setState({ ...this.state, selectedFields, unselectedFields }, () => {
        //console.log(this.state.selectedFields, this.state.unselectedFields);
      });
    } else {
      //unselected field added to selected field
      if (unselectedFields.includes(target.name)) {
        let index = unselectedFields.indexOf(target.name);
        unselectedFields.splice(index, 1);
      }

      selectedFields.push(target.name);
      this.setState({ ...this.state, selectedFields, unselectedFields });
    }
  }

  handleOrderCheckBox = (e, index) => {
      let {ordersArray} = this.state 
      ordersArray[index].checked = e.target.checked
      this.setState({ordersArray}, ()=> console.log(ordersArray))
  }
  
  generateData = (order) => {
    const { selectedFields, currency } = this.state;
    let dataRow = [];
    for (let i = 0; i < selectedFields.length; i++) {
      let field = selectedFields[i]   
      if (field === "Customer") field = "customerName";
      else if (field === "State") field = "customerState"; 
      else if (field === "Status") field = "orderStatus";
      else if (field === "Platform") field = "platform";
      else if (field === "Date") field = "createdAt";
      else if (field === "Price") field = "totalPrice";
      let data = "";
      let email = "";
      if (!order[field]) data = "N/A";
      else {
        if (field !== "customerName" && order[field].length == 0) data = "N/A";
        else if (field == "customerName" && order[field].length == 0) {
          data = "N/A";
          email = order["email"]
            ? order["email"].length == 0
              ? "N/A"
              : order["email"]
            : "N/A";
        } else if (field == "customerName" && order[field].length !== 0) {
          data = order[field];
          email = order["email"] ? order["email"] : null;
        } else if (field == "createdAt") {
          data = order[field];
        } else data = order[field];
      }
      let time = moment();
      if (field === "createdAt") {
        let d = moment(order[field]).format("Do");
        let m = moment(order[field]).format("MMM");
        let y = moment(order[field]).format("YYYY");
        let t = moment(order[field]).format("h:mm a");
        data = `${m} ${d} ${y}`;
        time = t;
      }
      if (field === "totalPrice") {
        let price = order[field];
        data = `${currency}${parseFloat(price).toFixed(2)}`;
      }
      let cell = (
        <td className='order-edit-cell cell' style={{ textAlign: "center" }}>
          {data}
        </td>
      );

      if (field === "customerName") {
        if (data !== "N/A") {
          cell = (
            <td className='order-edit-cell cell customer-col'>
              <div className='row'>
                <div className='col-4 col-md-5 mt-2'>
                  <span className='name'>{data.charAt(0)}</span>
                </div>
                <div
                  className='col-8 col-md-7 mt-2'
                  style={{ padding: 0, margin: 0 }}
                >
                  <span className='customer-name'>{data.toLowerCase()}</span>
                  <br />
                  {email && <span className='email'>{email}</span>}
                </div>
              </div>
            </td>
          );
        } else {
          cell = (
            <td className='order-edit-cell cell customer-col'>
              <span className='customer-name'>{data.toLowerCase()}</span>
              <br />
              <span className='email'>{email}</span>
            </td>
          );
        }
      }
      if (field === "createdAt") {
        cell = (
          <td className='order-edit-cell cell'>
            {data}
            <br />
            {time}
          </td>
        );
      }
      if (field === "orderStatus" && order[field]) {
        if (
          order[field].toUpperCase() == "COMPLETED" ||
          order[field].toUpperCase() == "CLOSED" ||
          order[field].toUpperCase() == "COMPLETE"
        ) {
          cell = (
            <td className='order-edit-cell cell'>
              <span className='order-status closed'>Closed</span>
            </td>
          );
        }
        if (order[field].toUpperCase() == "OPEN") {
          cell = (
            <td className='order-edit-cell cell '>
              <span className='order-status open'>Open</span>
            </td>
          );
        }
        if (order[field].toUpperCase() == "CANCELLED") {
          cell = (
            <td className='order-edit-cell cell '>
              <span className='order-status cancelled'>Cancelled</span>
            </td>
          );
        }
        if (order[field].toUpperCase() == "RETURNED") {
          cell = (
            <td className='order-edit-cell cell '>
              <span className='order-status returned'>Return</span>
            </td>
          );
        }
      }

      dataRow.push(cell);
    }
    return dataRow;
  }

  sort = (column) => {
    let { orderKey, orderType } = this.state;
    if (column == "Order No.") column = "orderNo";
    else if (column == "Date") column = "createdAt";
    else if (column == "Customer") column = "customerName";
    else if (column == "Platform") column = "Marketplace";
    else if (column == "State") column = "Location";
    if (!orderKey.includes(column)) {
      orderKey.push(column);
      orderType.push("asc");
    } else if (orderKey.includes(column)) {
      let index = orderKey.indexOf(column);
      // orderType[index] = orderType[index] == 'asc' ? 'desc' : 'asc';
      if (orderType[index] == "asc") orderType[index] = "desc";
      else if (orderType[index] == "desc") {
        orderKey.splice(index, 1);
        orderType.splice(index, 1);
      }
    }

    const { fromDate, toDate } = this.state;

    this.setState({ orderKey, orderType }, () =>
      this.sendAxiosRequest(fromDate, toDate, 0)
    )
  }

  sortIcon = (column) => {
    //no sort icon for order status column
    if (column === "Status") return;

    const { orderKey, orderType } = this.state;
    if (column === "Order No.") column = "orderNo";
    else if (column === "Date") column = "createdAt";
    else if (column === "Customer") column = "customerName";
    else if (column === "Platform") column = "Marketplace";
    else if (column === "State") column = "Location";

    if (!orderKey.includes(column))
      return <i class='fa fa-sort' onClick={() => this.sort(column)} />;
    else {
      if (orderType[orderKey.indexOf(column)] === "asc")
        return (
          <i
            class=' sort-icon-up fa fa-sort-up ml-1'
            onClick={() => this.sort(column)}
          />
        );
      else if (orderType[orderKey.indexOf(column)] === "desc")
        return (
          <i
            class='sort-icon-down fa fa-sort-down ml-1'
            onClick={() => this.sort(column)}
          />
        );
    }
  }

  sendSearchRequest = (searchKey, search) => {
    if (search.length >= 3) {
      if (searchKey === "Order No.") searchKey = "orderNo";
      else if (searchKey === "Date") searchKey = "createdAt";
      else if (searchKey === "Customer") searchKey = "customerName";
      else if (searchKey === "Platform") searchKey = "Marketplace";
      else if (searchKey === "State") searchKey = "Location";

      const { fromDate, toDate } = this.state;
      let { searchFilters } = this.state;
      searchFilters = {
        ...searchFilters,
        [searchKey]: search,
      };
      this.setState({ searchFilters }, () => {
        // console.log((1000, [searchField], e.target.value));
        this.sendAxiosRequest(fromDate, toDate, 0);
      });
    }
  }

  handleSearch = (e) => {
    let searchField = "";
    const { name, value } = e.target;
    if (value.length === 0) {
      this.handleSearchRemove(name);
    } else {
      if (name === "Order No.") searchField = "orderSearch";
      else if (name === "Customer") searchField = "customerSearch";

      let searchKey = name;
      let search = value;

      this.setState({ [searchField]: e.target.value }, () => {
        this.sendSearchRequest(searchKey, search);
      });
    }
  }

  handleSearchRemove = (item) => {
    let fieldName = "";
    let searchKey = "";

    if (item === "Order No.") {
      fieldName = "orderSearch";
      searchKey = "orderNo";
    } else if (item === "Customer") {
      fieldName = "customerSearch";
      searchKey = "customerName";
    }

    let { searchFilters } = this.state;
    delete searchFilters[searchKey]; //deleting the key from search object

    const { fromDate, toDate } = this.state;
    this.setState({ [fieldName]: "", searchFilters }, () =>
      this.sendAxiosRequest(fromDate, toDate, 0)
    );
  }

  searchOption = (item) => {
    let hide = true;
    let fieldName = "";
    if (item === "Order No.") {
      hide = false;
      fieldName = "orderSearch";
    } else if (item === "Customer") {
      hide = false;
      fieldName = "customerSearch";
    }

    return (
      <UncontrolledButtonDropdown style={{ opacity: hide ? "0" : "1" }}>
        <DropdownToggle
          style={{
            background: "#fff",
            border: "none",
            boxShadow: "none",
          }}
        >
          <i class='fa fa-filter mb-2' />
        </DropdownToggle>

        <DropdownMenu
          class='input-group'
          style={{
            padding: 0,
            height: "30px",
            borderRadius: "0",
          }}
        >
          <input
            type='text'
            className='pl-2 order-searchBox'
            placeholder='Search'
            name={item}
            onChange={this.handleSearch}
            value={this.state[fieldName]}
          />
          <button
            className='clear-search'
            onClick={() => this.handleSearchRemove(item)}
          >
            <i className='fa fa-times clear-search-btn'></i>
          </button>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }

  render() {
    const {
      selectedFields, total_count, filtersSelected, loading, currentPage, loadingOrder, totalFields,
      showAddFields, filters, defaultFields, ordersArray, error, searchFilters, orderKey, orderType
    } = this.state

    const {fromDate, toDate, companyKey, companyInfo} = this.props

    // const loadingOrder = true

    if (error === true) return <ErrorPage errorType={"server"} />;
    else
      return (
        <div className='container-fluid flex-grow pr-2 orders'>
          <div className='container-fluid orders-header'>
            <Header
              title='Orders'
              secondaryText={setNumbers(total_count) + " results"}
            />
            <div className='row filter-sort-row'>
              <CollectionFilter
                filters={filters}
                onCheckboxClick={this.onCheckboxClick}
                showSort={false}
                filtersSelected={filtersSelected}
                handleRangeSlide={this.handleRangeSlide}
                applyRange={this.applyRange}
              />
            </div>
          </div>

          <div className='d-flex flex-column order-section'>
            <div className='row order-top-header'>
              <AddFields
                openAddFieldDiv={this.openAddFieldDiv}
                showAddFields={showAddFields}
                totalFields={totalFields}
                selectedFields={selectedFields}
                handleCheckBox={this.handleCheckBox}
                loading={loading}
                orders={ordersArray}
                companyKey={companyKey}
                fromDate= {fromDate}
                toDate= {toDate}
                filters={filtersSelected}
                orderKey={orderKey}
                orderType={orderType}
                companyInfo={companyInfo}
                searchFilters={searchFilters}
              />
              <div className={`col-12 offset-lg-5 col-lg-4 orders__pagination ${loading ? 'loading' : null}`}>
                {!loading && <Pagination
                  count={total_count}
                  currentPage={currentPage}
                  pageSize={30}
                  changePage={this.onPageChanged}
                  loading={loading}
                />}
              </div>
            </div>
              <OrderList
                  defaultFields={defaultFields}
                  selectedFields={selectedFields}
                  sortIcon={this.sortIcon}
                  searchOption={this.searchOption}
                  orderStatusOption={this.orderStatusOption}
                  ordersArray={ordersArray}
                  onLinkClick={this.onLinkClick}
                  generateData={this.generateData}
                  loading={loadingOrder}
                  handleCheckBox={this.handleOrderCheckBox}
              />
          </div>
        </div>
      );
  }
}

export default Orders;
