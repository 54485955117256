import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Image from 'react-bootstrap/Image';
import { ExcelRenderer } from 'react-excel-renderer';
import { setCsvName, setCsvData } from '../../services/mappingPortalService';

function UploadFile({templateData, importType : importOptions, next}) {
    const inputRef = useRef()
    const [importType, setImportType] = useState(importOptions[0])
    const [dragging, setDragging] = useState(false)

    useEffect(()=>{
        let div = inputRef.current
        if(div){
            div.addEventListener('dragenter', handleDragIn)
            div.addEventListener('dragleave', handleDragOut)
            div.addEventListener('dragover', handleDrag)
            div.addEventListener('drop', handleDrop)
    
            return () => {
                div.removeEventListener('dragenter', handleDragIn)
                div.removeEventListener('dragleave', handleDragOut)
                div.removeEventListener('dragover', handleDrag)
                div.removeEventListener('drop', handleDrop)
            }

        }
    })

    const handleDragIn =(e) =>{
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    }

    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(true)
    }

    const handleDrag =(e)=>{
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDrop =(e)=>{
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setCsvName(e.dataTransfer.files[0].name)
        
            ExcelRenderer(e.dataTransfer.files[0], (err, resp) => {
                setCsvData(JSON.stringify(resp['rows']))
                next()
            })
            e.dataTransfer.clearData()
        }
    }
       
    const onFileSelect = (event) => {
        // console.log(data)
        setCsvName(event.target.files[0].name)
        
        ExcelRenderer(event.target.files[0], (err, resp) => {
            setCsvData(JSON.stringify(resp['rows']))
            next()
        })
    }

    const onButtonFileUpload = () => {
        document.getElementById('button-file').addEventListener('click', 
        document.getElementById('file-id').click());
    }

    const setImport = (e) => {
        setImportType(e.target.value)
    }

    if(!templateData)
        return null
    return (
        <div className="container-fluid" align="center">
            <div className="row">
                
            </div>
            <div className="upper row">
                <div className="col-12 col-md-4 offset-md-3">
                    <select name='importType' value={importType} className="form-control"  onChange={setImport}>
                        {importOptions.map((option, key) => (
                            <option key={key} value={option} >
                                {option.label}
                            </option>
                        ))}
                    </select>

                </div>
                <div className="col-12 col-md-4">
                    <CSVLink data={templateData.get(importType.value)} filename={'' + importType + '-template.csv'} className='btn get-template'>
                        Get Template
                    </CSVLink>
                </div>
            </div>
            <br />
            <br />
            <br />
            <span style={{ opacity: 0.4 }}>------------------------------ Or Upload file directly ------------------------------</span>
            <div className="lower">
                <div className="container-fluid importProducts">
                    <div className="importProducts_uploadDropzone" ref={inputRef}>
                        <label className={`importProducts_fileDropzone ${dragging}`}>
                            <input type="file" onChange={(event) => onFileSelect(event)} accept=".xlsx, .xls, .csv" id="file-id"/>
                            Custom Upload
                        </label>
                        <div className="utilDiv">
                            <Image className="mb-3" src={require('../../assets/fileUpload.png')} thumbnail />
                            <br />
                            <button className="btn mb-3" onClick={onButtonFileUpload} id="button-file">Choose file to upload</button>
                            <br />
                            {/* <p className="mb-3">or drop file here</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadFile;