import React, {useRef, useState, useEffect} from 'react';

function SingleSelectionDropdown(props) {
    const node = useRef();
    const data = [...props.data]
    const [selectedValue, setSelectedValue] =useState(props.selectedValue)
    const [activeClass, setActiveClass] = useState('')

    const [display, setDisplay] = useState(false)
    
    const showDropDown = () => {
        if(!display === true){
            setActiveClass('active')
        }
        else {
            setActiveClass('')
        }
        setDisplay(!display)
    }

    const handleClick = (item) => () =>{
        setSelectedValue(item.name)
        props.handleCheckbox(item)
    }

    const handleClickOutside=(event) =>{
        if (node.current && !node.current.contains(event.target)) {
            setDisplay(false)
            setActiveClass('')
          }
    }

    useEffect(() => {
        // add when mounted
        document.addEventListener("click", handleClickOutside);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, [])

    return (
        <div className="s-dropdown-hoc" ref={node} style={{right: props.right !== undefined ?  props.right : null}}>
            <div className={`s-dropdown-hoc_row1 ${activeClass}`} onClick={showDropDown}>
                <span className="label">
                    <span className="p-2 mt-2">{selectedValue} <i className="fa fa-angle-down"></i></span>
                </span>
            </div>
            <div className="s-dropdown-hoc_row2" style={{display : display ? 'block' : 'none'}}>
                <ul>
                    {data.map((item, key) => {
                        return(
                            <li className={selectedValue === item.name ? 'active' : ''} onClick={handleClick(item)} key={key}>
                                {item.name}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}

export default SingleSelectionDropdown;