import React from 'react';
import { Link } from 'react-router-dom';

const CustomLinks =({link, baseSku, category, label, percent, sku}) => {
  return (
    <Link to={link} params={{ type: baseSku, category: category, sku: sku ? sku: null }}>
        <div className="d-flex">
            <div className="flex-fill">
                <label>{label}</label>
            </div>
            <div className="flex-fill">
                <span>{percent ? percent : null}</span>
            </div>
        </div>
    </Link>
  );
}

export default CustomLinks;