import React, {useRef, useState, useEffect} from 'react';

function Dropdown(props) {
    const node = useRef();
    const [activeClass, setActiveClass] = useState('')
    const [display, setDisplay] = useState(false)
    
    const showDropDown = () => {
        if(!display === true){
            setActiveClass('active')
        }
        else {
            setActiveClass('')
        }
        setDisplay(!display)
    }

    const onCheckboxClick = (item) => () =>{
        props.handleCheckbox(item)
    }

    const handleClickOutside=(event) =>{
        if (node.current && !node.current.contains(event.target)) {
            setDisplay(false)
            setActiveClass('')
          }
    }
    
    useEffect(() => {
        document.addEventListener("click", handleClickOutside)
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [props.data])
    
    return (
        <div className="dropdown-hoc" ref={node}>
            <div className={`dropdown-hoc_row1 ${activeClass}`} onClick={showDropDown}>
                <span className="label">
                    <span className="p-2 mt-2">{props.title} <i className="fa fa-angle-down"></i></span>
                </span>
            </div>
            <div className="dropdown-hoc_row2" style={{display : display ? 'block' : 'none'}}>
                <ul>
                    {props.data.map((item, key) => {
                        return(
                            <li key={key}>
                                <label className="custom-checkbox">
                                    {item.name}
                                    <input
                                    type="checkbox"
                                    name={item.name}
                                    onClick={onCheckboxClick(item)}
                                    defaultChecked={item.checked}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Dropdown;