import React, { useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import RenderMarketAttr from '../RenderMarketAttr';

function Accordion({title, fields, dragStart, type, selectedMp}) {
  const [isOpen, setIsOpen] = useState(type ==='fields' ? true :false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <div className="map-col_fields">
        <Button className={`pim-field-btns ${type}`} onClick={toggle}><i className="fa fa-angle-down"></i>{title}</Button>
          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>
                {type === 'fields' ?
                    <ul>
                        {Object.entries(fields['fields']).map((field, key)=> {
                            return(
                                <li 
                                    key={key} 
                                    onDragStart={(e) => dragStart(e, field[0], null)}
                                    draggable="true"
                                    id={`dragtarget-${title}-${key}`}
                                >
                                    {field[1]}
                                </li>
                            )
                        })}
                    </ul> : 
                    <ul className="mp-fields">
                        {Object.entries(fields).map((field, key)=> {
                            return(
                                <li 
                                    key={key} 
                                    onDragStart={(e) => dragStart(e, field[0], title)}
                                    draggable="true"
                                    id={`dragtargetmp-${key}`}
                                >
                                    {field[1]}
                                </li>
                            )
                        })}
                    </ul>
                }

                {fields['Marketplaces'] && 
                  <RenderMarketAttr 
                      mpFields={fields['Marketplaces']}
                      dragStart={dragStart}
                      selectedMp={selectedMp}
                  />}
               
              </CardBody>
            </Card>
          </Collapse>
    </div>
  );
}

export default Accordion;