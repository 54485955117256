import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import moment from "moment";

class DateFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(),
      range: [],
      highlightDates: [],
      betweenDates: [],
      showCalendar: false,
      from: "",
      to: "",
      option: "",
    };
    this.handleApply = this.handleApply.bind(this);
  }

  from = React.createRef();
  to = React.createRef();
  highlight = React.createRef();

  openCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar,
    });
  };

  //For next month
  onNext = () => {
    this.setState({
      date: moment(this.state.date, "YYYY-MM--DD").add(1, "month"),
    });
  };

  //For previous month
  onPrev = () => {
    this.setState({
      date: moment(this.state.date, "YYYY-MM--DD").subtract(1, "month"),
    });
  };

  //For previous year
  handleUpControl = () => {
    this.setState({
      date: moment(this.state.date, "YYYY-MM--DD").subtract(1, "year"),
    });
  };

  //For next year
  handleDownControl = () => {
    this.setState({
      date: moment(this.state.date, "YYYY-MM--DD").add(1, "year"),
    });
  };

  //To generate date from date object
  generateDate = (dateObject) => {
    const day = parseInt(dateObject.d);
    const month = parseInt(dateObject.m);
    const year = parseInt(dateObject.y);
    const date = moment(`${month}-${day}-${year}`, "MM-D-YYYY");
    return date;
  };

  //To generate between-dates object array
  generateBetweenDates = (startDate, endDate) => {
    let betweenDates = [];
    let currentDate = moment(startDate).add(1, "day");
    let stopDate = moment(endDate);
    while (currentDate < stopDate) {
      let i = moment(currentDate).format("D");
      let m = moment(currentDate).format("MM");
      let y = moment(currentDate).format("YYYY");
      let d = parseInt(i + m + y);
      betweenDates.push(d);
      currentDate = moment(currentDate).add(1, "days");
    }
    return betweenDates;
  };

  //Adding date on-click
  OnDateClick = (e) => {
    let highlightDates = [...this.state.highlightDates]
    let betweenDates = [...this.state.betweenDates]
    let range = [...this.state.range];
    if (highlightDates.length >= 2) {
      highlightDates = [];
      betweenDates = [];
      range = [];
      this.setState({ ...this.state, range, highlightDates, betweenDates });
    }
    if (highlightDates.length < 2) {
      this.from.current.value = "";
      this.to.current.value = "";
      highlightDates.push(e.target.dataset.id);
      const dateObject = {
        d: e.target.dataset.d,
        m: e.target.dataset.month,
        y: e.target.dataset.year,
        month: e.target.dataset.monthname,
      };
      range.push(dateObject);
      range.sort(function (a, b) {
        const day1 = parseInt(a.d);
        const day2 = parseInt(b.d);

        const month1 = parseInt(a.m);
        const month2 = parseInt(b.m);

        const year1 = parseInt(a.y);
        const year2 = parseInt(b.y);

        return (
          new Date(year1, month1, day1, 10, 33, 30, 0) -
          new Date(year2, month2, day2, 10, 33, 30, 0)
        );
      })
      this.setState({
        ...this.state,
        range,
        highlightDates,
        betweenDates,
      })

      if (range.length == 2) {
        let startDate = this.generateDate(range[0]);
        let endDate = this.generateDate(range[1]);
        betweenDates = this.generateBetweenDates(startDate, endDate);
        this.setState({ ...this.state, range, highlightDates, betweenDates });
      }
    }
  };

  //For highlight classes
  generateClass = (d) => {
    if (this.state.highlightDates.length == 0) {
      if (this.props.fromDate && this.props.toDate) {
        const fromDate = {
          d: moment(this.props.fromDate).format("D"),
          m: moment(this.props.fromDate).format("MM"),
          y: moment(this.props.fromDate).format("YYYY"),
        };
        const toDate = {
          d: moment(this.props.toDate).format("D"),
          m: moment(this.props.toDate).format("MM"),
          y: moment(this.props.toDate).format("YYYY"),
        };

        let date1 = parseInt(fromDate.d + fromDate.m + fromDate.y);
        let date2 = parseInt(toDate.d + toDate.m + toDate.y);

        let startDate = this.generateDate(fromDate);
        let endDate = this.generateDate(toDate);
        let betweenDates = this.generateBetweenDates(startDate, endDate);

        let dateClass = "date m-1";
        if (d == date1 || d == date2) dateClass = "date highlight m-1";
        if (betweenDates.includes(d)) dateClass = "date highlight-range m-1";
        return dateClass;
      }
    }

    let arr = [...this.state.highlightDates];
    let dateClass = "date m-1";
    for (let j = 0; j < arr.length; j++) {
      if (parseInt(arr[j]) == d) dateClass = "date highlight m-1";
    }
    arr = [...this.state.betweenDates];
    for (let j = 0; j < arr.length; j++) {
      if (parseInt(arr[j]) == d) dateClass = "date highlight-range m-1";
    }

    return dateClass;
  };

  //To generate blanks in the calendar
  generateBlanks = (firstDayOfMonth) => {
    let blanks = [];
    for (let j = 0; j < firstDayOfMonth; j++) {
      blanks.push(
        <div key={j} className='blankSpace m-1'>
          {" "}
        </div>
      );
    }
    return blanks;
  };

  //To generate dates in the calendar
  generateDates = (days, month, year, monthName) => {
    let dates = [];
    for (let i = 1; i <= days; i++) {
      let d = parseInt(i + month + year);
      dates.push(
        <div
          data-id={d}
          data-d={i}
          data-month={month}
          data-year={year}
          data-monthname={monthName}
          key={i}
          className={this.generateClass(d)}
          onClick={this.OnDateClick}
        >
          {i}
        </div>
      );
    }
    return dates;
  };

  //To apply dates in the custom box
  applyRange = (range) => {
    const date1 = range[0];
    const date2 = range[1];
    const from = `${date1.d}-${date1.m}-${date1.y}`;
    const to = `${date2.d}-${date2.m}-${date2.y}`;
    this.from.current.value = from;
    this.to.current.value = to;
  };

  //To add custom-range
  addCustomRange = () => {
    let range = [];
    this.setState({ ...this.state, range, activeBtn: 1 });
    if (this.state.from && this.state.to) {
      let range = [];
      let arr = this.state.from.split("-");
      let dateObject = {
        d: parseInt(arr[0]),
        m: arr[1],
        y: arr[2],
        month: moment(parseInt(arr[1]), "MM").format("MMM"),
      };
      range.push(dateObject);
      let d = parseInt(dateObject.d + dateObject.m + dateObject.y);
      let highlightDates = [d];
      arr = this.state.to.split("-");
      dateObject = {
        d: parseInt(arr[0]),
        m: arr[1],
        y: arr[2],
        month: moment(parseInt(arr[1]), "MM").format("MMM"),
      };
      range.push(dateObject);
      d = parseInt(dateObject.d + dateObject.m + dateObject.y);
      highlightDates.push(d);
      let startDate = this.generateDate(range[0]);
      let endDate = this.generateDate(range[1]);
      let betweenDates = this.generateBetweenDates(startDate, endDate);
      this.setState({ ...this.state, range, highlightDates, betweenDates });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    }, () => {
      if(this.state.from.length === 10 && this.state.to.length === 10)
        this.addCustomRange()
    })
  }

  //Apply Button
  handleApply = () => {
    const {range} = this.state
    console.log(range)
    if (this.state.range.length === 2) {
      let from = this.generateDate(range[0]);
      let to = this.generateDate(range[1]);
      from = new Date(from);
      to = new Date(to);

      this.props.setDate(from, to);

      this.openCalendar();
    }
    else if(range.length === 1){
      let d = this.generateDate(range[0])
      d = new Date(d)

      this.props.setDate(d, d);

      this.openCalendar();
    }
  }

  //To add today as range
  addTodayAsRange = () => {
    let range = [];
    this.setState({ ...this.state, range });
    let dateObject = {
      d: moment().format("D"),
      m: moment().format("MM"),
      y: moment().format("YYYY"),
      month: moment().format("MMM"),
    };
    range.push(dateObject);
    range.push(dateObject);
    const d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    highlightDates.push(d);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates: [],
    });
    this.applyRange(range);
  }

  //To add yesterday as range
  addYesterdayAsRange = () => {
    let range = [];
    this.setState({ ...this.state, range });
    const dateObject = {
      d: moment().subtract(1, "day").format("D"),
      m: moment().subtract(1, "day").format("MM"),
      y: moment().subtract(1, "day").format("YYYY"),
      month: moment().subtract(1, "day").format("MMM"),
    };
    range.push(dateObject);
    range.push(dateObject);
    const d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    highlightDates.push(d);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates: [],
    });
    this.applyRange(range);
  }

  //To add last n days as range
  addLastAsRange = (number) => {
    let range = [];
    this.setState({ ...this.state, range });

    let dateObject = {
      d: moment().subtract(number, "day").format("D"),
      m: moment().subtract(number, "day").format("MM"),
      y: moment().subtract(number, "day").format("YYYY"),
      month: moment().subtract(number, "day").format("MMM"),
    };
    range.push(dateObject);
    let d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    dateObject = {
      d: moment().format("D"),
      m: moment().format("MM"),
      y: moment().format("YYYY"),
      month: moment().format("MMM"),
    };
    range.push(dateObject);
    d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    highlightDates.push(d);
    let startDate = this.generateDate(range[0]);
    let endDate = this.generateDate(range[1]);
    let betweenDates = this.generateBetweenDates(startDate, endDate);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates,
    });
    this.applyRange(range);
  }

  //To add last month as range
  addLastMonthAsRange = () => {
    let range = [];
    this.setState({ ...this.state, range });

    let dateObject = {
      d: moment().format("1"),
      m: moment().subtract(1, "month").format("MM"),
      y: moment().subtract(1, "month").format("YYYY"),
      month: moment().subtract(1, "month").format("MMM"),
    };

    range.push(dateObject);
    let d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    dateObject = {
      d: moment().subtract(1, "month").daysInMonth(),
      m: moment().subtract(1, "month").format("MM"),
      y: moment().subtract(1, "month").format("YYYY"),
      month: moment().subtract(1, "month").format("MMM"),
    };
    range.push(dateObject);
    d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    highlightDates.push(d);
    let startDate = this.generateDate(range[0]);
    let endDate = this.generateDate(range[1]);
    let betweenDates = this.generateBetweenDates(startDate, endDate);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates,
      activeBtn: 6,
    });
    this.applyRange(range);
  }

  //To add last year as range
  addLastYearAsRange = () => {
    let range = [];
    this.setState({ ...this.state, range });

    let dateObject = {
      d: moment().format("1"),
      m: moment().format("01"),
      y: moment().subtract(1, "year").format("YYYY"),
      month: "Jan",
    };

    range.push(dateObject);
    let d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    dateObject = {
      d: moment().format("31"),
      m: moment().format("12"),
      y: moment().subtract(1, "year").format("YYYY"),
      month: "Dec",
    };
    range.push(dateObject);
    d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    highlightDates.push(d);
    let startDate = this.generateDate(range[0]);
    let endDate = this.generateDate(range[1]);
    let betweenDates = this.generateBetweenDates(startDate, endDate);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates,
    });
    this.applyRange(range);
  };

  //To add week to date as range
  addWeekToDateAsRange = () => {
    let range = [];
    this.setState({ ...this.state, range });
    let week = moment().format("w");

    let dateObject = {
      d: moment().day("Monday").year("2020").week(week).format("D"),
      m: moment().day("Monday").year("2020").week(week).format("MM"),
      y: moment().day("Monday").year("2020").week(week).format("YYYY"),
      month: moment().day("Monday").year("2020").week(week).format("MMM"),
    };
    range.push(dateObject);
    let d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    dateObject = {
      d: moment().format("D"),
      m: moment().format("MM"),
      y: moment().format("YYYY"),
      month: moment().format("MMM"),
    };
    range.push(dateObject);
    d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    highlightDates.push(d);
    let startDate = this.generateDate(range[0]);
    let endDate = this.generateDate(range[1]);
    let betweenDates = this.generateBetweenDates(startDate, endDate);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates,
    });
    this.applyRange(range);
  };

  //To add month to date as range
  addMonthToDateAsRange = () => {
    let range = [];
    this.setState({ ...this.state, range });

    let dateObject = {
      d: moment().format("1"),
      m: moment().format("MM"),
      y: moment().format("YYYY"),
      month: moment().format("MMM"),
    };
    range.push(dateObject);
    let d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    dateObject = {
      d: moment().format("D"),
      m: moment().format("MM"),
      y: moment().format("YYYY"),
      month: moment().format("MMM"),
    };
    range.push(dateObject);
    d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    highlightDates.push(d);
    let startDate = this.generateDate(range[0]);
    let endDate = this.generateDate(range[1]);
    let betweenDates = this.generateBetweenDates(startDate, endDate);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates,
    });
    this.applyRange(range);
  }

  //To add quarter to date as range
  addQuarterToDateAsRange = () => {
    let range = [];
    this.setState({ ...this.state, range });

    let dateObject = {
      d: moment().quarter(moment().quarter()).startOf("quarter").format("D"),
      m: moment().quarter(moment().quarter()).startOf("quarter").format("MM"),
      y: moment().quarter(moment().quarter()).startOf("quarter").format("YYYY"),
      month: moment()
        .quarter(moment().quarter())
        .startOf("quarter")
        .format("MMM"),
    };
    range.push(dateObject);
    let d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    dateObject = {
      d: moment().format("D"),
      m: moment().format("MM"),
      y: moment().format("YYYY"),
      month: moment().format("MMM"),
    };
    range.push(dateObject);
    d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    highlightDates.push(d);
    let startDate = this.generateDate(range[0]);
    let endDate = this.generateDate(range[1]);
    let betweenDates = this.generateBetweenDates(startDate, endDate);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates,
    });
    this.applyRange(range);
  }

  //To add year to date as range
  addYearToDateAsRange = () => {
    let range = [];
    this.setState({ ...this.state, range });

    let dateObject = {
      d: moment().format("1"),
      m: moment().format("01"),
      y: moment().format("YYYY"),
      month: "Jan",
    };
    range.push(dateObject);
    let d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    const highlightDates = [d];
    dateObject = {
      d: moment().format("D"),
      m: moment().format("MM"),
      y: moment().format("YYYY"),
      month: moment().format("MMM"),
    };
    range.push(dateObject);
    d = parseInt(dateObject.d + dateObject.m + dateObject.y);
    highlightDates.push(d);
    let startDate = this.generateDate(range[0]);
    let endDate = this.generateDate(range[1]);
    let betweenDates = this.generateBetweenDates(startDate, endDate);
    this.setState({
      ...this.state,
      date: moment(),
      range,
      highlightDates,
      betweenDates,
    });
    this.applyRange(range);
  }

  handleOptionChange = ({ target }) => {
    this.setState({ option: target.value });
    if (target.value == "Custom Range") this.addCustomRange();
    if (target.value == "Today") this.addTodayAsRange();
    if (target.value == "Yesterday") this.addYesterdayAsRange();
    if (target.value == "Last 7 days") this.addLastAsRange(7);
    if (target.value == "Last 30 days") this.addLastAsRange(30);
    if (target.value == "Last 90 days") this.addLastAsRange(90);
    if (target.value == "Last month") this.addLastMonthAsRange();
    if (target.value == "Last year") this.addLastYearAsRange();
    if (target.value == "Week to date") this.addWeekToDateAsRange();
    if (target.value == "Month to date") this.addMonthToDateAsRange();
    if (target.value == "Quarter to date") this.addQuarterToDateAsRange();
    if (target.value == "Year to date") this.addYearToDateAsRange();
  }

  weekdayshort = moment.weekdaysShort()

  render() {

    const currentDays = moment(this.state.date, "YYYY-MM").daysInMonth();
    const currentYear = moment(this.state.date).format("YYYY");
    const currentMonth = moment(this.state.date).format("MMMM");
    const currentMonthName = moment(this.state.date).format("MMM");
    const currentMonthNumber = moment(this.state.date).format("MM");
    const currentFirstDayOfMonth = moment(this.state.date)
      .startOf("month")
      .format("d");

    const previousDays = moment(this.state.date, "YYYY-MM")
      .subtract(1, "month")
      .daysInMonth();
    const previousYear = moment(this.state.date)
      .subtract(1, "month")
      .format("YYYY");
    const previousMonth = moment(this.state.date)
      .subtract(1, "month")
      .format("MMMM");
    const previousMonthName = moment(this.state.date)
      .subtract(1, "month")
      .format("MMM");
    const previousMonthNumber = moment(this.state.date)
      .subtract(1, "month")
      .format("MM");
    const previousFirstDayOfMonth = moment(this.state.date)
      .subtract(1, "month")
      .startOf("month")
      .format("d");

    let weekdayshortname = this.weekdayshort.map((day) => {
      return (
        <div key={day} className='day'>
          {day}
        </div>
      );
    });

    return (
      <div className='container-fluid'>
        <div className='row date-filter-div'>
          <Dropdown toggle={this.openCalendar} isOpen={this.state.showCalendar}>
            <DropdownToggle
              caret
              className='dropdown-btn'
              style={{ background: "#fff" }}
            >
              <Image src={require("../../../assets/date-1.png")} thumbnail />{" "}
              <span className='date-value'>
                {this.state.range.length == 2
                  ? `${this.state.range[0].month} ${this.state.range[0].d}, ${this.state.range[0].y} - ${this.state.range[1].month} ${this.state.range[1].d}, ${this.state.range[1].y}`
                  : this.props.fromDate && this.props.toDate
                  ? `${moment(this.props.fromDate).format("MMM")} ${moment(
                      this.props.fromDate
                    ).format("D")}, ${moment(this.props.fromDate).format(
                      "YYYY"
                    )} - ${moment(this.props.toDate).format("MMM")} ${moment(
                      this.props.toDate
                    ).format("D")}, ${moment(this.props.toDate).format("YYYY")}`
                  : `${moment().format("MMM")} 1, ${moment(
                      this.state.date
                    ).format("YYYY")} - ${moment().format(
                      "MMM"
                    )} ${moment().format("DD")}, ${moment().format("YYYY")}`}
              </span>
            </DropdownToggle>

            <DropdownMenu
              className='dropdown-menu'
              right
              style={{ width: "620px", height: "400px" }}
            >
              <div className='date-filter'>
                <div className='calendar row container-fluid'>
                  <div className='calendar-year mt-3 row container-fluid clickable'>
                    <i
                      className='fa fa-angle-up m-1 mr-3 upControl'
                      aria-hidden='true'
                      onClick={this.handleUpControl}
                    />
                    Year {currentYear}
                    <i
                      className='fa fa-angle-down m-1 ml-3 downControl'
                      aria-hidden='true'
                      onClick={this.handleDownControl}
                    />
                    <div className='from-date ml-3'>
                      <input
                        id='from'
                        name='from'
                        type='text'
                        ref={this.from}
                        placeholder='DD-MM-YYYY'
                        onChange={this.handleChange}
                        value={this.state.from}
                      />
                    </div>
                    <div className='to-date'>
                      <input
                        type='text'
                        id='to'
                        name='to'
                        ref={this.to}
                        placeholder='DD-MM-YYYY'
                        onChange={this.handleChange}
                        value={this.state.to}
                      />
                    </div>
                    <div className='options-dropdown'>
                      <select
                        className='form-control clickable'
                        value={this.state.option}
                        name='option'
                        onChange={this.handleOptionChange}
                        style={{ color: "#4b3b94" }}
                      >
                        <option>Custom Range</option>
                        <option>Today</option>
                        <option>Yesterday</option>
                        <option>Last 7 days</option>
                        <option>Last 30 days</option>
                        <option>Last 90 days</option>
                        <option>Last month</option>
                        <option>Last year</option>
                        <option>Week to date</option>
                        <option>Month to date</option>
                        <option>Quarter to date</option>
                        <option>Year to date</option>
                      </select>
                    </div>
                  </div>

                  <div
                    className='row container-fluid mb-5 mt-3'
                    style={{ margin: 0 }}
                  >
                    <div className='previous myCalendar'>
                      <div className='monthRow'>
                        <div className='prevControl' onClick={this.onPrev}>
                          <i className='fa fa-angle-left' aria-hidden='true' />
                        </div>
                        <div className='previous-month'>
                          {previousMonth} {previousYear}
                        </div>
                      </div>

                      <div className='daysRow mt-2 ml-1 mr-1 mb-1'>
                        {weekdayshortname}
                      </div>

                      <div className='contentCalendar'>
                        {this.generateBlanks(previousFirstDayOfMonth)}
                        {this.generateDates(
                          previousDays,
                          previousMonthNumber,
                          previousYear,
                          previousMonthName
                        )}
                      </div>
                    </div>

                    <div className='current myCalendar'>
                      <div className='monthRow'>
                        <div className='current-month'>
                          {currentMonth} {currentYear}
                        </div>
                        <div className='nextControl' onClick={this.onNext}>
                          <i className='fa fa-angle-right' aria-hidden='true' />
                        </div>
                      </div>

                      <div className='daysRow mt-2'>{weekdayshortname}</div>

                      <div className='contentCalendar'>
                        {this.generateBlanks(currentFirstDayOfMonth)}
                        {this.generateDates(
                          currentDays,
                          currentMonthNumber,
                          currentYear,
                          currentMonthName
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className='calendarBtns row container-fluid mt-2'
                    style={{ marginLeft: "100px" }}
                  >
                    <div className='button-cancel col-2 offset-6'>
                      <button onClick={this.openCalendar}>Cancel</button>
                    </div>
                    <div className='button-apply col-3 ml-3'>
                      <button onClick={this.handleApply}>Apply</button>
                    </div>
                  </div>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default DateFilter;
