import React, { Component } from "react";
import CollectionFilter from "../common/Filters/CollectionFilter";
import Loading from "../common/Loading";
import ErrorPage from "../common/ErrorPage";
import Header from "../common/Header";
import ProductCard from "./Analytics/ProductCards/ProductCard";
import { getFilters, getProductDataPoints, getProductsList, setProductCount, setProductFilters, setProductSearchKeyword, setProductSorting } from "../../services/productService";
import Pagination from '../common/Pagination';
import CollectionList from "./Pim/Listing/CollectionList";
import { Link } from "react-router-dom";
import SearchBox from "../UI Elements/SearchBox";
import ProductImage from "./ProductImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { getUser } from "../../services/authService";
import ProductExport from "../common/Exports/ProductExport";
// import Maintainence from "../common/Maintainence";

class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products_array: [],
      total_count: null,
      rosSliderValue: 50,
      rrSliderValue: 50,
      sortOptions: [],
      sortSelected: {},
      searchedValue: "",
      sortOptionsDiv: false,
      colorsDisplay: [
        {
          color: "Red",
          hexCode: "#E52929",
          checked: false,
        },
        {
          color: "Green",
          hexCode: "#1C7309",
          checked: false,
        },
        {
          color: "Blue",
          hexCode: "#21618C",
          checked: false,
        },
        {
          color: "Yellow",
          hexCode: "#E3DD1E",
          checked: false,
        },
        {
          color: "Voilet",
          hexCode: "#E673FA",
          checked: false,
        },
        {
          color: "Orange",
          hexCode: "#E56E11",
          checked: false,
        },
      ],
      modalShow: false,
      loadingProducts: false,
      loadingDataPoints: false,
      loading: false,
      error: false,
      currentPage: 1,
      totalPages: 0,
      filters: [],
      filtersSelected: {},
      imageUrl: '',
      modalOpen : false
    }
  }
  scrollToTop = () => {
    this.topDiv.scrollIntoView({ behavior: "smooth" });
  }
  componentDidMount() {
    this.setPageStatus("loading", true, "")
    this.setPageStatus("loadingProducts", true, "")

    this.setFilters()
    this.getProductsList(this.state.currentPage-1)
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.fromDate != this.props.fromDate || prevProps.toDate != this.props.toDate){
        this.setPageStatus("loading", true, "")
        this.setPageStatus("loadingProducts", true, "")
        this.getProductsList(this.state.currentPage-1)     
    }
    if(prevProps.companyKey !== this.props.companyKey){
        this.setState({filters : []}, ()=> this.setFilters())     
        this.setPageStatus("loading", true, "")
        this.setPageStatus("loadingProducts", true, "")
        this.getProductsList(this.state.currentPage-1)
    }
    if(prevState.filtersSelected !== this.state.filtersSelected){
      this.getProductsList(0)
    }
  }
  setFilters = async () => {
    try{
      const formData = {
          companyKey: this.props.companyKey,
      }
      const res = await getFilters(formData)
      // console.log(res.data)
      for(let [key, value] of Object.entries(res.data)){
          if(key === 'select')
              this.setSelectFilters(value)
          else if(key === 'range')
              this.setRangeFilters(value)
          else if(key === 'hybrid')
              this.setHybridFilters(value)
      }
    }
    catch(ex){
      console.log(ex)
      this.setState({ error: true })
    }
  }
  setSelectFilters = (data) => {
    let { filters } = {...this.state}

    for(let [key, value] of Object.entries(data[0])){
        let arr = key.split(":")
        filters.push({
           name : arr[0],
           title : arr[1],
           value: [...this.setCheckBoxes(arr[0], value)],
           type: "select",
        })
    }
    this.setState({filters})
  }
  setCheckBoxes = (key, obj) => {
     let arr = obj.split(",")
     let temp = []
     for(let i=0; i < arr.length; i++){
        if(key === 'productCategory'){
          temp.push({
              name : arr[i],
              checked : false,
              img : this.setProductCategoryImg(arr[i])
          })
        }
        else{
          temp.push({
             name : arr[i],
             checked : false
          })
        }
     }
     return temp
  }
  setRangeFilters = (data) => {
      let { filters } = {...this.state}

      let temp_obj = {
        name : 'range',
        title : 'Range',
        type : 'range',
        value : []
      }
      for(let [key, value] of Object.entries(data[0])){
          let arr = key.split(":")
          let arr2 = value.split(":")
          temp_obj.value.push({
            name : arr[0],
            label : arr[1],
            value : {min: arr2[0], max:arr2[1]},
            range : {min: arr2[0], max:arr2[1]}
          }) 
      }
      filters.push(temp_obj)
      this.setState({...filters})
  }
  setHybridFilters = (obj) => {
    let { filters } = {...this.state}

    for(let [key, value] of Object.entries(obj)){
       let arr = key.split(":")
       let arr1 = value.keys.split(",")
       let arr2 = value.values.split(",")
       let temp_arr = []
       for(let i=0; i < arr2.length; i++){
          temp_arr.push({
             name : arr2[i],
             key : arr1[i]
          })
       }

       filters.push({
          name : arr[0],
          title : arr[1],
          type : "hybrid",
          value : [...temp_arr]
      })
       
    }

    this.setState({filters})
  }
  setProductCategoryImg(category){
    if(category === 'Seed')
        return 'seed.PNG'
    else if(category === 'Tree')
        return 'tree.PNG'
    else if(category === 'Sprout')
        return 'sprout.PNG'
    else
        return 'barren.PNG'
  }
  setPageStatus = (loadingLabel, loading, msg) => {
    this.setState({
      [loadingLabel]: loading,
    });
  }
  onPageChanged = (pageNo) => {
    this.getProductsList(pageNo - 1, "", "")
    this.setState({ currentPage: pageNo })    
  }
  getProductsList = async (offset) => {
    this.scrollToTop()
    try{
      let formData = {}
      let { filtersSelected, sortSelected, searchedValue } = this.state
      let filters = {...this.setFiltersForFormData(filtersSelected)}
      let orderKey = Object.keys(sortSelected).length >0 ? sortSelected.orderKey : ['totalSales30']
      let orderType = Object.keys(sortSelected).length >0 ? sortSelected.orderType : ['desc']

      setProductFilters(filters)
      setProductSearchKeyword(searchedValue)
      setProductSorting(orderKey, orderType)

      formData = {
        companyKey: this.props.companyKey,
        email : getUser().userEmail,
        offset: offset,
        limit: 21,
        filters: filters,
        search: searchedValue,
        orderKey: orderKey,
        orderType: orderType,
      }

      console.log(JSON.stringify(formData))

      const res = await getProductsList(formData)
      console.log(res.data)
      this.setResponseFromPim(res)
    }
    catch(e) {
        console.log(e)
        if(!e.message === 'operation cancelled'){
          this.setState({ error: true })
          this.setPageStatus("loadingProducts", false, "Something went Wrong");

        }
      }
  }
  setFiltersForFormData(filtersSelected){
    let filters = {};

    for (let [key, value] of Object.entries(filtersSelected)) {
      if(key === 'score' || key === 'ros90' || key=== 'aos' || key=== 'brokenP'){
          filters[key] = value; 
      }
      else{
        let arr = [...value.split(",")];
        filters[key] = arr;
      }
    }
    return filters
  }
  clearFilters=()=> {
    const {filtersSelected} = this.state
    if(Object.keys(filtersSelected).length > 0){
      this.setFiltersToFalse()
      this.setState({
        filtersSelected : {},
      }, ()=> this.getProductsList())
    }
  }
  setFiltersToFalse=()=> {
     const {filters} = this.state
     for(let i=0; i < filters.length; i++){
         for(let j=0; j<filters[i].value.length; j++){
             filters[i].value[j].checked = false
         }
     }
     this.setState({filters})
  }
  setResponseFromPim =(res)=>{
    const response = [...res.data[0].products]
    let total_count = res.data[0].count
    let totalPages = res.data[0].pages
    var products_array = []
    let limit = 21

    setProductCount(total_count)

    for (let i = 0; i < response.length; i++) {
      products_array.push({
        image_url: response[i].image_url,
        baseSku: response[i].baseSku,
        baseKey: response[i].baseKey,
        title: response[i].title,
        creationDate: response[i].creationDate,
        brand: response[i].Brand,
        productType: response[i].ProductType,
        Score: null,
        checked : false,
        position : (this.state.currentPage -1)*limit + i + 1
      })
    }
    this.setState(
      {
        products_array: products_array,
        totalPages: totalPages,
        total_count: total_count
      },
      () => {
        if(this.props.module === 'analytics'){
          this.fetchDataPoints()
          this.setPageStatus("loadingDataPoints", true)
        }
        this.setPageStatus("loadingProducts", false)
        this.setPageStatus("loading", false)
      }
    )
  }
  fetchDataPoints = async () =>{
    try{
      const { fromDate, toDate } = this.props;
      let products_array = [...this.state.products_array];

      var temp_arr = [];
      for (let i = 0; i < products_array.length; i++) {
        temp_arr.push(products_array[i].baseSku);
      }
      const formData = {
        toDate: toDate,
        fromDate: fromDate,
        companyKey: this.props.companyKey,
        timeZone: this.props.companyInfo["Time Zone"],
        baseSku: temp_arr,
        chartTypes: {
          PIE: {
            PIE_MARKETPLACE_ORDERS: {},
            PIE_MARKETPLACE_ORDER_SALES: {},
          },
          BAR: {
            BAR_ORDERS_DAYWISE: {},
          },
          LINE: {
            LINE_ORDERS: {
              timePeriod: "month",
            },
            LINE_ORDER_SALES: {
              timePeriod: "date",
            }
          },
        },
      }
      console.log(JSON.stringify(formData))

      const res = await getProductDataPoints(formData)
      console.log(res.data)
      this.setResponseFromPython(res)
    }
    catch(e) {
        console.log(e);
        this.setPageStatus("loadingProducts", false, "")
    }

  }
  setResponseFromPython = (res) => {
    var temp_arr2 = res.data
    let products_array = [...this.state.products_array]

    for (let i = 0; i < products_array.length; i++) {
      var dataPoints = temp_arr2[products_array[i].baseSku];
      for (var key in dataPoints) {
        products_array[i][key] = dataPoints[key];
      }
    }
    // console.log(products_array)
    this.setState({
      products_array: products_array,
      loadingProducts: false,
      loadingDataPoints: false,
    })
  }
  onCheckboxClick = (e, filterType, index, index2) => {
    const { name, checked } = e.target
    let {filters, filtersSelected} = this.state
    filters[index].value[index2].checked= checked

		if (checked === true) {
			filtersSelected = {
				...filtersSelected,
				[filterType]:
					filtersSelected[filterType] === undefined ? name : filtersSelected[filterType].concat(',' + name)
			};
		} else {
			let temp_arr = filtersSelected[filterType].split(',');
			if (temp_arr.length > 1) {
				temp_arr.splice(temp_arr.indexOf(name), 1);
				filtersSelected = {
					...filtersSelected,
					[filterType]: temp_arr.toString()
				};
			} else {
				delete filtersSelected[filterType];
			}
    }
    this.setState({
        filtersSelected,
        currentPage: 1,
        loadingProducts: true,
        filters
      })
  }
  onStockSelect = (filterType, index, index2) => {
    let {filters, filtersSelected} = this.state
    filtersSelected = {
      ...filtersSelected,
      [filterType]: filters[index].value[index2].key
    }
    this.setState({filtersSelected})
  }
  sortProducts = (key, type) => () => {
    let sortSelected = { ...this.state.sortSelected }

    sortSelected = {
      orderKey : [key],
      orderType: [type]
    }

    this.setState({ sortSelected },() => this.getProductsList(0))
  }
  handleRangeSlide = (index, data) => {
      let {filters} = this.state
      filters[3].value[index].value = {...data}
      this.setState({filters})
  }
  applyRange = (rangeArr) => {
    let {filters, filtersSelected} = this.state

    for(let i=0; i < rangeArr.length; i++){
      let filterType = filters[3].value[rangeArr[i].index].name
      filtersSelected = {
        ...filtersSelected,
        [filterType]: rangeArr[i].data.min+":"+rangeArr[i].data.max
      }
    }
    this.setState({filters, filtersSelected})
  }
  showList(){
     const {module, companyInfo} = this.props
     const {products_array, loadingProducts, loadingDataPoints} = this.state

     if(module === 'pim')
        return(
            <CollectionList 
              products_array ={products_array}
              loadingProducts={loadingProducts}
              enlargeImage= {this.enlargeImage}
            />
        )
     return(
        <ProductCard
          products_array ={products_array}
          loadingDataPoints={loadingDataPoints}
          currencySymbol={companyInfo ? companyInfo['Currency Symbol'] : '$'}
          loadingProducts={loadingProducts}
          enlargeImage= {this.enlargeImage}
          onProductSelect={this.onProductSelect}
        />
    )
  }
  enlargeImage =(imageUrl)=>{
      this.setState({ imageUrl})
  }
  handleSearch=(value)=> {
    this.setState({
      searchedValue : value
    }, () => {
       if(value.length > 2 || value === '')
          this.getProductsList()
    })
  }
  getSelectedFilters=()=> {
      const {filtersSelected} = this.state
      let arr = []
      for(let [key, value] of Object.entries(filtersSelected)){
          if(key !== 'score' && key !== 'ros90' && key !== 'aos' && key !== 'brokenP'){
            let temp = value.split(",")
            for(let i=0; i < temp.length; i++){
                arr.push({
                   name : temp[i],
                   type : key
                })
            }
          }
      }
      return arr
  }
  deselectFilter=(filter)=> {
      let {filters} = this.state
      let filtersSelected = {...this.state.filtersSelected}

      let temp_arr = filtersSelected[filter.type].split(',')
			if (temp_arr.length > 1) {
				temp_arr.splice(temp_arr.indexOf(filter.name), 1);
				filtersSelected = {
					...filtersSelected,
					[filter.type]: temp_arr.toString()
				};
			} else {
				delete filtersSelected[filter.type]
      } 

      let temp = [...filters.filter(item => item.name === filter.type)]
      let index = filters.indexOf(temp[0])

      let temp2 = temp[0].value.filter(item => item.name === filter.name)
      let index2 = temp[0].value.indexOf(temp2[0])

      filters[index].value[index2].checked= false
      this.setState({filters, filtersSelected, loadingProducts : true, currentPage : 1})
  }
  onProductSelect = (e, value)=> {
     const {name, checked} = e.target
     let products_array = [...this.state.products_array]
     let temp = products_array.filter(item => item.baseSku === name)
     products_array[products_array.indexOf(temp[0])].checked = checked

     this.setState({products_array})
  }
  toggleModal = () => {
     this.setState({ modalOpen : !this.state.modalOpen})
  }
  render() {
    const {
      loading,
      error,
      total_count,
      currentPage,
      loadingProducts,
      searchedValue,
      imageUrl,
      products_array,
      filters,
      filtersSelected,
      modalOpen
    } = this.state

    const {module} = this.props
    const selectedFilters = this.getSelectedFilters() 

    if (error === true) return <ErrorPage errorType={"server"} />;
    else if ((loading === true) & (window.innerWidth > 576))
      return <Loading message={"Please wait. Your data is getting ready..."} />;
    else
      return (
        <div className='container-fluid flex-grow pr-2 products'>          
          <div className='container-fluid products-header'>
              <Header 
                  title='Products'
                  secondaryText={total_count ? total_count + ' results' : ''}
              />
              <div className='row filter-sort-row'>
                <CollectionFilter
                  filters={filters}
                  onCheckboxClick={this.onCheckboxClick}
                  sortProducts={this.sortProducts}
                  showSort={module === 'analytics' ? true : false}
                  filtersSelected={filtersSelected}
                  handleRangeSlide={this.handleRangeSlide}
                  applyRange={this.applyRange}
                  onStockSelect={this.onStockSelect}
                />
              </div>
          </div>

          <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.topDiv = el; }}>
          </div>
          <div className="row tab-option">
              <div className="col-12 col-md-6 col-lg-10">
                  <div className="selected-filters">
                     {selectedFilters.map((item, key) => {
                          return (
                            <span key={key}>
                                {item.name}
                                <FontAwesomeIcon
                                    className="close-icon"
                                    icon={faTimes}
                                    onClick={() =>this.deselectFilter(item)}
                                />
                            </span>
                          )
                       })}
                  </div>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                  <Link 
                      className={module === 'pim' ? 'active' : ''} 
                      to="/products/pim" 
                      params={{module: 'pim'}}> 
                      PIM
                  </Link>
                  <Link 
                      className={module === 'analytics' ? 'active' : ''} 
                      to="/products/analytics" 
                      params={{module: 'analytics'}}> 
                      Analytics
                  </Link>
              </div>
          </div>

          <div className='row product-row-main'>
            <ProductImage imageUrl = {imageUrl} />

            <div className='col-12 product-listing-div'>
              <div className='container-fluid mt-3 div'>
                <div className='row row-sort'>
                  <div className='col-4 col-md-6 col-lg-4 pl-3'>
                      {module === 'analytics' ?
                        <button className="export-btn" onClick={this.toggleModal}>
                          <i className='fa fa-download p-1' aria-hidden='true' />
                          Export
                        </button>
                        :
                        <div className='import p-1 ml-2'>
                            <Link to='/mappingPortal/upload' params={{category: 'upload'}}>
                              <i className='fa fa-upload p-2' aria-hidden='true' />
                              Import
                            </Link>
                        </div> 
                      }
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                        <SearchBox 
                            searchedValue={searchedValue}
                            searchVariants={this.handleSearch}
                        />
                  </div>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div className='page-display'>
                      <div className='p-1'>
                        {loadingProducts === true ? null : 
                          <Pagination 
                            count={total_count}
                            currentPage={currentPage}
                            pageSize={21}
                            changePage={this.onPageChanged}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mt-1 all-products'>
                   {products_array.length > 0 ?this.showList() : <p className="not-available-msg">No products in the system.</p>}                     
                </div>
                <div className="row pb-4 pagination-bottom">
                    <div className='col-12 col-md-6 col-lg-4 offset-lg-8'>
                      <div className='page-display'>
                        <div className='p-1'>
                          {loadingProducts === true ? null : 
                            <Pagination 
                              count={total_count}
                              currentPage={currentPage}
                              pageSize={21}
                              changePage={this.onPageChanged}
                            />
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>          
        
          <ProductExport 
              modalOpen={modalOpen} 
              toggleModal={this.toggleModal}
              data={products_array}
              companyKey={this.props.companyKey}
              fromDate= {this.props.fromDate}
              toDate= {this.props.toDate}
              timeZone={this.props.companyInfo["Time Zone"]}
          />
        </div>
      );
  }
}

export default Products;
