import React, { useEffect, useState } from 'react';
import LoadingContent from '../../common/LoadingContent';
import MiniGraphs from '../MiniGraphs';

function TopCards({color, currencySymbol, count, loadingDS, card, lineData}) {
    const [data, setGraphData] = useState([])
    const [labels, setLabels] = useState([])
    const [rate, setRate] = useState()

    useEffect(()=>{
        if(!loadingDS){
            setBarData()
            setRate(calculatePercent(card.currValue, card.prevValue))
        }
    }, [card])

    const setBarData = () => {
        let graphData = lineData[card.graph] ? lineData[card.graph]['chartData']['All'] : null
        if(graphData){
            let arr = []
            let arr2= []
            for(let [key, value] of Object.entries(graphData)){
                arr2.push(key)
                arr.push(value)
            }
            setGraphData([...arr])
            setLabels([...arr2])
        }
    }

    const calculatePercent = (str1, str2) => {
        if(str1 !== null & str2 !== null){           
            let num1 = parseFloat(str1.replace(/,/g, ''))    //currValue
            let num2 =parseFloat(str2.replace(/,/g, ''))     // prevValue
            if(num2 === 0.00)
                return null
            return Math.round(((num1 - num2)/num2)* 100).toFixed(2)
        }
        return null
    }

    return (
            <div className="dashboard-cards" style={{borderLeft: '5px solid'+color}}>
                <div className="value">
                    {loadingDS ?
                        <LoadingContent 
                            width={'65%'} 
                            marginTop={'12px'} 
                            height={'10px'}
                        />
                        :
                        <h6>{card.title} </h6>
                    }
                    {loadingDS ?
                        <LoadingContent 
                            width={'50%'} 
                            marginTop={'12px'} 
                            height={'10px'}
                        />
                        :
                        <span className="value_span">
                            {card.currency === true ? currencySymbol: null}
                            {card.currValue}
                            {card.perc === true ? '%': null}
                        </span>
                    }
                    {loadingDS ?
                        <LoadingContent 
                            width={'35%'} 
                            marginTop={'12px'} 
                            height={'10px'}
                        />
                        :
                        <span className="rate" style={{color: rate > 0 ? '#009688' : 'red'}}>
                            {rate === null || rate === 0.00 ? null : rate > 0 ? '+' + rate + '%' :  rate + '%' }
                        </span>
                    }

                    {data && !loadingDS &&<MiniGraphs 
                        data={data}
                        color={color}
                        labels={labels}
                        id={'card_'+count}
                    />}                  
                </div>
            </div>
            
        
    );
}

export default TopCards;
