import React from 'react';
import { getCsvData } from '../../services/mappingPortalService';

function CsvPreview(props) {
    const getTable = ()=> {
        const data = getCsvData()

        let table = []
        for (let i = 0; i < data.length; i++) {
            let children = []
            if (i === 0)
                children.push(<th key={i + '' + i}>Row</th>);
            else
                children.push(<td key={i + '' + i}>{i}</td>);
            let rowLength = data[0].length;
            //Inner loop to create children
            for (let j = 0; j < rowLength; j++) {
                if (i === 0)
                    children.push(<th key={i + '.' + j}>{` ${data[i][j]}`}</th>);
                else {
                    if (data[i][j] !== undefined)
                        children.push(<td key={i + '.' + j}>{` ${data[i][j]}`}</td>);
                    else
                        children.push(<td key={i + '.' + j}>-</td>);
                }
            }
            //Create the parent and add the children
            table.push(<tr key={i}>{children}</tr>)
        }
        return table
    }

    return (
        <div className="container-fluid" align="center">
            <div className="csv-preview-div">
                <table className="table-hover">
                    <tbody>
                        {getTable()}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CsvPreview;