import React, {useEffect, useState } from 'react';
import { Card, CardText, CardBody } from "reactstrap";
import amazon from "../../../assets/amazon-icon.png";
import shopify from "../../../assets/shopify.png";
import ebay from "../../../assets/ebay-logo.png";
import walmart from "../../../assets/walmart-logo.jpg";
import EbayModal from './Modals/EbayModal';
import AmazonModal from './Modals/AmazonModal';
import ShopifyModal from './Modals/ShopifyModal';
import WalmartModal from './Modals/WalmartModal';
import { getStoreFields } from '../../../services/registrationService';

function OtherStores({marketPlacesArr, isModalOpen, toggleModal, companyKey, addStores}) {
  const [marketplace, setMarketplace] = useState('')
  const [fields, setFields] = useState({})

  const getFields = async () => {
    const formData = {
        "marketplace":
        [
            "Amazon",
            "Shopify",
            "Ebay",
            "Walmart"
        ]
    }
    return await getStoreFields(formData)
  }

  useEffect(() => {
      getFields().then(res=> {
         setFields({...res.data})
        //  console.log(res.data)
      })
  }, [])

  const storeImage = (store) => {
    if (store === "Amazon")
      return (
        <img src={amazon} alt='store-img-amazon' style={{ height: "30px" }} />
      );
    if (store === "Shopify")
      return (
        <img src={shopify} alt='store-img-shopify' style={{ height: "25px" }} />
      );
    if (store === "Ebay")
      return <img src={ebay} alt='store-img-ebay' style={{ height: "25px" }} />;
    if (store === "Walmart")
      return (
        <img src={walmart} alt='store-img-walmart' style={{ height: "25px" }} />
      );
  }

  const setModal = (e, mp) => {
     setMarketplace(mp)
     toggleModal(e)
  }

  const generateModal = () => {
    if (marketplace === "Amazon") 
        return (<AmazonModal 
                    isModalOpen={isModalOpen} 
                    toggleModal={toggleModal} 
                    className={'amazon-integration'}
                    companyKey={companyKey}
                    addStores={addStores}
                    fields={fields.StoreAmazon}
                />)

    if (marketplace === "Shopify") 
        return (<ShopifyModal 
                    isModalOpen={isModalOpen} 
                    toggleModal={toggleModal} 
                    className={'shopify-integration'}
                    companyKey={companyKey}
                    addStores={addStores}
                    fields={fields.StoreShopify}
                />)

    if (marketplace === "Ebay") 
        return (<EbayModal 
                    isModalOpen={isModalOpen} 
                    toggleModal={toggleModal} 
                    className={'ebay-integration'}
                    companyKey={companyKey}
                    addStores={addStores}
                    fields={fields.StoreEbay}
                />)

    if (marketplace === "Walmart") 
        return (<WalmartModal 
                    isModalOpen={isModalOpen} 
                    toggleModal={toggleModal} 
                    className={'walmart-integration'}
                    companyKey={companyKey}
                    addStores={addStores}
                    fields={fields.StoreWalmart}
                />)

    else 
      return null
  }

  const productCustomUpload = () => {
    return(
    <div className="col-12 col-md-6 col-lg-4">
      <Card key={5} className='integration-product-settings-card'>
        <div className='integration-product-settings-image'>
            <img src={require('../../../assets/fileUpload.png')} alt='store-img-custom' style={{ height: "25px" }} />
            <span className="p-2">Product Custom Upload</span>
        </div>
        <CardBody>
          <CardText style={{ textAlign: "center", fontSize:'13px' }}>
            Manually upload your Products and sync them later with marketplaces
          </CardText>
          <button className='integration-button' onClick={openMappingPortal}>
              Upload
          </button>
        </CardBody>
      </Card>
    </div>
    )
  }

  const openMappingPortal = () => {
     window.location = '/mappingPortal'
  }

  return (
    <React.Fragment>
        {marketPlacesArr.map((marketplace, key) => {
          return (
            <div className="col-12 col-md-6 col-lg-4" key={key}>
              <Card key={key} className='integration-product-settings-card'>
                <div className='integration-product-settings-image'>
                  {storeImage(marketplace)}
                </div>
                <CardBody>
                  <CardText style={{ textAlign: "center", fontSize:'13px' }}>
                    Connect your {marketplace} account and sync all
                    your products.
                  </CardText>
                  <button className='integration-button' onClick={(e) =>setModal(e,marketplace)}>
                    Connect
                  </button>
                </CardBody>
              </Card>
            </div>
          )
        })}
        {productCustomUpload()}
        {generateModal()}
      </React.Fragment>
  );
}

export default OtherStores;