// settings/upload
export const uploadPath = [
  {
    link: "/settings",
    label: "Settings",
  },
  {
    link: "/settings/upload",
    label: "Upload Products",
  },
];

// settings/stores
export const storesPath = [
  {
    link: "/settings",
    label: "Settings",
  },
  {
    link: "/settings/stores",
    label: "Stores",
  },
];

// settings/account
export const accountPath = [
  {
    link: "/settings",
    label: "Settings",
  },
  {
    link: "/settings/account",
    label: "Your Account",
  },
];

// settings/account/reset_password
export const resetPasswordPath = [
  {
    link: "/settings",
    label: "Settings",
  },
  {
    link: "/settings/account",
    label: "Your Account",
  },

  {
    link: "/settings/reset_password",
    label: "Reset Password",
  },
];

// settings/users
export const activeUsersPath = [
  {
    link: "/settings",
    label: "Settings",
  },
  {
    link: "/settings/users",
    label: "Active Users",
  },
];
