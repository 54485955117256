import React, { useState, useEffect } from 'react';
import GABox from './GoogleAnalytics.js/GABox';
import { setNumbers } from '../../../common-functions/setNumbers';

function ConversionWidget({data, loading, currencySymbol}) {
    const [audienceData, setAudienceData] = useState({})
    const [conversionData, setConversionData] = useState({})
    const [googleAds, setGoogleAdsData] = useState({})
    const [ecommerceData, setEcommerceData] = useState({})

    useEffect(() => {
        if(data){
            setAdData()
            setConvData()
            setGAData()
            setEcomData()
        }
    }, [data])

    const setAdData = () => {
        setAudienceData({
            totalPageViews: {
                label : 'Total Page Views',
                value : data['totalPageViews'] ? setNumbers(data['totalPageViews']) : 'N/A'
            },
            bounceRate : {
                label : 'Bounce Rate',
                value : data['bounceRate'] ? setNumbers(data['bounceRate'])+'%' : 'N/A'
            },
            avgTimeOnPage : {
                label : 'Avg. Time On Page',
                value : data['avgTimeOnPage'] ? setNumbers(data['avgTimeOnPage']) : 'N/A'
            },
            uniquePageViews : {
                label : 'Unique Page Views',
                value : data['uniquePageViews'] ? setNumbers(data['uniquePageViews']) : 'N/A'
            }
        })
    }

    const setConvData = () => {
        setConversionData({
            grossSales: {
                label : 'Gross Sales',
                value : data['grossSales'] ? currencySymbol+ setNumbers(data['grossSales']) : 'N/A'
            },
            avgOrderValue : {
                label : 'Avg. Order Value',
                value : data['avgOrderValue'] ? setNumbers(data['avgOrderValue']) : 'N/A'
            },
            totalOrders : {
                label : 'Total Orders',
                value : data['totalOrders'] ? setNumbers(data['totalOrders']) : 'N/A'
            },
            totalUsers : {
                label : 'Total Users',
                value : data['totalUsers'] ? setNumbers(data['totalUsers']) : 'N/A'
            }
        })
    }

    const setGAData = () => {
        setGoogleAdsData({
            adSpent: {
                label : 'Ad Spent',
                value : data['adSpent'] ? currencySymbol+ setNumbers(data['adSpent']) : 'N/A'
            },
            adClicks : {
                label : 'Ad Clicks',
                value : data['adClicks'] ? setNumbers(data['adClicks']) : 'N/A'
            },
            adClickThroughRate : {
                label : 'Ad Click Through Rate',
                value : data['adClickThroughRate'] ? setNumbers(data['adClickThroughRate'])+'%' : 'N/A'
            },
            ROAS : {
                label : 'ROAS',
                value : data['ROAS'] ? setNumbers(data['ROAS'])+'%' : 'N/A'
            }
        })
    }

    const setEcomData = () => {
        setEcommerceData({
            detailsPage: {
                label : 'Details Page',
                value : data['detailsPage'] ? setNumbers(data['detailsPage']) : 'N/A'
            },
            addedToCart : {
                label : 'Added To Cart',
                value : data['addedToCart'] ? setNumbers(data['addedToCart']) : 'N/A'
            },
            checkoutPage : {
                label : 'Checkout Page',
                value : data['checkoutPage'] ? setNumbers(data['checkoutPage']) : 'N/A'
            },
            conversionRate : {
                label : 'Conversion Rate',
                value : data['conversionRate'] ? setNumbers(data['conversionRate'])+'%' : 'N/A'
            }
        })
    }

    if(!loading && !data)
        return (<p className="not-available-msg">Not Available</p>)

    return (
        <div className="container-fluid widget-container p-3">
            <div className="row widget-container_1 pl-3">
                <h5>Google Analytics</h5>
            </div>
            <div className="row widget-container_2 mt-3">
                <GABox 
                    data={audienceData}
                    title={'Audience'}
                    image={'audience.png'}
                    loading={loading}
                />
                <GABox 
                    data={ecommerceData}
                    title={'E-commerce'}
                    image={'ecommerce.png'}
                    loading={loading}
                />
                <GABox 
                    data={conversionData}
                    title={'Conversion'}
                    image={'conversion.png'}
                    loading={loading}
                />
                <GABox 
                    data={googleAds}
                    title={'Google Ads'}
                    image={'google-ads.png'}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default ConversionWidget;