import React, {useState, useEffect} from 'react';
import Pagination from '../../../common/Pagination';
import { paginate } from '../../../../common-functions/paginate';
import { setNumbers } from '../../../../common-functions/setNumbers';

function Orders(props) {
  const limit = 5
  const {productOrders, currencySymbol, loadingStatus} = props

  const [searchedValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
  }, [productOrders])

  const getPagedData = () => {
    let filtered = []
    if(searchedValue){
        filtered = productOrders.filter(item => item['orderNo'].toLowerCase().includes(searchedValue.toLowerCase()) ||  item['sku'].toLowerCase().includes(searchedValue.toLowerCase()))       
    }
    else
        filtered = [...productOrders]

    const arr = paginate(filtered, currentPage, limit)
    return { totalCount: filtered.length, data: arr }
  }

  const search = (event) => {
      const {value} = event.target
      setSearchValue(value)  
  }
  const changePage = (pageNo) => {
    setCurrentPage(pageNo)
  }
  const { totalCount, data : arr} = getPagedData()

  return (
    <div className="product-orders p-3">
        <div className="row">
            <div className="col-6 col-lg-3">
                <h5>Recent Orders</h5>
            </div>
            <div className="col-6 col-lg-4">
                <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input className="form-control searchbar" type="text" placeholder="Search" name="searchedValue" value={searchedValue} aria-label="Search" onChange={search}/>
                </div>
            </div>
            <div className="col-12 col-lg-4 offset-lg-1">
                <Pagination 
                    count={totalCount}
                    currentPage={currentPage}
                    pageSize={limit}
                    changePage={changePage}
                />
            </div>
        </div> 

        {/* Orders List  */}
        <div className="wrapped-div-orders">
            <div className="row mt-3 table-header recent_orders">
                <div className="table_col">
                    <h6> ORDER NO:</h6>
                </div>
                <div className="table_col">
                    <h6> ORDERED DATE:</h6>
                </div>
                <div className="table_col">
                    <h6>SKU</h6>
                </div>
                <div className="table_col">
                    <h6>PRICE</h6>
                </div>
                <div className="table_col">
                    <h6>QUANTITY</h6>
                </div>
                <div className="table_col">
                    <h6>MARKETPLACE</h6>
                </div>
            </div>
            {arr.length === 0 && !loadingStatus ? <p className="not-available-msg">No results found</p> : null}
            {arr.map((item, key) => {
                return(
                    <div className="row listing-row recent_orders" key={key}>
                        <div className="table_col">
                            <div className="p-1 mt-2 orderNo">
                                {item.orderNo}
                            </div>  
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {item.orderDate}
                            </div>  
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {item.sku}
                            </div>
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {currencySymbol}{setNumbers(item.price)}
                            </div>
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {item.quantity}
                            </div>
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {item.marketplace}
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    </div>
                    
  );
}

export default Orders;