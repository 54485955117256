import React from 'react';
import { toastify } from '../../../common-functions/notify';
import { getUser } from '../../../services/authService';
import { getOrderNotifications } from '../../../services/notificationService';
import ExportClass from './ExportClass';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

class OrderExport extends ExportClass {
  state = {
    data : { 
        exportedData : [],
        selectedExport : '', 
        exportOptions : ['All Orders', 'Selected Orders', 'Current Page']
    },
    errors : ''
  }
  exportData = async () => {
    const {companyKey, fromDate, toDate, timeZone, orderKey, orderType, filters} = this.props
    const formData = {
       "companyKey":companyKey,
       "email":getUser().userEmail,
       "from": fromDate,
       "to": toDate,
       "timeZone":timeZone,
        orderKey,
        orderType,
        "filters": {...filters}
    }
    // console.log(JSON.stringify(formData))
    try{
      toastify('success', "Request sent", "Downloading will begin soon.")
      this.props.toggleModal()
      let res = await getOrderNotifications(formData)
      if(res !== 'success')
           toastify('error', "Error", "Some error occured. Try again later")
    }
    catch(ex){
       console.log(ex)
    }
    
  }
  render() {
    const {modalOpen, toggleModal} = this.props
    return (
      <Modal className="export-modal" show={modalOpen} size="md"    aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                  Export Orders
              </Modal.Title>
              <Button onClick={toggleModal}>Close</Button>
          </Modal.Header>
          <Modal.Body>
                <h5>Export</h5>
                <ul>
                  {this.state.data.exportOptions.map((option, key)=> {
                      return(
                          <li>
                              {this.renderRadioButton(option)}
                          </li>
                      )
                  })}
                </ul>
                {this.renderExportButton()}
          </Modal.Body>
      </Modal>

    );
  }
}

export default OrderExport;