import React, {useState, useEffect, useRef} from 'react';

function SelectBox({title: header, list, onChange, selectedItem}){
  const node = useRef()
  const [showList, setListDisplay] = useState(false)
  const [title, setTitle] = useState(false)
  
  const openList = () => {
    setListDisplay(!showList)
  }
  const handleClickOutside=(event) =>{
    if (node.current && !node.current.contains(event.target)) {
        setListDisplay(false)
      }
  }
  const onSelect = (item) => { 
    setListDisplay(false)
    onChange(item)
    if(item.label)
      setTitle(item.label)
    else
      setTitle(item)
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectedItem])

  return (
    <div className="select-box" ref={node}>
      <button className="select-label" onClick={openList}>
          <span>{title ? title : header} <i className="fa fa-angle-down"></i></span>
      </button>
      <ul className={`list-div ${showList === true ? 'active' : ''}`}>
         {list.map((item, key)=> {
            if(typeof(item) === 'object' & item !== null)
                return(
                  <li className={`list-item ${selectedItem.label === item.label ? 'active' : ''}`} key={key} onClick={() =>onSelect(item)}>
                    {item.label}
                  </li>
                )
            return(
              <li className={`list-item ${selectedItem === item ? 'active' : ''}`} key={key} onClick={()=>onSelect(item)}>
                 {item}
              </li>
            )
         })}
      </ul>
    </div>
  );
}

export default SelectBox;