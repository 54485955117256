import http from "./httpService";

const registerApiEndPoint = http.pim_app_url + "/register/newCompanyDetails";
const verifyCompanyKeyApiEndPoint = http.pim_app_url + "/register/verifyKey";
const resetPasswordApiEndPoint = http.pim_app_url + "/register/resetPassword";
const newUserApiEndPoint = http.pim_app_url + "/register/createUser";
const integrateApiEndPoint = http.pim_app_url + "/integration/setCredentials";
const processApiEndPoint =
  http.pim_app_url + "/process/checkProcess?processId=";
const accountApiEndPoint = http.pim_app_url + "/setting/postSettings";
const connectionStatusApiEndPoint =
  http.pim_app_url + "/setting/postConnectionStatus";

const deleteStoreUrl = http.pim_app_url + '/integration/deleteStores'
const integrationApi = http.pim_app_url + '/integration'

export async function registerUser(formData) {
  const res = await http.post(registerApiEndPoint, formData);

  if (res.data.result === "success") {
    localStorage.setItem("activeCompany", res.data.active);
    localStorage.setItem("email", formData.email);
    localStorage.setItem(
      "companyKeyArray",
      JSON.stringify(res.data.companyKeyArray)
    );
    localStorage.setItem("companyArray", JSON.stringify(res.data.companyArray));
    localStorage.setItem("userName", res.data.userName);
    localStorage.setItem("name", res.data.name);
    localStorage.setItem("token", res.headers["x-auth-token"]);
  }

  return res.data;
}

export function verifyCompanykey(formData) {
  return http.post(verifyCompanyKeyApiEndPoint, formData);
}

export function integrate(formData) {
  return http.post(integrateApiEndPoint, formData);
}

export function processStatus(processId) {
  return http.post(processApiEndPoint + processId);
}

export function getAccountDetails(formData) {
  return http.post(accountApiEndPoint, formData);
}

export function connectionStatus(formData) {
  return http.post(connectionStatusApiEndPoint, formData);
}

export function resetPassword(formData) {
  return http.post(resetPasswordApiEndPoint, JSON.stringify(formData));
}

export function addNewUser(formData) {
  return http.post(newUserApiEndPoint, JSON.stringify(formData));
}

export function registerWithShopify(formData){
  //  const obj = {
  //     data: {
  //       result : 'success',
  //       exists : false,
  //       country : "India",
  //       email : 'anjali@gmail.com',
  //       timezone : 'America/New_york'
  //     }
  //  }
  //  return obj
   return http.post(http.pim_app_url+'/app/registerStore', formData)
}

export function getStoreFields(formData){
   return http.post(integrationApi+'/storeFields', formData)
} 

export function deleteStore(formData){
   return http.post(deleteStoreUrl, JSON.stringify(formData));
}

export function reducer(state, { field, value }) {
  return {
    ...state,
    [field]: value,
  };
}
