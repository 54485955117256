import React, {useState, useRef, useEffect} from 'react';
import { Image } from 'react-bootstrap';
import SortProducts from '../../Products/Analytics/Listing/SortProducts';
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

function CollectionFilter({ filters, filtersSelected, showSort, onCheckboxClick : onCheck, sortProducts, applyRange, handleRangeSlide, onStockSelect}) {
	const node = useRef()
	const [activeFilter, setActiveFilter] = useState('')
	const [rangeArr, setRangeArr] = useState([])
	
	const openFilter = (filter) =>() => {
			setActiveFilter(filter.name)
	}

	const closeFilter = ()=> {
		setActiveFilter('')
	}
	
	useEffect(() => {
		document.addEventListener("mouseleave", handleClickOutside)
		return () => {
			document.removeEventListener("mouseleave", handleClickOutside)
		}
	}, [filters])

	const handleClickOutside=(event) =>{
		if (node.current && !node.current.contains(event.target)) {
				setActiveFilter('')
		}
	}
	
	const onCheckboxClick =(filterType, index, index2) =>(e) => {
		onCheck(e, filterType, index, index2)
	}

	const onRangeSlide = (index, data) => {
		 console.log(index, data)
		 let arr =[...rangeArr]
		 let temp = arr.filter(item => item.index === index)
		 if(temp.length > 0)
				 arr[arr.indexOf(temp[0])].data = {...data}
		 else
					arr.push({index : index, data: data})
		 setRangeArr([...arr])
		 handleRangeSlide(index, data)
	}

  return (
       <div className="container-fluid lg-filters" ref={node}>
				 	<div className="row filters-row">
					 		{showSort && 
								<div className={`col-6 col-md-4 col-lg-2 filter-cols ${activeFilter=== 'sortBy' ? 'active' : null}`} onMouseOver={openFilter('sortBy')} onMouseLeave={closeFilter}>
												<span>Sort By <i className="fa fa-angle-down"></i></span>
												<div className="filters-div">
														<SortProducts sortProducts={sortProducts} />
												</div>

										</div>
							}	
					 		{filters.map((filter, key) => {
								 if(filter.type === 'select')
										return(
											<div 
													className={`col-6 col-md-4 col-lg-2 filter-cols ${filter.name === activeFilter ? 'active' : null}`} 
													onMouseOver={openFilter(filter)}
													onMouseLeave={closeFilter}
													key={key}
											>
													<span>{filter.title} <i className="fa fa-angle-down"></i></span>
													<div className="filters-div">
															<ul>
																	{filter.value.map((item, key2) => {
																			return(
																					<li key={key2}>
																							<label className="custom-checkbox">
																									{item.img && <Image src={require('../../../assets/'+item.img)} thumbnail/>}
																									{item.name}
																									<input 
																											type="checkbox"
																											name={item.name}
																											onChange={onCheckboxClick(filter.name, key, key2)}
																											checked={item.checked}
																									/>
																									<span className="checkmark"></span>
																							</label>
																					</li>
																			)
																	})}
															</ul>
													</div>

											</div>
										)
							})}
							{filters.map((filter, key) => {
								 if(filter.type === 'range')
										return(
											<div 
													className={`col-6 col-md-4 col-lg-2 filter-cols ${activeFilter === 'range' ? 'active' : null}`} 
													onMouseOver={openFilter(filter)}
													onMouseLeave={closeFilter}
													key={key}
											>
													<span>{filter.title} <i className="fa fa-angle-down"></i></span>
													
													<div className="filters-div">
															<ul>
																	{filter.value.map((item, key2) => {
																			return(
																					<li key={key2} className="mt-2 pt-3">
																								<h5>{item.label}</h5>
																								<InputRange
																										maxValue={item.range.max}
																										minValue={item.range.min}
																										value={item.value}
																										// handleRangeSlide={handleRangeSlide}
																										onChange={(value) => onRangeSlide(key2, value)} 
																								/>
																					</li>
																			)
																	})}
															</ul>
															<button onClick={() =>applyRange(rangeArr)}>Apply</button>
													</div>
													
											</div>
										)
							})}
							{filters.map((filter, key) => {
								 if(filter.type === 'hybrid')
										return(
											<div 
													className={`col-6 col-md-4 col-lg-2 filter-cols ${activeFilter === filter.name ? 'active' : null}`} 
													onMouseOver={openFilter(filter)}
													onMouseLeave={closeFilter}
													key={key}
											>
													<span>{filter.title} <i className="fa fa-angle-down"></i></span>
													
													<div className="filters-div">
															<ul>
																	{filter.value.map((item, key2) => {
																			return(
																				<li key={key2}>
																						<label className='container-radio-sort'>
																								{item.name}
																									<input
																									type='radio'
																									name='sorting'
																									onChange={()=>onStockSelect(filter.name, key, key2)}
																								/>
																								<span className='checkmark-radio-sort' />
																							</label>
																				</li>
																			)
																	})}
															</ul>
													</div>
													
											</div>
										)
							})}
					</div>
		
			</div>
						
  );
}

export default CollectionFilter;