import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../../services/authService';

const ProtectedRoute = ({path, component: Component, render, ...rest}) => {
  const {home, module} = rest.computedMatch.params
  return (
    <Route
        {...rest}
        render = {props => {
            const token = getToken()
            if(!token) {
              return <Redirect to='/login' />
            }
            if(home === 'products' && module === undefined)
              return <Redirect to='/products/analytics' />
            if(home === 'settings' && module === undefined)
              return <Redirect to='/settings/data_sources' />
            return Component ? <Component {...props} /> : render(props)
        }}
    />
  );
}

export default ProtectedRoute;