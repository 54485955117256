import React, { Component } from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';

class ProductEditable extends Component {
    constructor(props){
        super(props)
        this.state = {
            total_count: 10566,
            products_array: [],
            currentProducts: [],
            currentPage: null,
            totalPages: null,
            showAddFields: false,
            selectedFields: ['','Image', 'Base Sku', 'Title', 'Creation Date', 'Brand','Product Type'],
            unselectedFields: ['Status', 'Department', 'Tags','Manufacturer', 'Country of Origin'],  
        }
        this.onPageChanged = this.onPageChanged.bind(this)
        this.openAddFieldDiv = this.openAddFieldDiv.bind(this)
        this.checkField = this.checkField.bind(this)
        this.addField = this.addField.bind(this)
    }
    componentDidMount(){
        const formData = {
            "companyKey" : this.props.companyKey
        }
        axios({
            method: 'post',
            url: 'https://www.razorprime.com/api/product/listProducts',
            headers: { 'content-type': 'application/json' },
            data : formData
        }).then(res=>{
            var response = [...res.data[0].products]
            let products_array = []
            for(let i=0; i < response.length; i++){
                products_array.push({
                    image_url : response[i].image_url,
                    baseSku : response[i].baseSku,
                    title : response[i].title,
                    creationDate: response[i].creationDate,
                    brand: response[i].brand
                })
            }
            this.setState({
                products_array
            })
        })
    }
    onPageChanged = data => {
        const { products_array } = this.state
        const { currentPage, totalPages, pageLimit } = data;
    
        const offset = (currentPage - 1) * pageLimit;
        const currentProducts = products_array.slice(offset, offset + pageLimit);
    
        this.setState({ currentPage, currentProducts, totalPages });
    }
    openAddFieldDiv(){
        this.setState({
            showAddFields : !this.state.showAddFields
        })
    }
    checkField=(item) => e =>{
        var selectedFields = [...this.state.selectedFields]
        var unselectedFields = [...this.state.unselectedFields]
        for( var i = 0; i < unselectedFields.length; i++){ 
            if ( unselectedFields[i] === item) {
                unselectedFields.splice(i, 1)
                
            }
        }
        selectedFields.push(item) 
        this.setState({
            selectedFields : selectedFields,
            unselectedFields : unselectedFields,
        })
    }
    addField(){

    }
    render() {
        const { products_array, selectedFields,unselectedFields, currentProducts} = this.state
        const totalProducts = products_array.length
        if (totalProducts === 0) return null
        // for(let i=0; currentProducts.length; i++){
        //     for(var key in currentProducts[i]){
        //         if(unselectedFields.includes(key)){
        //             delete currentProducts[i][key]
        //         }
        //     }
        // }
        const AllProducts = () =>{
            return(
                <div className="table-responsive" style={{position: 'relative'}}>
                <table className="table table-bordered" id="product-edit-table">
                    <thead>
                        <tr>
                            {selectedFields.map((item, key) =>{
                                return(
                                    <th>{item}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {currentProducts.map((product, key) =>{
                            return (
                                <tr key={key}> 
                                    <td><label className="custom-checkbox-editor">
                                                <input type="checkbox" />
                                                <span className="checkmark-editor"></span>
                                        </label></td>
                                    <td className="product-edit-cell"><img className="img-thumbnail product-img-thumbnail" src={product.image_url}/></td>
                                    <td className="product-edit-cell"><input type="text" className="product-table-input" value={product.baseSku}/></td>
                                    <td className="product-edit-cell"><input type="text" className="product-table-input" value={product.title}/></td>
                                    <td className="product-edit-cell"><input type="text" className="product-table-input" value={product.creationDate}/></td>
                                    <td className="product-edit-cell"><input type="text" className="product-table-input" value={product.brand}/></td>
                                    <td className="product-edit-cell"><input type="text" className="product-table-input" value={product.product_type}/></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
            )
        }
        return ( 
            <div className="container-fluid" >
                {/* Header with search and save fn */}
                <div className="row product-row-main pt-3">
                    <div className="col-12 col-sm-3">
                        <span className="p-2 header-span-products"> <strong>Products</strong></span><span className="p-2">|</span><span className="p-2">{this.state.total_count} results</span>
                    </div>
                    <div className="col-12 col-sm-4 offset-sm-1">
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input className="form-control searchbar" type="text" placeholder="Search by tags" aria-label="Search" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-3 offset-sm-1" style={{textAlign : 'center'}}>
                        <span className="p-2"><Button className="export-btn"><i className="fa fa-download" aria-hidden="true"></i> Export</Button></span>
                        <span className="p-2"><Button id="product-info-save-btn">Save</Button></span>
                    </div>
                </div>
                
                {/* Body with table and filters */}
                <div className="row product-row-main">
                    <div className="container-fluid" id="product-table-div">
                        
                        {/* Table haeder with filters and sorting */}
                        <div className="row p-2">
                            <div className="col-12 col-sm-4">
                                <span className="p-1"><Button className="div-btn-group"><i class="fa fa-archive" aria-hidden="true"></i> Archive</Button></span>
                                <span className="p-1">
                                    <Button className="product-editable-add-field" onClick={this.openAddFieldDiv}>
                                        <span>Add Fields</span><i className="fa fa-angle-down" style={{fontSize: '17px'}}></i>
                                    </Button>
                                    <div id="add-field-div" style={{display : this.state.showAddFields ? 'block': 'none'}}>
                                        <div className="pb-3">
                                            <h6><strong>Selected Fields</strong></h6>
                                            {this.state.selectedFields.map((field, key) =>{
                                                return(
                                                    <span key={key} className="p-2"> {field}</span>
                                                )
                                            })}
                                        </div>
                                        <hr></hr>
                                        <div>
                                            <h6><strong>Add Fields</strong></h6>
                                            {this.state.unselectedFields.map((item, key) =>{
                                                return(
                                                    <span key={key} className="p-2"> <input key={key} type="checkbox" name={item}  onChange={this.checkField(item)}/> {item}</span>
                                                )
                                            })}
                                        </div>
                                       
                                    </div>
                                </span>
                            </div>
                            <div className="col-12  offset-sm-6 col-sm-2 pt-3" style={{alignContent: 'right'}}>
                                {/* <Pagination className="product-paginate"  totalRecords={totalProducts}  pageLimit={20}  pageNeighbours={1} onPageChanged={this.onPageChanged} /> */}
                            </div>
                        </div>
                       
                       {/* Product listing */}
                       <AllProducts />
                    </div>
                </div>
            </div>
         );
    }
}
 
export default ProductEditable;