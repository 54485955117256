import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function CollectionList(props) {
  const [products_array, setProducts] = useState([])

  useEffect(() => {
    setProducts(props.products_array)
},[props])
  const { enlargeImage} = props
  return (
    <React.Fragment>
        <div className="row pim_product_list">
            <div className="pim_product_list_header d-flex">
              <div className="flex-grow">
                  
              </div>
              <div className="flex-grow">
                  SKU
              </div>
              <div className="flex-grow">
                  TITLE
              </div>
              <div className="flex-grow">
                  BRAND
              </div>
              <div className="flex-grow">
                  Product Type
              </div>
            </div>
        </div>
        {products_array.map((product, key) => {
            return(
              <div className="row pim_product_list" key={key}>
                <div className="product_row d-flex">
                  <div className="flex-grow" onMouseOver={() =>enlargeImage(product.image_url)} onMouseLeave={() => enlargeImage('')}>
                    <Image
                          src={product.image_url}
                          alt='Card image cap'
                          className='pim_product-img'
                    />
                  </div>
                  <div className="flex-grow">
                    <Link 
                      to={`/products/pim/${product.baseSku}/base`}
                      params={{ type: product.baseSku, category:'base' }}>
                        {product["baseSku"]}
                    </Link>
                  </div>
                  <div className="flex-grow">
                    {product["title"]}
                  </div>
                  {/* <div className="flex-grow">
                    {product["brand"]}
                  </div> */}
                  <div className="flex-grow">
                    {product["brand"]}
                  </div>
                  <div className="flex-grow">
                    {product["productType"]}
                  </div>
                </div>              
              </div>
            )
        })}
    </React.Fragment>    
  );
}

export default CollectionList;