import React from "react";

const Header = ({ title, secondaryText }) => {

  return (
    <div className='row lyzer-header'>
      <div className='col-5 col-lg-3'>
        <span className='heading'>{title}</span>
        {secondaryText && (
          <span className='secondary-heading'> {secondaryText}</span>
        )}
      </div>
      {/* <div className */}
    </div>
  );
};

export default Header;
