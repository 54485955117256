import React, { useEffect, useState } from 'react';
import Store from "./Store";
import amazon from "../../../assets/amazon-icon.png";
import shopify from "../../../assets/shopify.png";
import ebay from "../../../assets/ebay-logo.png";
import walmart from "../../../assets/walmart-logo.jpg";
import StoreModal from './Modals/StoreModal';

function ConnectedStores({connectedStores, companyKey, loading}) {
  const [display, setDisplay] = useState(false)
  const [stores, setStores] = useState([])
  const [store, setStore] = useState({})

  useEffect(() => {
     setStores([...connectedStores])
  }, [connectedStores])

  const storeImage = (store) => {
    if (store === "Amazon")
      return (
        <img src={amazon} alt='store-img-amazon' style={{ height: "30px" }} />
      );
    if (store === "Shopify")
      return (
        <img src={shopify} alt='store-img-shopify' style={{ height: "25px" }} />
      );
    if (store === "Ebay")
      return <img src={ebay} alt='store-img-ebay' style={{ height: "25px" }} />;
    if (store === "Walmart")
      return (
        <img src={walmart} alt='store-img-walmart' style={{ height: "25px" }} />
      );
  }

  const openModal = (store) => {
     setDisplay(!display)
     setStore(store)
  }

  if (stores.length === 0)
      return <p className='settings-no-store'>No stores connected</p>;
    else
      return (
        <React.Fragment>
            {loading &&
                <div className="d-flex justify-content-around loading">
                    <div className="p-2"><span></span></div>
                    <div className="p-2"><span></span></div>
                    <div className="p-2"><span></span></div>
                    <div className="p-2"><span></span></div>
                    <div className="p-2"><span></span></div>
                </div>
            }
            {!loading && stores.map((store, key) => (
                <Store store={store} key={key} storeImage={storeImage} openModal={openModal} />
            ))}
            {!loading && display && <StoreModal toggleModal={openModal} display={display} store={store} companyKey={companyKey}/>}
        </React.Fragment>
      );
}

export default ConnectedStores;