import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function CircProgressBar({perc}) {
  return (
    <div className="circular-progress-bar">
        <CircularProgressbar 
            value={perc} 
            text={`${parseInt(perc)}%`} 
            strokeWidth={10}
            styles={buildStyles({
              textSize: '28px',
              pathColor: perc > 50 ? 'red' : 'green',
              textColor: 'black'
            })}
        />
    </div>
  );
}

export default CircProgressBar;