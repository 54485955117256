import React from 'react';

const AccountDiv = ({showUserDiv, iconLetter, userName, userEmail, logout, openSettings}) =>  {
    return(
    <div className="userDiv" style={{display: showUserDiv === true ? 'block': 'none' }} >
        <div className="userDiv_div d-flex justify-content-around">
        <div className="p-2">
            <div className="user-icon">
                <span>{iconLetter}</span>  
            </div>
        </div>
        <div className="p-2">
            <h6>Hello {userName}</h6>
            <p>{userEmail}</p>
        </div>
    </div>
        <ul>
            <li onClick={openSettings}>
                Account Settings
            </li>
            <li onClick={logout}>
                Logout
            </li>
        </ul>
    </div>
      
    )
}

export default AccountDiv