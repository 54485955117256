import React, { Component } from "react";
import { Link } from "react-router-dom";
import SettingsNav from "./SettingsNav";
import SettingsHeader from "./SettingsHeader";
import { getAccountDetails } from "../../services/registrationService";
import Breadcrumbs from "./Breadcrumbs";
import { accountPath } from "./BreadcrumbData";

class SettingsAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyKey: props.companyKey,
      EmailID: "",
      CompanyName: "",
      TimeZone: "",
      Currency: "",
      Country: "",
    };
  }
  componentDidMount = async () => {
    try {
      const { CompanyKey } = this.state;
      const formData = { companyKey: CompanyKey };
      const resp = await getAccountDetails(formData);
      // console.log(formData, resp);

      if (resp.data.data) {
        const {
          EmailID,
          CompanyName,
          TimeZone,
          Currency,
          Country,
        } = resp.data.data.CompanyDetails;

        this.setState({ EmailID, CompanyName, TimeZone, Currency, Country });
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  render() {
    const {
      EmailID,
      CompanyName,
      CompanyKey,
      TimeZone,
      Currency,
      Country,
    } = this.state;
    return (
      <div className='settings-container'>
        {/* Aside */}
        <SettingsNav />
        {/* Main */}
        <main className='settings-view'>
          {/* Header  */}
          <SettingsHeader />
          <div className='container-fluid settings-main'>
            {/* breadcrumbs  */}
            <Breadcrumbs items={accountPath} />
            <div className='row profile'>
              <div className='row container-fluid '>
                <div className='col-sm-2 profile-label'>My Profile</div>
                <div className='col-sm-2 offset-sm-8'>
                  {" "}
                  <button className='ml-4 settings-save-btn'>Save</button>
                </div>
              </div>

              <div className='row profile-content container-fluid p-3'>
                <div className='col-sm-3'>
                  <div className='row'>
                    <span className='profile-pic'></span>
                  </div>
                  <div className='row pt-3 profile-pic-label'>
                    Upload Profile Picture
                  </div>
                </div>
                <div className='col'>
                  <div className='row profile-field'>
                    <span className='col-sm-3'>Email</span>
                    <span className='col'>{EmailID}</span>
                  </div>
                  <div className='row profile-field'>
                    <span className='col-sm-3'>Password</span>
                    <span className='col'>
                      ************{" "}
                      <Link
                        to='/settings/account/reset_password'
                        className='reset'
                      >
                        Reset Password
                      </Link>
                    </span>
                  </div>
                  <div className='row profile-field'>
                    <span className='col-sm-3'>Role</span>
                    <span className='col'>Admin</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Company details */}
            <div className='row profile'>
              <div className='row profile-label ml-1'>Company Details</div>
              <div className='row profile-content container-fluid mt-4 pb-2'>
                <div className='col'>
                  <div className='row profile-field'>
                    <span className='col-sm-3'>Company Name</span>
                    <span className='col'>{CompanyName}</span>
                  </div>
                  <div className='row profile-field'>
                    <span className='col-sm-3'>Company Key</span>
                    <span className='col'>{CompanyKey}</span>
                  </div>
                  <div className='row profile-field'>
                    <span className='col-sm-3'>Country</span>
                    <span className='col'>{Country}</span>
                  </div>
                  <div className='row profile-field'>
                    <span className='col-sm-3'>Time Zone</span>
                    <span className='col'>{TimeZone}</span>
                  </div>
                  <div className='row profile-field'>
                    <span className='col-sm-3'>Currency</span>
                    <span className='col'>{Currency}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default SettingsAccount;
