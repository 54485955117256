import React, { Component } from 'react';
import InfoTabs from './common/InfoTabs';
import ProductInfoLinks from './common/ProductInfoLinks';
import { getProductInfo, saveProduct } from '../../../services/productService';
import ProductHeader from './common/ProductHeader';
import Loading from '../../common/Loading';

class ProductEdit extends Component {
    constructor(props){
        super(props)
        this.state = {
            baseSku: this.props.sku,
            variantSku : this.props.variantSku,
            vitalInfoObject : {},
            variants: [],
            shownVariants: [],
            images: [],
            marketplaces: {},
            selectedMarketPlaces:[],
            unselectedMarketPlaces: [],
            tabArray : [],
            category : this.props.category,
            selectedVariant: '',
            listVariants: {},
            postFields : {},
            errors :[],
            displayDropDown : false,
            variantId: null,
            modalShow: false,
            activeDivName: '',
            selectedTab: '',
            searchedValue: '',
            loading: true,
        }
        this.handleVitalInputChange = this.handleVitalInputChange.bind(this)
        this.handleMarketPlaceBaseInput = this.handleMarketPlaceBaseInput.bind(this)
        this.handleVariantInputChange = this.handleVariantInputChange.bind(this)
        this.handleMarketPlaceVariantInput = this.handleMarketPlaceVariantInput.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.addCategoryAttributes = this.addCategoryAttributes.bind(this)
    }
    async componentDidMount(){
        try{
            const formData = {
                'companyKey' : this.props.companyKey,
                'productSku' : this.state.baseSku
            }
    
            const res = await getProductInfo(formData)
            console.log(res.data)

            var temp_arr = res.data.product.marketplaces
            var temp_arr2 = []
            var temp_arr4 = []
            for(var key in temp_arr){
                var attriName = key.toLowerCase()+'BaseAdd'
                if(temp_arr[key][attriName].value === true){
                    temp_arr2.push(key)
                }
                else {
                    temp_arr4.push(key)
                }
            }

            // fetch variants added in the marketplaces
            var listVariants = {}
            var temp_arr3 = [...res.data.product.variants]

            for(var key in temp_arr){
                var variants = []
                for(let j=0; j < temp_arr3.length; j++){
                    var attriName = key.toLowerCase()+'VariantExists'
                    if(temp_arr3[j].marketplaces[key][attriName].value === true){
                        variants.push({
                            variant : temp_arr3[j].attributes.sku.value,
                            exists : true 
                        })
                    }
                    else {
                        variants.push({
                            variant : temp_arr3[j].attributes.sku.value,
                            exists : false 
                        })
                    }
                }
                listVariants[key] = variants
            }
            
            //set selectedVariant 
            var variant = this.props.variantSku
            var selectedVariant = {}
            var variantId = null
            if(variant === undefined){
                selectedVariant = res.data.product.variants[0]
                variantId = 0
            }
            else {
                var arr = [...res.data.product.variants]
                for(let i=0; i < arr.length; i++){
                    if(variant === arr[i].attributes.variantKey.value){
                        selectedVariant = arr[i]
                        variantId = i
                    }
                }
            }

            this.setState({
                vitalInfoObject : res.data.product.attributes,
                variants : res.data.product.variants,
                images : res.data.product.images,
                marketplaces : res.data.product.marketplaces,
                selectedMarketPlaces : temp_arr2,
                unselectedMarketPlaces : temp_arr4,
                listVariants : listVariants,
                selectedVariant : selectedVariant,
                variantId: variantId,
                loading : false
            }) 

            if(this.props.category === 'base'){
                this.setState({
                    tabArray : ['Vital Info', 'Others'],
                    selectedTab : 'Vital Info'
                })
            }
            else if(this.props.category === 'variant'){
                var tabArray = ['Core Info', ...this.state.selectedMarketPlaces]
                this.setState({
                    tabArray : tabArray,
                    selectedTab: tabArray[0]
                })
            }
            else if(this.props.category === 'marketplace'){
                this.setState({
                    tabArray : this.state.selectedMarketPlaces,
                    selectedTab : this.state.selectedMarketPlaces[0]
                })
            }
            else if(this.props.category === 'images'){
                this.setState({
                    tabArray : ['Images'],
                    selectedTab :['Images']
                })
            }
        }

        catch(e){
            console.log(e)
        }
        
    }
    handleVitalInputChange = (childDataObject)=>{
        const {name, value} = childDataObject
        this.setState(prevState =>({
            vitalInfoObject : {
                ...prevState.vitalInfoObject,
                [name] : {
                    ...prevState.vitalInfoObject[name],
                    value : value
                }
            },
            postFields : {
                ...prevState.postFields,
                vitalInfoObject : {
                    ...prevState.postFields.vitalInfoObject,
                    [name] : value,
                }
            }
        }))
    }
    handleMarketPlaceBaseInput = (marketPlace, childData) =>{

        const {postFields} = this.state

        const {name, value} = childData

        this.setState(prevState=>({
            marketplaces : {
                ...prevState.marketplaces,
                [marketPlace] : {
                    ...prevState.marketplaces[marketPlace],
                    [name] : {
                        ...prevState.marketplaces[marketPlace][name],
                        value : value
                    },
                   [marketPlace.toLowerCase()+'BaseAdd'] : {
                        ...prevState.marketplaces[marketPlace][marketPlace.toLowerCase()+'BaseAdd'],
                        value : true
                   }
                }
            }
        }))
        
    }
    handleVariantInputChange = (childDataObject,id) =>{
        const {name, value} = childDataObject
        var variants = [...this.state.variants]
        variants[id].attributes[name].value = value 
        this.setState(prevState=>({
            variants : variants,
                postFields : {
                    ...prevState.postFields,
                    variants : {
                    ...prevState.postFields.variants,
                    [name] : value,
                }
            }
        }))
        
    }
    handleMarketPlaceVariantInput = (idx, marketPlace, childData) =>{
        const {name, value} = childData
        var variants = [...this.state.variants]
        var temp = marketPlace.toLowerCase()+'VariantAdd'
        variants[idx].marketplaces[marketPlace][name].value = value
        variants[idx].marketplaces[marketPlace][temp].value = true
        this.setState({
            variants
        })
    }
    handleSave(){
        var products = {
            "attributes" : this.state.vitalInfoObject,
            "variants" : this.state.variants,
            "marketplaces" : this.state.marketplaces
        }
        
        const formData = {
            "companyKey" : this.props.companyKey,
            "product" : products
        }
        this.sendAxiosRequest(formData)
    }
    async sendAxiosRequest(formData){
        console.log(formData)

        try{
            const res = await saveProduct(formData)
            console.log(res.data)
        }
        catch(e){
            console.log(e)
        }
    }
    addCategoryAttributes=(objI, objII, marketPlace)=> {
        let marketplaces = {...this.state.marketplaces}
        let variants = [...this.state.variants]
        for(let key in objI){
            marketplaces[marketPlace][key]= objI[key]
        }
        for(let i=0; i < variants.length; i++){
            for(let key in objII){
                variants[i]['marketplaces'][marketPlace][key] = objII[key]
            }
        }
        this.setState({
            marketplaces : marketplaces,
            variants : variants
        })
    }
    setModalShow=()=>{
        this.setState({
            modalShow: !this.state.modalShow
        })
    }
    onHide=()=>{
        this.setState({
            modalShow: false,
        })
    }
    openDiv=(item)=>()=>{
        var arr = [...this.state.tabArray]
        arr.push(item)
        this.setState({
            tabArray : arr,
            selectedTab : item,
            modalShow : false
        })
    }
    saveMarketPlace = () => {

    }
    addVariant(){

    }    
    searchedVariants=(e)=> {
        const {name, value} = e.target
        const shownVariants = this.state.variants.filter(function(item){
            return item.attributes.sku.value.toLowerCase().search(
                value.toLowerCase()) !== -1;
            })
        this.setState({
            shownVariants : shownVariants,
            name : value
        })
    }
    render() {
        const {
            baseSku, variantSku, images, vitalInfoObject, variants, marketplaces, tabArray, listVariants, selectedMarketPlaces, unselectedMarketPlaces, selectedVariant, category, modalShow, variantId, loading} = this.state
        return ( 
            loading === true ?
            <Loading />
            :
            <div className="container-fluid">
                <div className="row">

                    {/* Categories available to edit */}
                    <div className="col-md-12 col-lg-3 col-xl-2 product-left pt-5">
                        <ProductInfoLinks 
                            baseSku={baseSku} 
                            variantSku={selectedVariant.attributes.variantKey.value} 
                            selectedMarketPlaces={selectedMarketPlaces} 
                            category={category} 
                            variants={variants}
                        />
                    </div>

                    {/* Categories Information to edit */}
                    <div className="col-md-12 col-lg-9 col-xl-10 product-right">
                         {/* Header */}
                        <div className="container-fluid product-right_top pl-1">
                            <div className="row top_rows1 lyzer-header">
                                <div className="col-12 col-md-5 col-lg-3">
                                    <span className="span1 m-2 pr-3">Products</span>
                                    <span className="span2 m-2">{baseSku}</span>
                                </div>
                                <div className="col-12 col-md-3 offset-lg-3 col-lg-2 save-btn">
                                    <button onClick={this.handleSave}>Save</button>
                                </div>
                            </div>

                            {/* Product Image and Title */}
                            <ProductHeader 
                                title={vitalInfoObject.title.value}
                                brand={vitalInfoObject.brand.value}
                                category={this.props.category}
                                unselectedMarketPlaces={unselectedMarketPlaces}
                                setModalShow={this.setModalShow}
                                modalShow={modalShow}
                                onHide={this.onHide}
                                openDiv={this.openDiv}
                                images={images}
                            />
                        </div>
                        
                        {/* Tabs */}
                        <div className="container-fluid product-right_bottom pl-1 mt-2">
                            {/* <h5>Not available now.</h5> */}
                            <InfoTabs 
                                vitalInfoObject={vitalInfoObject}
                                variantSku={variantSku}
                                variants={variants}
                                variantId={variantId}
                                selectedVariant={selectedVariant}
                                marketplaces={marketplaces}
                                images={images}
                                category={category}
                                tabArray={tabArray}
                                listVariants={listVariants}
                                addCategoryAttributes={this.addCategoryAttributes}
                                handleVitalInputChange={this.handleVitalInputChange}
                                handleVariantInputChange={this.handleVariantInputChange}
                                handleMarketPlaceBaseInput={this.handleMarketPlaceBaseInput}
                                handleMarketPlaceVariantInput={this.handleMarketPlaceVariantInput}
                            />
                        </div>
                    </div>
                </div>
            </div>  );
    }
}
export default ProductEdit;


  