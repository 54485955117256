import React, { Component } from "react";
import SideBar from "./common/sidebar/SideBarComponent";
import Image from "react-bootstrap/Image";
import DropDown from "./UI Elements/DropDownII.js";
import RenderedComponent from "./RenderedComponent";
import {
  getCompanyArray,
  getActiveCompany,
  getActiveCompanyInfo,
  setActiveCompany,
} from "../services/authService";
import { ToastContainer} from 'react-toastify';
import { toastify } from '../common-functions/notify';
import DateFilter from "./common/Filters/DateFilter";
import setDateFormat from "../common-functions/setDateFormat";
import Notification from "./common/Notifications/Notification";

const defaultTimePeriod = 30

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayCompanies: false,
      companies: [],
      activeCompany: "",
      showCloseIcon: false,
      activeClass: "",
      companyInfo: {},
      fromDate : '',
      toDate : '', 
    };

    this.changeCompanyKey = this.changeCompanyKey.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.closeNav = this.closeNav.bind(this)
  }
  componentDidMount() {
    let companies = getCompanyArray();
    let activeCompany = getActiveCompany();
    let companyInfo = getActiveCompanyInfo(activeCompany)

    const d1 = new Date()
    const from = (d1.getTime()/1000) - (86400*defaultTimePeriod)
    const d2 = new Date(from*1000)
    this.setState({ companies, activeCompany, companyInfo, fromDate : d2, toDate : d1})
  }
  componentDidUpdate(prevProps){
    if(prevProps.match.params.home != this.props.match.params.home){
       this.closeNav()
    }
  }
  setDate = (fromDate, toDate) => {
     this.setState({fromDate, toDate})
  }
  notify = (type, msg) => {
    toastify(type, msg)
  }
  changeCompanyKey = (childData) => {
    setActiveCompany(childData);
    const companyInfo = getActiveCompanyInfo(childData);

    this.setState(
      {
        activeCompany: childData,
        companyInfo: companyInfo,
      },
      () => this.redirectPage()
    );
  }
  redirectPage = () => {
    if (this.props.match.params.home === "products"){
      if(this.props.match.params.module === 'pim')
         this.props.history.push("/products/pim")
      else
          this.props.history.push("/products/analytics")
    }
    else if (this.props.match.params.home === "orders")
        this.props.history.push("/orders")
    else if (this.props.match.params.home === "analytics")
        this.props.history.push("/analytics")
    else if (this.props.match.params.home === "mappingPortal")
        this.props.history.push("/mappingPortal")
    else if (this.props.match.params.home === "settings"){
        if(this.props.match.params.module === "account")
            this.props.history.push("/settings/account")
        else if(this.props.match.params.module === "users")
            this.props.history.push("/settings/users")
        else
            this.props.history.push("/settings")
    }
    else 
        this.props.history.push("/dashboard")
  }
  openMenu() {
    this.setState({
      activeClass: "active",
    });
  }
  closeNav() {
    this.setState({
      activeClass: "",
    });
  }

  render() {
    const { activeClass, activeCompany, companyInfo, companies, fromDate, toDate } = this.state;

    return (
      <div className='wrapper'>
        <SideBar
          linkName={this.props.match.params.home}
          activeClass={activeClass}
          close={this.closeNav}
        />
        <div id='content'>
          <div className='hamburger-icon' onClick={this.openMenu}>
            <Image src={require("./../assets/hamburger.png")} />
          </div>

          <Notification 
              companyKey={activeCompany}
          />

          <DropDown
            companies={companies}
            activeCompany={activeCompany}
            changeCompanyKey={this.changeCompanyKey}
          />
          <div className='container-fluid'>
            <div className='row body'>
                <DateFilter setDate={this.setDate} fromDate={fromDate} toDate={toDate} />
                {activeCompany && 
                    <RenderedComponent
                      companyKey={activeCompany}
                      companyInfo={companyInfo}
                      match={this.props.match}
                      history={this.props.history}
                      notify={this.notify}
                      fromDate= {setDateFormat(fromDate)}
                      toDate= {setDateFormat(toDate)}
                      onNotificationDataRecieved={this.onNotificationDataRecieved}
                    />
                }
            </div>
          </div>
          <ToastContainer 
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
          />
        </div>
      </div>
    );
  }
}

export default Main;
