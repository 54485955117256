import React, { useReducer, useEffect } from "react";
import { resetPasswordPath } from "./BreadcrumbData";
import SettingsNav from "./SettingsNav";
import SettingsHeader from "./SettingsHeader";
import { reducer, resetPassword } from "../../services/registrationService";
import Breadcrumbs from "./Breadcrumbs";
import { toastify } from "../../common-functions/notify";

const initialState = {
  companyKey: "",
  email: "",
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  error: "",
};

const ResetPassword = ({notify}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      field: "companyKey",
      value: localStorage.getItem("activeCompany"),
    });
    dispatch({ field: "email", value: localStorage.getItem("email") });
  }, []);

  const handleChange = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const renderValidation = () => {
    if (error.length > 0)
      return <span className='form__validation p-2'>{error}</span>;
    else return <span className='form__validation p-2'>&nbsp;</span>;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        state.oldPassword.length === 0 ||
        state.newPassword.length === 0 ||
        state.confirmPassword.length === 0
      ) {
        dispatch({ field: "error", value: "Field cannot be left empty" });
      } else if (state.newPassword !== state.confirmPassword) {
        dispatch({ field: "error", value: "Passwords do not match" });
      } else {
        const resp = await resetPassword(state)
        if (resp.data.result === "error")
          dispatch({ field: "error", value: resp.data.message });
        else {
          toastify('success','Password Succesfully Changed.', '')
          setTimeout(function(){ window.location='/settings/account' }, 2000)          
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { oldPassword, newPassword, confirmPassword, error } = state;

  return (
    <div className='settings-container'>
      {/* Aside */}
      <SettingsNav />
      {/* Main */}
      <main className='settings-view'>
        {/* Header  */}
        <SettingsHeader />
        <div className='container-fluid settings-main'>
          {/* breadcrumbs  */}
          <Breadcrumbs items={resetPasswordPath} />

          <div className='row profile '>
            <div className='row container-fluid '>
              <div className='col-sm-2 profile-label settings__reset-password-heading'>
                Reset Password
              </div>
            </div>
            <form className='settings__reset-password-form ml-3'>
              {renderValidation()}
              <div className='form-group mt-1'>
                <input
                  type='password'
                  className='form-control'
                  id='old-password'
                  placeholder='Old Password'
                  name='oldPassword'
                  value={oldPassword}
                  onChange={handleChange}
                />
              </div>
              <div className='form-group mt-4'>
                <input
                  type='password'
                  className='form-control'
                  id='new-password'
                  placeholder='New password'
                  name='newPassword'
                  value={newPassword}
                  onChange={handleChange}
                />
              </div>
              <div className='form-group mt-4'>
                <input
                  type='password'
                  className='form-control'
                  id='confirm-password'
                  placeholder='Confirm password'
                  name='confirmPassword'
                  value={confirmPassword}
                  onChange={handleChange}
                />
              </div>
              <button
                className='integration-button mt-4'
                style={{ width: "8rem" }}
                onClick={handleSubmit}
              >
                Reset
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ResetPassword;
