import React from 'react';
import {FormGroup, Col, Input, Label} from 'reactstrap';
import { TextArea } from 'semantic-ui-react';

const InputBox =({label, product, handleVitalInputChange, type})=> {
  return (
    <FormGroup row key="form-row-1">
        <Label htmlFor="title" md={8}>{label}</Label>
        <Col md={10}>
           {type=== 'text' ?
              <Input 
                  type="text" 
                  className="product-input" 
                  name={product[0]}
                  value={product[1].value} 
                  onChange={handleVitalInputChange} 
                  readOnly={!product[1].editable} 
              /> 
              :
              <TextArea
                className="product-input"
                name={product[0]} 
                value={product[1].value} 
                onChange={handleVitalInputChange} 
                readOnly={!product[1].editable}
              />
            }
        </Col>
    </FormGroup>
  );
}  

export default InputBox;