import React, {useState, useEffect} from 'react';
import Pagination from '../../../common/Pagination';
import Image from 'react-bootstrap/Image';
import { paginate } from '../../../../common-functions/paginate';
import { setNumbers } from '../../../../common-functions/setNumbers';
import { CSVLink } from "react-csv";

function Variants({variants, currencySymbol, mainImage, loadingStatus}) {
  const limit = 5
  
  const [searchedValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [variantCsvData, setCsvData] = useState([])

  useEffect(() => { 
        // console.log(variants)
        setDataInCsv()
  }, [variants, loadingStatus])

  const getPagedData = () => {
    let filtered = []
    if(searchedValue){
        filtered = variants.filter(item => item['sku'].toLowerCase().includes(searchedValue.toLowerCase()) || (item['color'] && item['color'].toLowerCase().includes(searchedValue.toLowerCase())) 
        ||  (item['size'] && item['size'].toLowerCase().includes(searchedValue.toLowerCase()))
        )       
    }
    else
        filtered = [...variants]
    const arr = paginate(filtered, currentPage, limit); 
    return { totalCount: filtered.length, data: arr };
  }

  const setDataInCsv = () => {
    if(!loadingStatus){
        let csvHeader = Object.keys(variants[0])
        let csvRows = [csvHeader]  

        for(let i=0; i < variants.length; i++){
            let temp_arr = []
            for(let j=0; j < csvHeader.length; j++){
                temp_arr.push(variants[i][csvHeader[j]])             
            }
            csvRows.push(temp_arr)
        }
        setCsvData(csvRows)
    }
  }
  
  const search = (event) => {
    const {value} = event.target
    setSearchValue(value) 
  }
  const changePage = (pageNo) => {
      setCurrentPage(pageNo)
  }

  const { totalCount, data : arr} = getPagedData()

  return (
        <div className="product-variants p-3">
            <div className="row">
                <div className="col-6 col-md-3 col-lg-3">
                    <h5>Variants</h5>
                </div>
                <div className="col-6 col-lg-4">
                    <div className="form-group has-search">
                        <span className="fa fa-search form-control-feedback"></span>
                        <input className="form-control searchbar" type="text" placeholder="Search" name="searchedValue" value={searchedValue} aria-label="Search" onChange={search}/>
                    </div>
                </div>
                <div className="col-12 col-md-1 export">
                     {!loadingStatus && <CSVLink data={variantCsvData} filename={"variants.csv"}>
                        <i className='fa fa-download p-1' aria-hidden='true' />
                        Export
                      </CSVLink>}
                </div>
                <div className="col-12 col-md-5 col-lg-4">
                    <Pagination 
                        count={totalCount} 
                        currentPage={currentPage} 
                        changePage={changePage}
                        pageSize={limit}
                    />
                </div>
            </div>
            {/* Variants List  */}
            <div className="wrapped-div-variants">
                <div className="row mt-3 table-header">
                    <div className="table_col">
                        <h6>SKU</h6>
                    </div>
                    <div className="table_col">
                        <h6>COLOR</h6>
                    </div>
                    <div className="table_col">
                        <h6>SIZE</h6>
                    </div>
                    <div className="table_col">
                        <h6>INVENTORY</h6>
                    </div>
                    <div className="table_col">
                        <h6>ORDERED QTY</h6>
                    </div>
                    <div className="table_col">
                        <h6>PRICE</h6>
                    </div>
                    <div className="table_col">
                        <h6>ROS</h6>
                    </div>
                    <div className="table_col">
                        <h6>DOI</h6>
                    </div>
                </div>
                {arr.length === 0 ? <p className="not-available-msg">No results found</p> : null}
                {arr.map((item, key) => {
                    return(
                        <div className="row listing-row" key={key}>
                            <div className="table_col">
                                <div className="d-flex justify-content-around">
                                    <div className="p-1 flex-fill">
                                        {item.imgUrl ? <Image src={item.imgUrl}/> :
                                            <Image src={mainImage}/>
                                        }
                                    </div>
                                    <div className="p-1 mt-2 flex-fill sku">
                                        {item.sku.toUpperCase()}
                                    </div>
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {item.color}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {item.size}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {item.inventory}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {item.orderQty}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {currencySymbol}{setNumbers(item.price)}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {(item.ros).toFixed(3)}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {setNumbers(item.doi)}
                                </div>
                            </div>
                        </div>
                    )
                })}
            
            </div>
        </div>
   
  );
}

export default Variants;