import React from 'react';
import {Form} from 'reactstrap';
import InputBox from '../common/InputBox';

const Others =({vitalInfoObject, handleVitalInputChange}) =>{
  return (
    <Form>
    {Object.entries(vitalInfoObject).map((item,key)=>{
        if(item[1].config && JSON.parse(item[1].config).tab === 'Other Fields'){
            if( item[1].type === 'textarea' & item[1].visibility === true) {
                return(
                  <InputBox
                      type="text"
                      key={key} 
                      product={item}
                      label={item[1].title}
                      handleVitalInputChange={handleVitalInputChange}
                  />
                )
            }
            else if(item[1].type === 'wysiwyg' & item[1].visibility === true){
                return(
                      <InputBox
                          type="textarea"
                          key={key} 
                          product={item}
                          label={item[1].title}
                          handleVitalInputChange={handleVitalInputChange}
                      />
                    )
            }
            else 
                return null
        }
    })}   
</Form>

  );
}

export default Others;