import React from 'react';
import Form from './form';
import { login } from "../../services/authService";
import bcrypt from "bcryptjs";

class LoginForm extends Form {
  state = {
     data : { email : '', password : ''},
     message : ''
  }

  doSubmit = async () => {
    let { email, password } = this.state.data;

    const salt = await bcrypt.genSalt(10);
    password = await bcrypt.hash(password, salt);

    const formData = {
      email,
      password
    }
    const res = await login(formData)
    if (res.result === "success") 
        window.location= '/dashboard'
    else
        this.setState({message : 'Invalid Credentials'})
  }
  render() {
    const {message} = this.state
    return (
      <div className='container-fluid login'>
          <h1>Login</h1>
          <p className="error-msg mt-2">{message ? message : null}</p>
          <form onSubmit={this.handleSubmit}>
            {this.renderLabel('Email')}
            {this.renderInput("email", "Email", "email")}
            {this.renderLabel('Password')}
            {this.renderInput("password", "Password", "password")}
            <div className='btns'>
                {this.renderButton("Login", 'login')}
            </div>
          </form>
          <p className='create-account'>
            Don't have an account ?{" "}
            <span
              className='clickable'
              onClick={() => window.location='/register'}
            >
              Register
            </span>
          </p>
      </div>
    );
  }
}

export default LoginForm;