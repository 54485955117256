import React, { useEffect } from 'react';
import NavigationBar from './NavigationBar';
import Buttons from './Button';
import RenderedComponent from './RenderedCmponent';

function NavigateComponent({categories, selectedCategory, importFile, next, templateData, completeTemplateData, companyKey, setData, setShouldSave, finalData, notify, response, selectedImport, selectedMp}) {
  useEffect(()=> {
  }, [selectedCategory])
  return (
    <div className="container-fluid navigation">
        {selectedCategory === 'Current Import' ? null :
          <React.Fragment>
            <NavigationBar categories={categories} selectedCategory={selectedCategory}/>
            <Buttons                     
                category={selectedCategory}
                next ={next}
                importFile={importFile}
            /> 
          </React.Fragment>
        }
        <RenderedComponent
             category={selectedCategory}
             templateData={templateData}
             companyKey={companyKey} 
             completeTemplateData={completeTemplateData} 
             setData={setData}
             finalData={finalData}
             importFile={importFile}
             notify={notify}
             response={response}
             importType={selectedImport}
             selectedMp={selectedMp}
             next={next}
        />
    </div>
  );
}

export default NavigateComponent;