import React from 'react';
import {Form, FormGroup, Col, Input, Label} from 'reactstrap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function ShopifyTemplate(props) {
  const { attributeArray, tabSelected} = props
  return (
    <div>
        <Form>
            {Object.entries(attributeArray).map((item, key) => {
                if(item[1].type === 'textarea' & item[1].visibility === true)
                    return(
                        <FormGroup row key={key}>
                            <Label htmlFor="title" md={12}>{item[1].title}</Label>
                            <Col md={12}>
                                <Input type="text" className="product-input" id={item[0]} name={item[0]} value={item[1].value} onChange={props.handleMarketPlaceBaseInput(tabSelected)} readOnly={!item[1].editable} />                                    
                            </Col>
                        </FormGroup>
                    )
                else if(item[1].type === 'select' & item[1].visibility === true){
                  var arr = JSON.parse(item[1].options)
                  let options= []
                  for(let i=0; i < arr.length; i++){
                    for(var key1 in arr[i]){
                       options.push(key1)
                    }                          
                  }
                  return(<FormGroup row key={key}>
                            <Label htmlFor="title" md={12}>{item[1].title}</Label>
                            <Select value={item[1].value} name={item[0]} onChange={props.handleSelect(item, tabSelected, options)}>
                                {options.map(function(item1, key1){ 
                                    return (<MenuItem  key={key1} value={item1}>{item1}</MenuItem>)})}
                            </Select>
                        </FormGroup>)
                                
                }  
                else   
                    return null
            })}
        </Form>
    </div>
  );
}

export default ShopifyTemplate;