import http from './httpService'

const apiEndPoint = http.pim_app_url + '/product'
const pyApiEndPoint = http.py_app_url 
// const tempApiEndPoint = http.py_app_url + '/product/getFiltersTemp'

export function getFilters(formData) {
  return http.post(apiEndPoint + '/getFilters', formData)
}

let prevReq = []

export async function getProductsList(formData) {
  if(prevReq.length > 0){
      for(let i=0; i < prevReq.length; i++){
        prevReq[i].cancel("operation canceled.")
      }
      prevReq.length = 0
  }
  let call = http.CancelToken.source()
  prevReq.push(call)
  let res = await http.post(apiEndPoint + '/listProducts', formData, {
    cancelToken: call.token
  })
  return res
}

export function getProductDataPoints(formData) {
  return http.post(pyApiEndPoint + '/item', formData)
}

export function getProductInfo(formData) {
  return http.post(apiEndPoint + '/getProduct', formData)
}

export function saveProduct(formData) {
  return http.post(apiEndPoint + '/saveProduct', formData)
}

export function saveProductPosition(position){
   localStorage.setItem("productPosition", position)
}

export function getProductPosition(){
  return localStorage.getItem("productPosition")
}

export function setProductCount(val){
  localStorage.setItem("totalProducts", val)
}

export function getProductCount(){
  return localStorage.getItem("totalProducts")
}

export function setProductFilters(filters){
  localStorage.setItem("productFilters", JSON.stringify(filters))
}

export function getProductFilters(){
  return JSON.parse(localStorage.getItem("productFilters"))
}

export function setProductSearchKeyword(searchedValue){
  localStorage.setItem("searchedValue", searchedValue)
}

export function getProductSearchKeyword(){
  return localStorage.getItem("searchedValue")
}

export function setProductSorting(productSortKey, productSortType){
  localStorage.setItem("productSortKey", JSON.stringify(productSortKey))
  localStorage.setItem("productSortType", JSON.stringify(productSortType))
}

export function getProductSorting(){
  return {
    sortKey : JSON.parse(localStorage.getItem("productSortKey")),
    sortType : JSON.parse(localStorage.getItem("productSortType"))
  }
}