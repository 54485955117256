import React, { useEffect, useState } from 'react';
import amazon from "../../../../assets/amazon-favicon.png";
import shopify from "../../../../assets/shopify-favicon.png";
import ebay from "../../../../assets/ebay-logo.png";
import walmart from "../../../../assets/walmart-favicon.png";
import myntra from "../../../../assets/myntra-favicon.png";
import { Image } from 'react-bootstrap';

function Links({links}) {
  const [linkArr, setLinkArr] = useState([])

  useEffect(() => {
      if(Object.keys(links).length > 0){
         let arr = []
         for(let value of Object.values(links)){
              arr.push({
                 url : value['url'],
                 src : value['marketplace'] ? value['marketplace'] : null
              })              
          }
          setLinkArr(arr)
      }
  },[links])

  const setImage = (mp) => {
     if(mp=== 'Shopify')
        return <Image className="link-img" src={shopify} img-thumbnail />
     else if(mp=== 'Ebay')
        return <Image className="link-img" src={ebay} img-thumbnail />
     else if(mp=== 'Myntra')
        return <Image className="link-img" src={myntra} img-thumbnail />
     else if(mp=== 'Amazon')
        return <Image className="link-img" src={amazon} img-thumbnail style={{height : '28px'}}/>
     else if(mp=== 'Walmart')
        return <Image className="link-img" src={walmart} img-thumbnail />
     else
        return null
  }

  if(linkArr.length === 0)
      return null

  return(
    <React.Fragment>
      {linkArr.map((item, key) => {
        return (
          <a href={item.url} target="_blank" key={key}>
              {setImage(item.src)}
          </a>   
        );
      })}

    </React.Fragment>
  )
}

export default Links;