import React, { Component } from 'react';
import {Form, FormGroup, Col, Input, Label} from 'reactstrap';
import Modal from 'react-bootstrap/Modal'
import { Button } from 'reactstrap';
import axios from 'axios';
import ShopifyTemplate from './MarketplaceTemplates/ShopifyTemplate';
import WalmartTemplate from './MarketplaceTemplates/WalmartTemplate';

class MarketPlaceBase extends Component {
    constructor(props){
        super(props)
        this.state = {
            tabStyle: {},
            marketPlaceObj : {},
            modalShow : false,
            displayVariantInfo : false,
            selectedVariant: {},
            variantId : '',
            categorySelected: '',
            subCategories: [],
            
        }
        this.components = {
            Shopify : ShopifyTemplate,
            Walmart : WalmartTemplate,
            // Shopify : ShopifyTemplate,
        }
        this.handleSelect = this.handleSelect.bind(this)
        this.handleSubCategory = this.handleSubCategory.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    handleMarketPlaceBaseInput=(marketPlace)=>(e)=>{
        this.props.handleMarketPlaceBaseInput(marketPlace, e.target)

    }
    setModalShow=()=>{
        this.setState({
            modalShow: !this.state.modalShow
        })
    }
    onHide=()=>{
        this.setState({
            modalShow: false,
            displayVariantInfo : false,
        })
    }
    showVariantInfo = (variantSku)=>()=> {
        var variants = [...this.props.variants]
        for(let i=0; i < variants.length; i++){
            if(variantSku === variants[i].attributes.sku.value){
                this.setState({
                    displayVariantInfo : true,
                    selectedVariant : variants[i],
                    variantId : i
                })
            }
        }
        
    }
    onChange = (e)=>{
        this.props.onChange(this.state.variantId, this.props.tabSelected, e.target)
    }
    saveProduct=()=>{
        // let {listVariants} = this.props
        // listVariants.push()
        this.setState({
            modalShow: !this.state.modalShow
        })
    }
    handleSelect=(item, marketPlace, categories)=>(e)=>{
        this.props.handleMarketPlaceBaseInput(marketPlace, e.target)
        var arr = [...categories]
        var selectedCategory = e.target.value
        var subCategories = []
        for(let i=0; i < arr.length; i++){
            for(var key in arr[i]){
                if( key === selectedCategory){
                    subCategories = [...arr[i][key]]
                }
            }
        }
        var uniqueSet = new Set(subCategories)
        var arr2 = [...uniqueSet]
        this.setState({
            subCategories : arr2,
            categorySelected : e.target.value
        })

    }
    handleSubCategory = (item, marketPlace) =>(e) =>{
        this.props.handleMarketPlaceBaseInput(marketPlace, e.target)
        const API = JSON.parse(item[1].config).API
        const labelI = marketPlace.toLowerCase()+'Category'
        const labelII = marketPlace.toLowerCase()+'SubCategory'
        const formData = {
            marketplace : marketPlace,
            [labelI] : this.state.categorySelected,
            [labelII] : e.target.value
        }
        axios({
            method: 'post',
            url: API,
            data : JSON.stringify(formData)
        }).then(res=>{
            // console.log(res.data)
            var objI = {...res.data[marketPlace.toLowerCase()+'BaseCategoryAttributes']}
            var objII = {...res.data[marketPlace.toLowerCase()+'VariantCategoryAttributes']}
            this.props.addCategoryAttributes(objI, objII, marketPlace)

        })
    }
    setMPTemplate(){
        const {tabSelected, marketplaces} = this.props
        const MpTemplate = this.components[tabSelected]
        const attributeArray =  marketplaces[this.props.tabSelected]

        return(
            <MpTemplate 
                attributeArray={attributeArray}
                tabSelected={tabSelected}
                subCategories={this.state.subCategories}
                handleMarketPlaceBaseInput={this.handleMarketPlaceBaseInput}
                handleSubCategory={this.handleSubCategory}
                handleSelect={this.handleSelect}
            />
        )
    }
    render() {
        const {modalShow, displayVariantInfo, selectedVariant} = this.state
        const {marketplaces, listVariants, tabSelected} = this.props
        const attributeArray =  marketplaces[this.props.tabSelected]
        const baseAdd = attributeArray[tabSelected.toLowerCase()+'BaseAdd'].value

        return ( 
            <div className="container-fluid market-place-container">
                    {/* <div className="row p-2">
                        <div className="col-12 col-md-5 offset-md-7 col-lg-3 offset-lg-9 col-xl-2 offset-xl-10">
                            <span className="missing-label">3 attributes missing</span>
                            <span className="missing-dot mr-2 mt-1"></span>
                        </div>
                    </div> */}
                <div className="row pl-3 mt-2">
                    <div className="col-12 col-lg-6 content-left">
                        {this.setMPTemplate()}
                    </div>
                    <div className="col-12 col-lg-2 offset-lg-2 content-right">
                        <div className="mt-3">
                            <h5>List Of Variants</h5> 
                            <p>TOTAL : {listVariants[tabSelected].length}</p>
                            <ol>
                                {listVariants[tabSelected].map((item, key)=> {
                                    if(item.exists === true)
                                        return(<li>{item.variant}</li>)
                                })}
                            </ol>
                            <div>
                                <button onClick={this.setModalShow}>+ Add</button>
                            </div>
                        </div>
                        <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Add Variant
                                </Modal.Title>
                                <Button onClick={this.onHide}>Close</Button>
                            </Modal.Header>
                            <Modal.Body>
                                {displayVariantInfo === false ? <div>
                                    <h6>List of Variants</h6>
                                    <ol>
                                        {listVariants[tabSelected].map((item, key)=> {
                                            if(item.exists === false)
                                                return(
                                                    <li onClick={this.showVariantInfo(item.variant)}>{item.variant}</li>
                                                )
                                        })}
                                    </ol>
                                </div>
                                    : <div className="container-fluid variant-container">
                                        <div className="row p-3">
                                            <Form>
                                                {Object.entries(selectedVariant.marketplaces[tabSelected]).map((item,key)=>{
                                                    if(item[1].visibility === true)
                                                        return(
                                                            <FormGroup row key={key}>
                                                                <Label htmlFor="length" md={12}>{item[1].title}</Label>
                                                                <Col md={12}>
                                                                    <Input type={item[1].visibility === false ? "hidden" : "text"} className="product-input" id={item[0]} name={item[0]} value={item[1].value} onChange={this.onChange}/>
                                                                </Col>
                                                            </FormGroup>
                                                        )
                                                    else
                                                        return null
                                                })}
                                            </Form>
                                        </div>
                                        <div className="row p-2" >
                                            <div className="col-12 col-lg-2 offset-lg-10" style={{textAlign: 'center'}}>
                                                <button onClick={this.saveProduct}>Done</button>
                                            </div>
                                            
                                        </div>
                                    </div> }
                                
                            </Modal.Body>
                        </Modal>   
                    </div>
                </div>
                
            </div>
         );
    }
}
 
export default MarketPlaceBase;