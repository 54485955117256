import React from "react";
import CustomLinks from "./CustomLinks";

const AllLinks = ({ activeClass, linkName }) => {
  return (
    <div className={`navlinks ${activeClass}`}>
      <ul className='list-unstyled components side_nav_links'>
        <CustomLinks 
           linkName={linkName}
           givenLink={'dashboard'}
           image={'dashboard-1.png'}
           title={'Dashboard'}
        />
        <CustomLinks 
           linkName={linkName}
           givenLink={'products'}
           module={'analytics'}
           image={'product.png'}
           title={'Products'}
        />
        <CustomLinks 
           linkName={linkName}
           givenLink={'orders'}
           image={'orders.png'}
           title={'Orders'}
        />
        <CustomLinks 
           linkName={linkName}
           givenLink={'analytics'}
           image={'analytics.png'}
           title={'Analytics'}
        />
        <CustomLinks 
           linkName={linkName}
           givenLink={'settings'}
           image={'settings.png'}
           title={'Settings'}
        />
      </ul>
    </div>
  );
};

export default AllLinks;

