import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ConnectedStores from './ConnectedStores';
import OtherStores from './OtherStores';

function DataSources({connectedStores, companyKey, addStores, loading}) {
  const [isModalOpen, setModal] = useState(false)
  const marketPlacesArr = ['Amazon', 'Shopify', 'Ebay', 'Walmart']
  const [stores, setConnectedStores] = useState([])

  useEffect(()=> {
     if(connectedStores.length > 0){
       setConnectedStores([...connectedStores])
       setModal(false)
     }
  },[connectedStores, loading])
  
  const toggleModal = (e) => {
      if(e)
          e.preventDefault()
      setModal(!isModalOpen)
  }

  return (
    <div>
        <div className='row row1'>
            <div className='col-sm-8'>
              <h1 className='settings-heading-primary col1 pl-3'>
                Data Sources &nbsp; | 
                <span className="ml-2"> {stores.length} sources</span>
              </h1>
            </div>
            <div className='col-sm-4 col2 pr-5'>
              <i className='fa fa-arrow-left pr-2' aria-hidden='true' />
              go to <Link to='/dashboard'>Dashboard</Link>
            </div>
        </div>
        <div className="row connectedStores">
            <ConnectedStores 
                connectedStores={stores}
                companyKey={companyKey}
                loading={loading}
            />
        </div>
        <div className='row settings-connect mb-4'>
            <div className='row row1 pl-4'>More sources to connect</div>
            <div className='row row2'>
              <OtherStores
                  isModalOpen ={isModalOpen} 
                  toggleModal={toggleModal}
                  marketPlacesArr={marketPlacesArr}
                  companyKey={companyKey}
                  addStores={addStores}
              />
            </div>
        </div>
          
    </div>
  );
}

export default DataSources;