import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ items }) => {
  const length = items.length;
  return (
    <nav
      aria-label='breadcrumb'
      className='section-reports__breadcrumbs settings__breadcrumbs '
    >
      <ol className='breadcrumb'>
        {items.map((item, key) => {
          if (key === length - 1) {
            return (
              <li className='breadcrumb-item active' aria-current='page' key={key}>
                {item.label}
              </li>
            );
          } else {
            return (
              <li className='breadcrumb-item' key={key}>
                <Link to={item.link} className='breadcrumb-link'>
                  {item.label}
                </Link>
              </li>
            );
          }
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
