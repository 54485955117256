import React, { Component } from 'react';
import PropTypes from "prop-types";
import VitalInfo from './VitalInfo';
import Others from './Others';

class BaseInfo extends Component {
    constructor(props){
        super(props)
        this.state = {
            baseSku : this.props.vitalInfoObject.baseSku.value,
            commonFieldsArray : ['title', 'brand', 'productType', 'description'],
            otherFieldsArray : ['tags', 'classfication', 'googleProductCategory', 'manufacturer', 'objectType', 'conditionType']
        } 
    }
    handleVitalInputChange=(e)=>{
        if (typeof this.props.handleVitalInputChange === 'function') {
            this.props.handleVitalInputChange(e.target)
        }
    }
    
    render() {
        const {vitalInfoObject, tabType} = this.props
        return ( 
        <div className="container-fluid">
            <div className="row pl-3 mt-3">
                <div className="col-12 pb-3">
                    {tabType === 'Vital Info' ?
                         <VitalInfo 
                            vitalInfoObject={vitalInfoObject}
                            handleVitalInputChange={this.handleVitalInputChange}
                         />
                         :
                         <Others 
                            vitalInfoObject={vitalInfoObject}
                            handleVitalInputChange={this.handleVitalInputChange}
                         />
                        }
                </div>
            </div>  
        </div> );
    }
}
BaseInfo.propTypes = {
    baseInfoObject : PropTypes.object,
    handleVitalInputChange: PropTypes.func,
    handleSave: PropTypes.func
}
export default BaseInfo;