import http from './httpService'

const apiEndPoint = http.pim_app_url + '/report'

let csvData = []

export function getFeeds(formData) {
  return http.post(apiEndPoint+'/feed', formData)
}

export function getReports(formData) {
  return http.post(apiEndPoint+'/getReport', formData)
}

export async function fetchReportData(api) {
  const res = await http.get(api)
  console.log(res)
  // var urllib = require('urllib');
 
  // urllib.request(api).then(function (result) {
  //   // result: {data: buffer, res: response object}
  //   console.log(result);
  // }).catch(function (err) {
  //   console.error(err);
  // });
}

