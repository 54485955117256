import React, { useEffect, useState } from 'react';
import Loading from '../../common/Loading';
import Accordion from './Accordion/Accordion';

function RenderAttr({pimFields, dragStart, importType : importOptions, selectedMp}) {  
  const [fields, setFields] = useState({})

  useEffect(()=> {
      if(Object.keys(pimFields).length > 0){
        let tempObj = setObj()
        setFields({...tempObj})
      }
  }, [pimFields])

  const setObj = () => {
    if(importOptions.length > 0){
        let temp = {}
        for(let i=0; i < importOptions.length; i++){
            temp[importOptions[i].value] = pimFields[importOptions[i].value] 
        }
        return temp
    }
    else
      return pimFields
  }

  if(!Object.keys(fields).length === 0)
      return(<Loading message={''}/>)
  return (
    <div className="map-col">
        {Object.entries(fields).map((category, key)=> {
            
            if(category[0] != 'Marketplaces')
                return( 
                  <Accordion 
                    title={category[0]}
                    fields={category[1]}
                    dragStart={dragStart}
                    key={key}
                    type={'fields'}
                    selectedMp={selectedMp}
                  />
                )
        })}
    </div>
  );
}

export default RenderAttr;