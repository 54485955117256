import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function LastImport({response, companyKey}) {
    const [currPercent, setCurrPercent] = useState(0);
    const [table, setTable] = useState('');

    const setTableUi = (res) => {
        let table = []
        let count = 0
        for (let [key, value] of Object.entries(res)) {
            let children = [];
            children.push(<td key={count + '_' + 1}>{` ${count + 1}`}</td>);
            children.push(<td key={count + '_' + 2}>{` ${key}`}</td>);
            if (value.result === 'success')
                children.push(<td key={count + '_' + 3}><Image src={require('../../assets/green-tick.png')} thumbnail />{` ${value.result}`}</td>);
            else
                children.push(<td key={count + '_' + 3}><Image src={require('../../assets/warning.png')} thumbnail />{` ${value.result}`}</td>);
            children.push(<td key={count + '_' + 4}>{` ${value.message}`}</td>);
            table.push(<tr key={count}>{children}</tr>)
            count++
        }
        setTable(table);
    }

    useEffect(() => {
        // const interval = setInterval(sendAxiosReq(), 5000);
        // return () => {
        //     clearInterval(interval);
        // };
        setTableUi(response.data)
        console.log(response)
    }, [response]);

    return (
        <div className="container-fluid" align="center">
            <Link className="back" to='/mappingPortal'>Upload Another File</Link>
            <div className="row top-div">
                <div className="col-12 col-md-6">
                    {localStorage.getItem('csvName')}
                    <ProgressBar now={100} className="bar" />
                    <span>{`100%`} Completed</span>
                </div>
                <div className="col-12 col-md-6 row top-div_right">
                    <div className="col-md-4 pt-1">
                        <h6>Format</h6>
                        <Image src={require('../../assets/green-tick.png')} thumbnail />
                    </div>
                    <div className="col-md-4 pt-1">
                        <h6>Validation</h6>
                        <Image src={require('../../assets/green-tick.png')} thumbnail />
                    </div>
                    <div className="col-md-4 pt-1">
                        <h6>Configuration</h6>
                        <Image src={require('../../assets/green-tick.png')} thumbnail />
                    </div>
                </div>
            </div>
            <div className="bottom-div">
                <table>
                    <thead>
                        <tr>
                            <th key="row">Row</th>
                            <th key="keyColumn">Key Column</th>
                            <th key="result">Status</th>
                            <th key="message">Message</th>
                        </tr>
                    </thead>
                    <tbody>{table}</tbody>
                </table>
            </div>
        </div>
    );
}

export default LastImport;