import React, { useEffect } from 'react';
import CsvPreview from '../CsvPreview';
import MapColumns from '../Map-Columns/MapColumns';
import ResolverSetting from '../ResolverSetting';
import LastImport from '../LastImport';
import UploadFile from '../UploadFile';

function RenderedComponent({category, templateData, companyKey, completeTemplateData, setData, importOptions, finalData, notify, response, importType, selectedMp, next}) {

    useEffect(() => {
    }, [templateData])

    if(category === 'Upload')
        return (
            <UploadFile 
                templateData={templateData} 
                importType={importType} 
                next={next} 
            />
        )

    else if (category === 'Preview CSV') {
        return (
            <CsvPreview key={companyKey} />
        )
    } 
    else if (category === 'Configure Map Setting') {
        return (
            <ResolverSetting 
                data={{pimFields: completeTemplateData, 
                companyKey: companyKey
                 }}
                 setData={setData}
                 importType={importType}
           />)
    } 
    else if (category === 'Finish') {
        return (
            <LastImport key={companyKey} response={response} />
        )
    }
    else if (category === 'Map Columns') {
        return (
            <MapColumns 
                category={category}
                pimFields={completeTemplateData}
                setData={setData}
                finalData={finalData}
                notify={notify}
                selectedMp={selectedMp}
                importType={importType}
            />);
        }
    }

export default RenderedComponent;