import React from 'react';
import ScoreIcon from "../../ScoreIcon";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { setNumbers } from '../../../../common-functions/setNumbers';
import LoadingContent from '../../../common/LoadingContent';
import CircProgressBar from './Circ-ProgressBar';
import CheckBox from '../../../UI Elements/CheckBox';
import Inventory from '../Tooltips/Inventory';
import Title from '../Tooltips/Title';
import { saveProductPosition } from '../../../../services/productService';

function ProductCardTemplate({product, enlargeImage, loadingDataPoints, onProductSelect, id, currencySymbol}) {

  const renderBrokenValue = (value) => {
    if(value !== null || value !== undefined){
      if(value === 100){
        return (
          <div
              className='p-1 mr-2 alert divs'
              style={{ textAlign: "center" }}>
              <h6 className='mt-1'>
                Out of Stock
              </h6>
          </div>
        )
      }
      else if(value === 0)
         return (
           <div
               className='p-1 mr-2 green divs'
               style={{ textAlign: "center" }}>
               <h6 className='mt-1'>
                 All In Stock
               </h6>
           </div>
         )
      else{
         let v = setNumbers(value)
         return (
         <div
             className='p-1 mr-2 divs'
             style={{ textAlign: "center" }}>
             <h6 className='mt-1'>
               <CircProgressBar perc={v}/>
             </h6>
         </div>)
      }
    }
    return 'N/A'
  }

  const renderSalesData = (sales, salesChange) => {
      return(
      <React.Fragment>
          {currencySymbol}
          {sales ? setNumbers(sales) : null}
          <span className={`pl-2 ${salesChange > 0 ? 'green' : 'red'}`} >
              {salesChange !== 'NA'? 
                salesChange > 0 ?
                '+'+ setNumbers(salesChange) +'%'
                : setNumbers(salesChange) +'%'
              : '' }
          </span>
      </React.Fragment>
      )
  }

  const renderDataPoints = (labels, values) => {
      return(
        <div className='d-flex flexbox1 justify-content-between'>
            {renderBox(labels[0], product[values[0]], false, 1)}
            {renderBox(labels[1], setNumbers(product[values[1]]), true,2)}
        &nbsp;
      </div>
      )
  }

  const renderBox = (label, value, currency, key) => {   
    return(
        <div className={`p-1 ${key ===1 ? 'mr-2':''} divs ${loadingDataPoints ? 'loading' : ''}`}>
            {!loadingDataPoints && 
            <React.Fragment>
                {label}
                <h6 className='mt-1'>
                  {currency && currencySymbol}
                  {value}
                </h6>
            </React.Fragment> }
        </div>
    )
  }

  return (
    <div className='col-12 col-md-6 col-lg-4 mb-5' key={id}>
          <div className={`product-card-div`}>
            <div className={`product-div ${product.checked ? 'active ' : ''}`}>
                <CheckBox 
                    onCheckboxClick={onProductSelect}
                    value={product.baseSku}
                    checked={product.checked}
                />
                {!loadingDataPoints &&
                  <ScoreIcon
                    category={product["Product Category"]}
                    scoreDetails={product['Score']}
                    zoom = {'zoom_out'}
                    _id={product.baseKey}
                />}
              
                <Card className='product-card'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-4 col-md-4 col-lg-3 image-div' 
                        onMouseOver={() =>enlargeImage && enlargeImage(product.image_url)} 
                        onMouseLeave={() => enlargeImage && enlargeImage('')}
                        >
                          <Link
                            className='product-a'
                            to={`/products/analytics/${product.baseKey}`}
                            params={{ type: product.baseKey }}
                            onClick={() =>saveProductPosition(product.position)}
                          >
                              <Image
                                src={product.image_url}
                                alt='Card image cap'
                                className='products-img'
                              />
                          </Link>
                      </div>
                      <div className='col-8 col-md-8 col-lg-9'>
                        <div className='product-card_title'>
                          <Link
                              className='product-a'
                              to={`/products/analytics/${product.baseKey}`}
                              params={{ type: product.baseKey }}
                              onClick={() =>saveProductPosition(product.position)}
                            >
                              {product["baseSku"]}
                          </Link>
                        </div>
                        <div 
                            className="product-card_title"
                            data-for="custom-color-no-arrow"
                            data-tip={product['title']}
                            data-iscapture="true"
                            >
                            {product['title']}
                        </div>
                        <div className={`product-card_title ${loadingDataPoints ? 'loading' : ''}`}>
                           {loadingDataPoints ?
                            <LoadingContent width={'80%'} height={'13px'}/>
                            :
                            renderSalesData(product["Total Sales"], product['Sales Change'])
                           }                            
                        </div>
                        <div className={`product-card_title`}> 
                          {loadingDataPoints ?
                            <LoadingContent width={'60%'} marginTop={'10px'} height={'13px'}/>
                            :
                            <span>Net Sales</span>
                           }
                        </div>
                      </div>
                    </div>
                      <div className='row data-points mt-2'>
                        <div className='col-8 d-flex flex-column data-points_1'>
                          {renderDataPoints(['ROS', 'ASP'], ["Rate of Sale", "Average Sale Price"])}
                         
                          <div className='d-flex flexbox2 justify-content-between'>
                              {loadingDataPoints ?
                                  <LoadingContent width={'43%'} marginTop={'20px'} height={'22px'}/>
                                  :
                                  renderBrokenValue(product["Broken %"])
                              }
                              {loadingDataPoints ?
                                <LoadingContent width={'43%'} marginTop={'20px'} height={'22px'}/>
                                :
                                <div
                                  className='p-1 divs'
                                  style={{ textAlign: "center" }}
                                  data-tip 
							                    data-for={'inventory_'+product.baseKey}>
                                    INV
                                    <h6 className='mt-1'>
                                      {product["Current Quantity"]}
                                    </h6>
                                </div>
                              }
                              {" "}
                              &nbsp;
                          </div>
                        </div>
                        <div className='col-4 d-flex flex-column data-points_2'>
                          {loadingDataPoints ?
                              <LoadingContent width={'75%'} marginTop={'40px'} height={'57px'}/>
                              :
                              <div className='pt-4 divs'>
                                  <h5>{product["Total Orders"]}</h5>
                                  <h6>Orders</h6>
                              </div>
                          }
                        </div>
                      </div>

                  </div>
                </Card>

              <Title />

              {/* <Inventory 
                  stockCount={product['Stock Count']}
                  _id={product.baseKey}
              />              */}
            </div>
          </div>
        </div>
      
  );
}

export default ProductCardTemplate;