import React, { Component } from "react"
import { Link } from "react-router-dom"
import SettingsNav from "./SettingsNav"
import SettingsHeader from "./SettingsHeader"
import { connectionStatus, processStatus } from "../../services/registrationService"
import moment from "moment"
import DataSources from "./Data sources/DataSources"
import Loading from "../common/Loading"

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyKey: props.companyKey,
      storeName: "",
      marketplaces: [],
      loading : true,
      connectedStores: [],
    };
  }

  componentDidMount = async () => {
    try {
      const formData = { companyKey: this.state.companyKey }
      const resp = await connectionStatus(formData)
      // console.log(resp, formData)
      let storeNames = [...Object.keys(resp.data.connections)]
      let { connectedStores } = this.state

      Object.values(resp.data.connections).map((store, key) => {
        let lastSync = ""
        if (!store.lastSynced) lastSync = "N/A"
        else
          lastSync = moment(store.lastSynced).format(
            "Do MMM YYYY,     hh:mm a[(ISO)]"
          );
        let object = {
          marketPlace: store.marketplace,
          storeName: storeNames[key],
          uploadStatus: 100, //100%
          uploadLabel: "Connected",
          lastSync,
        };
        connectedStores.push(object)
      })
      this.setState({ connectedStores, loading : false })
    } catch (ex) {
      console.log(ex)
    }
  }

  checkProcessStatus = async (processId, marketPlace, storeName) => {
    try {
      const resp = await processStatus(processId)
      console.log(resp)
      let arr = [...this.state.connectedStores]

      if (resp.data.result === "success") {        
        arr.map((store) => {
            if (store.marketPlace === marketPlace && store.storeName === storeName) {
                store.uploadStatus = 100;
                store.uploadLabel = "Connected";
            }
        })
        this.setState({connectedStores: arr});
      } else {
        setTimeout(
          function () {
              arr.map((store) => {
                if(store.marketPlace === marketPlace && store.storeName === storeName)
                  store.uploadStatus = resp.data.data.percent
              })
              this.setState({connectedStores: arr});
              this.checkProcessStatus(processId, marketPlace, storeName)
          }, 1500)
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  addStores = (obj, processId) => { 
     let arr = [...this.state.connectedStores]
     arr.unshift(obj)
     this.setState({connectedStores: arr})
     this.checkProcessStatus(processId, obj.marketPlace, obj.storeName)
  }
  render() {
    const {connectedStores, companyKey, loading} = this.state

    return (
      <div className='settings-container'>
        <SettingsNav />
        <main className='settings-view'>
          <SettingsHeader />
          <div className='settings-main'>
            <nav
              aria-label='breadcrumb'
              className='section-reports__breadcrumbs settings__breadcrumbs '
            >
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/settings' className='breadcrumb-link'>
                    Settings
                  </Link>
                </li>

                <li className='breadcrumb-item active' aria-current='page'>
                  Data Sources
                </li>
              </ol>
            </nav>
            <DataSources 
                connectedStores = {connectedStores}
                className={this.props.className}
                companyKey={companyKey}
                addStores={this.addStores}
                loading={loading}
            />
          </div>
        </main>
      </div>
    );
  }
}

export default Settings;
