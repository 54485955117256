import React, { useEffect } from 'react';
import { Button } from 'reactstrap';

function Buttons({category, importFile, next}) {
  const btnArr = [
    {
      label : 'Discard',
      fn : () => window.location= '/mappingPortal',
      categories : ['Preview CSV', 'Configure Map Setting', 'Map Columns']
    },
    {
      label : 'Next',
      fn : next,
      categories : ['Preview CSV', 'Configure Map Setting',]
    },
    {
      label : 'Import',
      fn : importFile,
      categories : [ 'Map Columns']
    }
  ]
  useEffect(()=> {
  }, [category])
  
  return (
    <div className="map-btns d-flex justify-content-end">
        {btnArr.map((btn, key) => {
            return(
               <div className="mp-btn" key={key}>
                    <Button className={`${btn.label} ${btn.categories.includes(category) ? 'active': 'disabled'}`}
                    onClick={btn.fn}>{btn.label}</Button>
               </div>
            )
        })}
    </div>
  );
}

export default Buttons;