import React, { Component } from 'react';
import Image from 'react-bootstrap/Image';

class ImageContainer extends Component {
    constructor(props){
        super(props)
        this.state = {
            
        }
    }
    render() { 
        const {images} = this.props
        if(images === null){
            return(
                <div className="container-fluid image-container">
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-9 image-div">
                            <Image className="product-enlarged-image" src={require('../../../../assets/no-image.png')}/>
                        </div>
                    </div>
                </div>
            )
        }
        else if(images.length === 1){
            return(
                <div className="container-fluid image-container">
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-9 image-div">
                            <Image className="product-enlarged-image" src={images[0]}/>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return ( 
                <div className="container-fluid image-container">
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-9 image-div">
                            <img className="product-enlarged-image" src={images[0]}/>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 image-div-2">
                            <div className="row pb-2 image-stacks">
                                {images[1] !== undefined ?<img className="product-enlarged-image" src={images[1]}/> : null}
                            </div>
                            <div className="row pb-2 pt-2 image-stacks">
                                {images[2] !== undefined ?<img className="product-enlarged-image" src={images[2]}/> : null}
                            </div>
                            <div className="row pt-2 image-stacks">
                                {images[3] !== undefined ?<img className="product-enlarged-image" src={images[3]}/> : null}
                            </div>
                        </div>
                        
                    </div>
                </div>
         );
        }
    }
}
 
export default ImageContainer;