import React, { useState, useEffect } from 'react';
import { getLayout, startImporting, getCsvData, removeAllData } from '../../services/mappingPortalService';
import Loading from '../common/Loading'
import NavigateComponent from './common/NavigateComponent';

function EntryPage({companyKey, notify, importOptions, selectedImport, selectedMp}) {
  const categoryArr = ['Upload', 'Preview CSV', 'Configure Map Setting', 'Map Columns', 'Finish']

  const [currCategory, setCurrCategory] = useState('Upload')
  const [templateData, setTemplateData] = useState(null)
  const [finalData, setFinalData] = useState({})
  const [completeTemplateData, setCompleteTemplateData] = useState({})
  const [response, setResponse] = useState({})

  const getLayoutFn = async () => {
    const formData = {
        'companyKey' : companyKey
    }
    return await getLayout(formData)
  }

  useEffect(() => {        
      getLayoutFn().then(res=>{
          console.log(res)
          setCompleteTemplateData(res)
          let map = new Map()
          for(let [key, value] of Object.entries(res)){
              if(key !== 'Marketplaces')
                  map.set(key,[Object.values(value['fields'])])
          }
          setTemplateData(map)
      })
  }, [])

  const handleNext =()=> {
      if(currCategory === 'Resolver Setting'){
          if(Object.keys(finalData).length === 0)
              notify('error', "One of the selection is missing")
          else
              setCurrCategory(categoryArr[categoryArr.indexOf(currCategory)+1])
      }
      else{
          setCurrCategory(categoryArr[categoryArr.indexOf(currCategory)+1])
      }
  }

  const handleImport = async ()=> {   
      console.log(JSON.stringify(finalData))
      const response = await startImporting(finalData)
      console.log(response)
      if(response.result === 'success'){
          setResponse(response)
          setCurrCategory('Finish')
          removeAllData()
      }
  }

  const setData = (data) => {
      setFinalData(data)
  }

  if(Object.keys(completeTemplateData).length === 0)
    return(<Loading />)
  
  return (
    <div className="container-fluid entry-point">
        <NavigateComponent 
            categories={categoryArr}
            selectedCategory={currCategory}
            importFile ={handleImport}
            next ={handleNext}
            category={currCategory}
            templateData={templateData}
            companyKey={companyKey} 
            completeTemplateData={completeTemplateData} 
            setData={setData}
            finalData={finalData}
            notify={notify}
            response={response}
            selectedImport={selectedImport}
            selectedMp={selectedMp}
        />        
    </div>
  );
}

export default EntryPage;