import React, {useState} from 'react';
import VariantDetails from '../Variants/VariantDetails';
import MarketPlaceBase from '../MarketPlaces/MarketPlaceBase';
import ProductImages from '../Images/Images';
import Loading from '../../../common/Loading';
import CustomTabs from './CustomTabs';
import BaseInfo from '../Base/BaseInfo';

function InfoTabs(props) {
  const { category, vitalInfoObject, marketplaces, selectedVariant, selectedMarketPlaces,
    variantId, variants, listVariants, images, tabArray} = props

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setComponent = (item) => {
      if(category === 'base'){
          return(<BaseInfo 
                    vitalInfoObject={vitalInfoObject}
                    handleVitalInputChange={props.handleVitalInputChange}
                    selectedMarketPlaces={selectedMarketPlaces}
                    tabType={item}
                />)
      }
      else if(category === 'variant'){
          return(<VariantDetails 
                    selectedVariant={selectedVariant} 
                    variantId={variantId} 
                    handleVariantInputChange={props.handleVariantInputChange}
                    tabType={item}
                    handleInput={props.handleMarketPlaceVariantInput}
                    onChange={props.handleMarketPlaceVariantInput}
                  />)
      }
      else if(category === 'marketplace'){
          return(<MarketPlaceBase 
                    tabSelected={item} 
                    marketplaces={marketplaces} 
                    handleMarketPlaceBaseInput={props.handleMarketPlaceBaseInput}
                    listVariants={listVariants} 
                    variants={variants} 
                    onChange={props.handleMarketPlaceVariantInput}
                    addCategoryAttributes={props.addCategoryAttributes}
                  />)
      }
      else if(category === 'images'){
          return(<ProductImages baseSku={vitalInfoObject.baseSku.value} images={images}/>)
      }
      else
        return null
  }
  return (
        <div className="row pl-3 pr-5">
            {tabArray.length === 0 ? 
                <Loading />
                : 
                <div className="col-12 settings-tab">
                      <CustomTabs 
                          value={value}
                          setComponent={setComponent}
                          tabArray={tabArray}
                          handleChange={handleChange}
                      />   
                </div>
            }
        </div>
  );
}

export default InfoTabs;