import React from "react";

const ExistingUser = ({ users }) => {
  return (
    <React.Fragment>
      {users.map((user, key) => {
        let { Email, Role } = user;
        if (!Email) Email = "N/A";
        if (!Role) Role = "N/A";
        return (
          <div key={key} className='row user'>
            <span className='user-pic'></span>
            <div className='col pt-4 pl-4'>{Email}</div>
            <div className='col pt-4 pl-4'>{Role}</div>
            <div className='col pt-4 pl-4'>
              Permissions{" "}
              <i
                className='fa fa-arrow-down p-2 clickable'
                aria-hidden='true'
                style={{ color: "#4b3b94" }}
              ></i>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default ExistingUser;
