import http from './httpService'

const apiEndPoint = http.pim_app_url + '/order'
let prevReq = []

export function getFilters(formData) {
  return http.post(apiEndPoint + '/getFilters', formData)
}

export async function getOrdersList(formData) {
  if(prevReq.length > 0){
    for(let i=0; i < prevReq.length; i++){
       prevReq[i].cancel("operation canceled.")
    }
    prevReq.length = 0
  }
  let call = http.CancelToken.source()
  prevReq.push(call)
  let res = await http.post(apiEndPoint + '/listing', formData)
  return res
}

export function getOrderInfo(formData) {
  return http.post(apiEndPoint + '/orderDetails', formData)
}

export function getAllOrders(formData) {
  return http.post(apiEndPoint + '/orderExport', formData)
}