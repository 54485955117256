import React from 'react';

 function Loading(props){
   return(
      <React.Fragment>
          <div className="loadingio-spinner-pulse-kl4yx9ocx7b" 
            style={{
              left: !props.left ? null: props.left,
              top: !props.top ? null: props.top
            }}
          >
            <div className="ldio-u7vcz2trq9m">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
          </div>
          <p className="loadingMsg" style={{left : props.msgPaddingLeft}}>
            { !props.message ? null : props.message}</p>          
      </React.Fragment>
      
   )
}

export default Loading