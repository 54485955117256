import React from 'react';
import { Image } from 'react-bootstrap';
import watch from "../../../assets/watch.png";
import NotificationClass from './NotificationClass';
import { Progress } from 'reactstrap';
import { getUser } from '../../../services/authService';
import { getAllNotifications } from '../../../services/notificationService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import Header from '../Header';

class NotificationContainer extends NotificationClass {
  state = {
    data : {notifications : [], loading : true},
    errors : {},
  }

  componentDidMount(){
    this.getNotifications()
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.companyKey !== this.props.companyKey){
       this.getNotifications()
    }
  }

  getNotifications = async () => {
    const formData = {
        "companyKey" : this.props.companyKey,
        "email": getUser().userEmail,
        "limit" : 10
    }
    try{
      const data= await getAllNotifications(formData)
      // console.log(data)
      this.setState(prevState=>({
        data : {
           ...prevState.data,
           notifications : [...data],
           loading : false,
        }
      }))
    }
    catch(ex){
       console.log(ex)
    }
  }
  
  renderListItem = (notification, key) => {
    return(
        <div className={`row d-flex notification-list-item`} key={key}>
                <div className="col-1 icon">
                    <img src={this.setImage(notification.processType)} alt='notify-type'/>
                </div>
                <div className="date-time">
                  <Image src={watch} />
                  {this.setDate(notification.startTime)}
                </div>
                <div className="col-9 content">
                    
                    <h5>{notification.processType ? notification.processType : 'CSV File'}</h5>
                    <p>
                        <div>
                            {notification.message}
                        </div>
                        <Progress value={notification.percent} />
                        {notification.percent === '100' ? null :<span className="perc">{notification.percent}%</span>}
                        
                    </p>
                </div>
              <div className="col-1 btn-group-div">
                <a href={notification.url} className="btn-group download">Download</a>
                {/* <button className="btn-group view" onClick={()=>viewReport(notification)}>View</button> */}
              </div>
              <FontAwesomeIcon
                    className={`close-icon`}
                    icon={faTimes}
                    onClick={() =>this.deleteItem(notification)}
                />
        </div>
    )
  }

  render() {
    const {loading, notifications } = this.state.data
    return (
      <div className="container-fluid notification-container">
          <Header 
              title='Notifications'
              secondaryText={''}
          />
          <div className="row notification_body">
              {!loading && notifications.length === 0 ?
                <h6 className="zero-notification">You have no notifications</h6>
                :
                <React.Fragment>
                  <h4>{notifications.length} Notifications</h4>
                  {loading && this.renderLoadingList()}
                  {notifications.map((notification, key)=> {
                    if(key < 10)
                        return this.renderListItem(notification, key)
                  })}
                </React.Fragment>
              }
          </div>
      </div>
    );
  }
}

export default NotificationContainer;