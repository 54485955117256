import React, { Component } from "react";
import Reports from "./Reports/Reports";
import Header from "../common/Header";
import Loading from "../common/Loading";

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyKey: this.props.companyKey,
      fromSecs : '',
      toSecs : '',
      loading: true
    }
  }

  componentDidMount() {
    let timeZone = "";
    let companyArray = JSON.parse(localStorage.getItem("companyArray"));
    companyArray = Object.values(companyArray);

    companyArray.map((company) => {
      if (company["Company Name"] === this.state.companyKey)
        timeZone = company["Time Zone"];
    })

    const {s1, s2} = this.setDate()
    this.setState({ 
        timeZone: timeZone,
        fromSecs : parseInt(s1),
        toSecs : parseInt(s2),
        loading : false
     })
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.fromDate != this.props.fromDate || prevProps.toDate != this.props.toDate){
      const {s1, s2} = this.setDate()
      this.setState({ 
          fromSecs : parseInt(s1),
          toSecs : parseInt(s2),
          loading : false
       })
    }
    if(prevProps.companyKey !== this.props.companyKey){
      let timeZone = "";
      let companyArray = JSON.parse(localStorage.getItem("companyArray"));
      companyArray = Object.values(companyArray);
  
      companyArray.map((company) => {
        if (company["Company Name"] === this.props.companyKey)
          timeZone = company["Time Zone"];
      })
  
      const {s1, s2} = this.setDate()
      this.setState({ 
          timeZone: timeZone,
          fromSecs : parseInt(s1),
          toSecs : parseInt(s2),
          loading : false
       })
    }
  }
  setDate= ()=> {
    const {fromDate, toDate} = this.props
    let s1 = 0
    let s2 = 0
    if(fromDate.length === 10){
      s1 = new Date(fromDate[6]+fromDate[7]+fromDate[8]+fromDate[9], fromDate[3]+fromDate[4], fromDate[0]+fromDate[1]).getTime()/1000  
    }
    else{
      s1 = new Date(fromDate[5]+fromDate[6]+fromDate[7]+fromDate[8], fromDate[2]+fromDate[2], fromDate[0]).getTime()/1000 
    }
    if(toDate.length === 10){
      s2 = new Date(toDate[6]+toDate[7]+toDate[8]+toDate[9], toDate[3]+toDate[4], toDate[0]+toDate[1]).getTime()/1000     
    }
    else{
      s2 = new Date(toDate[5]+toDate[6]+toDate[7]+toDate[8], toDate[2]+toDate[3], toDate[0]).getTime()/1000
    }
    return {s1, s2}
  }
  render() {
    const {fromSecs, toSecs, timeZone, loading} = this.state
    if(loading === true)
        return(<Loading />)

    return (
      <div className='container-fluid analytics'>
          <div className='container-fluid analytics-header'>
              <Header
                title='Analytics'
                secondaryText={""}
              />
          </div>
          <Reports 
              companyKey={this.props.companyKey}
              fromDate={fromSecs}
              toDate={toSecs}
              timeZone={timeZone}
          />
      </div>
    );
  }
}

export default Analytics;
