import React, { Component } from 'react'
import { Input, FormGroup, Label, Col } from "reactstrap"
import Select from '../../../UI Elements/Select'

class ModalClass extends Component {
  constructor(props){
      super(props)
      this.state = {
        data : {},
        errors : '',
        checkboxes : {
        }
      }
  }

  handleInputChange = (e) => {
    const {name, value} = e.target
    const data = { ...this.state.data }
    data[name].value = value
    this.setState({ data})
  }

  handleCheckbox = (e, mp) => {
      const {name, checked} = e.target
      const checkboxes = { ...this.state.checkboxes }
      checkboxes[name].checked = checked
      this.setState({ checkboxes })
  }

  validate = () => {
     const { data } = this.state
     let flag = true
     for(let item of Object.values(data)){
          if(!item){
            flag = false
            break 
          }
     }
     return flag
  }

  getFields = (fields, id) => {
      let arr = []
      for(let [key, value] of Object.entries(fields)){
          if(key.toLowerCase().includes(id))
              arr.push(value)
      }
      return arr
  }

  renderCheckbox = (label, fields, id, mp, num) =>{
    const {checkboxes} = this.state

    let arr = [...this.getFields(fields, id)]

    return(
       <FormGroup row className='mt-3'>
           <Label htmlFor={id} sm={5}>
               {label}
           </Label>
           <Col sm={12}>
             <div className='row'>
               {arr.map((item, key) => {
                   return(
                       <div className='col-md-3' key={key}>
                         <label className='custom-checkbox'>
                           {" "}
                           {id === 'sync' ? item.Label.replace('Sync','') : item.Label.replace('Update','')}
                           <input
                             type='checkbox'
                             name={item.name}
                             onChange={e => this.handleCheckbox(e, mp)}
                             defaultChecked={checkboxes[item.name.checked]}
                           />
                           <span className='checkmark'/>
                         </label>
                       </div>
                   )
               })}
             </div>
           </Col>
        
     </FormGroup>
    )
  
  }

  renderSelect(name, label, options, key) {
    const { data } = this.state;

    return(
      <FormGroup row className='mt-4 integration-form-field' key={key}>
          <Label htmlFor={'select'} md={5}>
              <span className="imp">*</span>{label}
          </Label>
          <Col md={7}>
              <Select
                name={name}
                value={data[name.value]}
                label={''}
                options={options}
                onChange={this.handleInputChange}
              />
          </Col>
      </FormGroup>
    )
  }

  renderButton = (btnClass, fn, label) => {
    if(fn)
      return (
        <button className={btnClass} onClick={fn}>
          {label}
        </button>
      );
    return(
      <button className={btnClass}>
        {label}
      </button>
    )
  }

  renderInput = (field, key) => {
    const {data} = this.state
     return (
      <FormGroup row className='mt-4 integration-form-field' key={key}>
        <Label htmlFor={field.name} md={5}>
           {field.Mandatory ? <span className="imp">*</span> : ""}{field.Label}
        </Label>
        <Col md={7}>
          <Input
            className='mt-2 register-input'
            type={field.name.toLowerCase().includes('password') || field.name.toLowerCase().includes('key') ? 'password':'text'}
            id={field.name}
            name={field.name}
            value={data[field.name].value}
            onChange={this.handleInputChange}
            required={field.Mandatory}
          />
        </Col>
      </FormGroup>
     )
  }
  
  handleSubmit = (e) => {
    e.preventDefault()
     if(this.validate())
         this.doSubmit()
    //  else{
    //     this.setState({
    //        errors : 'Invalid Credentials'
    //     })
    //  }     
  }
}

export default ModalClass;