import React, { Component } from 'react';
import {Form, FormGroup, Col, Input, Label} from 'reactstrap';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import PropTypes from "prop-types";
import Image from 'react-bootstrap/Image';

const imageMaxSize = 1000000000 //bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'

class VariantDetails extends Component {
    constructor(props){
        super(props)
        this.state = {
            image : null,
            img : null,
            isDragActive: false,
            baseSku : this.props.baseSku,
            openModal : false,
            dataSet : {}
        }
    }
    handleVariantInputChange=(id)=>(e)=>{
        const {tabType} = this.props
        if(tabType === 'Core Info'){
            this.props.handleVariantInputChange(e.target,id)
        }
        else  {
            this.props.onChange(id, tabType, e.target)
        }
    }
    openModal(){

    }
    goBack = () =>{
        this.props.goBack()
    }
    componentDidMount(){
        const { selectedVariant, tabType } = this.props
        if(tabType === 'Core Info'){
            this.setState({
                dataSet : selectedVariant.attributes
            })
        }
        else {
            this.setState({
                dataSet : selectedVariant.marketplaces[tabType]
            })
        }
    }
    render() {
        const { selectedVariant, tabType, variantId } = this.props
        const { dataSet } = this.state
        console.log(selectedVariant)
        return ( <div className="container-fluid pl-2" >                    
                    <div className="row pt-3">
                        <div className="col-12 col-md-6 col-lg-6">
                            {Object.entries(dataSet).length === 0
                                ? <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                : <Form>
                                    {Object.entries(dataSet).map((item,key)=>{
                                        if(item[1].type === 'textarea' & item[1].visibility === true){
                                            return(
                                                <FormGroup row>
                                                    <Label htmlFor="length" md={6}>{item[1].title}</Label>
                                                    <Col md={12}>
                                                        <Input type="text" className="product-input" id={item[0]} name={item[0]} value={item[1].value} 
                                                        onChange={this.handleVariantInputChange(variantId)} readOnly={!item[1].editable} visibility={item[1].visibility}/>
                                                    </Col>
                                                </FormGroup>
                                            )
                                        }
                                        else{
                                            return null
                                        }
                                    })}
                                </Form>}
                            
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 offset-lg-2 p-3">
                            <div className="variant-image-div">
                                {selectedVariant.attributes.varImage.value !== null ? 
                                <Image src={selectedVariant.attributes.varImage.value} /> :
                                <Dropzone onDrop={this.handleOnDrop} accept={acceptedFileTypes} maxSize={imageMaxSize} >
                                        {({getRootProps, getInputProps, isDragActive}) => {
                                            return (
                                                <div
                                                    {...getRootProps()}
                                                    className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                                                    >
                                                    <input {...getInputProps()} name="file" />
                                                    {
                                                        isDragActive ?
                                                            '':
                                                        <React.Fragment>
                                                            <button className="dropzone-btn">Link Images</button>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            )
                                        }}
                                </Dropzone>}
                                {/* <div className="dropzone" onClick={this.openModal}>
                                    <p>Add Image</p>
                                </div> */}
                            </div>
                            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                    Modal heading
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4>Centered Modal</h4>
                                    <p>
                                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                                        consectetur ac, vestibulum at eros.
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={props.onHide}>Close</Button>
                                </Modal.Footer>
                           </Modal>    */}
                        </div>
                    </div>
                </div> );
    }
}

VariantDetails.propTypes = {
    variants : PropTypes.object,
    handleVariantInputChange: PropTypes.func,
    handleSave: PropTypes.func
}
export default VariantDetails;