import React, { useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";

function MiniGraphs({labels, data, color, id}) {
  const [backgroundColor, setColor] = useState([])

  useEffect(()=> {
     let arr = []
      for(let i=0; i < data.length; i++){
         arr.push(color)
      }
      setColor([...arr])
  },[data])

  return (
        <div className="mini-chart-div" id={id}>
            <Bar data={{
                    labels: [...labels],
                    datasets: [{
                        barPercentage: 5.0,
                        barThickness: 1,
                        maxBarThickness: 1,
                        minBarLength:3,
                        data: [...data],
                        backgroundColor: [...backgroundColor],
                    }]        
                    }}
                    width={90}
                    height={50}
                    options={
                        { 
                            maintainAspectRatio: false,
                            legend : {
                                display: false,
                            },
                            tooltip : {
                                display : false
                            },
                            scales: {
                                xAxes: [{
                                    display : false,
                                    gridLines: {
                                        display : false,
                                    }
                                }],
                                yAxes: [{
                                    display : false,
                                    gridLines: {
                                        display : false,
                                    }
                                }]
                            }
                        }
                    }
                />
    </div>
  );
}

export default MiniGraphs;