import React from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import amazon from "../../../../assets/amazon-icon.png";
import shopify from "../../../../assets/shopify.png";
import ebay from "../../../../assets/ebay-logo.png";
import walmart from "../../../../assets/walmart-logo.jpg";
import { deleteStore } from '../../../../services/registrationService';

function StoreModal({display, toggleModal, store, companyKey}) {
  const storeImage = (store) => {
    if (store === "Amazon")
      return (
        <img src={amazon} alt='store-img-amazon' style={{ height: "35px" }} />
      );
    if (store === "Shopify")
      return (
        <img src={shopify} alt='store-img-shopify' style={{ height: "30px" }} />
      );
    if (store === "Ebay")
      return <img src={ebay} alt='store-img-ebay' style={{ height: "30px" }} />;
    if (store === "Walmart")
      return (
        <img src={walmart} alt='store-img-walmart' style={{ height: "30px" }} />
      );
  }
  const openMportal = () => {
      window.location='/mappingPortal'
  }
  const deletestore = async () => {
      const formData = {
          "companyKey":companyKey,
          "marketplace":store.marketPlace,
          "info":
          {
              "toBeDeleted":
              [
                  store.storeName
              ]
          }
      }

      const res = await deleteStore(formData)
      console.log(res.data)
      // if(res.data['result'] === 'success')
      //     window.location = '/settings'
  }
  const archive = async () => {
    console.log('hi')
    const formData = {
      "companyKey":companyKey,
      "marketplace":store.marketPlace,
      "info":
      {
          "toBeArchived":
          [
              store.storeName
          ]
      }
    }

    const res = await deleteStore(formData)
    console.log(res.data)
    // if(res.data['result'] === 'success')
    //       window.location = '/settings'
  }
  return (
    <Modal isOpen={display} toggle={toggleModal} className="store-modal">
        <ModalHeader className='store-modal-header'>
           {storeImage(store.marketPlace)}
        </ModalHeader>
        <ModalBody className='store-modal-body'>
            <h5>Your Store :
              <span>{store.storeName}</span> 
            </h5>

            <div className="actions">
                <div className='mt-4 custom-upload' onClick={openMportal}>
                  <img src={require('../../../../assets/fileUpload.png')} alt='store-img-custom' />
                  Product Custom Upload
                </div>
                <div className="d-flex mt-3">
                  {/* <div className='mt-2 label p-2' onClick={editStore}>
                    <i className='fa fa-pencil-square-o edit-icon mr-2' />
                    Edit
                  </div> */}
                  <div className='mt-2 label p-2' onClick={deletestore}>
                    <i className='fa fa-trash edit-icon mr-2' />
                    Delete
                  </div>
                  <div className='mt-2 label p-2' onClick={archive}>
                    <i className='fa fa-archive edit-icon mr-2' />
                    Archive
                  </div>
                </div>
            </div>            
            <button className='modal-button-cancel' onClick={toggleModal}>
                Cancel
            </button>
        </ModalBody>
    </Modal>
  );
}

export default StoreModal;