import React, {useState} from 'react';
import CustomLinks from './CustomLinks';
import VariantsLink from './VariantsLink';
import SearchBox from '../../../UI Elements/SearchBox';

function ProductInfoLinks(props) {
  const {baseSku, variantSku, selectedMarketPlaces, variants, category} = props

  const [shownVariants, setShownVariants] = useState(variants.slice(0,5))
  const [activeClass, setActiveClass] = useState(false)
  const [showLabel, setShowLabel] = useState('Show More')
  const [searchedValue, setSearchedValue] = useState('')

  const showAllVariants = () => {
    if(showLabel === "Show More"){
      setShownVariants([...variants])
      setShowLabel('Show Less')
    }
    else{
      setShownVariants(variants.slice(0,5))
      setShowLabel('Show More')
    }
  }

  const searchVariants = (e) => {
     setSearchedValue(e.target.value)
  }
  const changeActiveState = () => {
    setActiveClass(!activeClass)
  }
 
  return (
    <div className="product-left_categories">
        {/* Header */}
        <span>
            <h6>PRODUCT INFORMATION</h6>
            <button className="info-btn" onClick={changeActiveState}>PRODUCT INFORMATION
            <i className="fa fa-angle-down"></i></button>
        </span>

        {/* Categories link */}
        <div className={`product-left_types d-flex flex-column pt-2 ${activeClass}`}>
            <CustomLinks 
                link={`/products/pim/${baseSku}/${'base'}`}
                baseSku={baseSku}
                category={'base'}
                label={'Base Info'}
                percent={'66%'}
            />
            <CustomLinks 
                link={`/products/pim/${baseSku}/${'marketplace'}`}
                baseSku={baseSku}
                category={'marketplace'}
                label={`Marketplaces (${selectedMarketPlaces.length})`}
            />
            <CustomLinks 
                link={`/products/pim/${baseSku}/${'variant'}/${variantSku}`}
                baseSku={baseSku}
                sku={variantSku}
                category={'variant'}
                label={`Variants (${variants.length})`}
            />
            
            {category === 'variant' ?
                <SearchBox 
                    searchVariants={searchVariants} 
                    searchedValue={searchedValue}
                /> 
            : null }
            <VariantsLink
                baseSku={baseSku} 
                category={category}
                shownVariants={shownVariants}
                variantSku={variantSku}
                showAllVariants={showAllVariants}
                showLabel={showLabel}
                length={variants.length}
            />
            <CustomLinks 
                link={`/products/pim/${baseSku}/${'images'}`}
                baseSku={baseSku}
                category={'images'}
                label={'Images'}
            />
        </div>

    </div>
  );
}

export default ProductInfoLinks;