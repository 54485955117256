import React from 'react';
import { Image } from 'react-bootstrap';

function NavigationBar({categories, selectedCategory}) {
  let index = categories.indexOf(selectedCategory)
  const setStatus = (item, key) => {
     return selectedCategory === item && index < categories.length-1 ? 'active' :index > key || index===categories.length-1 ? 'done' : '' 
     
  }
  return (
    <div className="navigation-bar d-flex p-1">
        {categories.map((item, key)=> {
           return(
             <div className={`p-2 category ${setStatus(item, key)}`} key={key}>
                   {index > key || index === categories.length-1  ? 
                      <Image src={require('../../../assets/tick.png')}/>
                      : null}
                  {item}
              </div>
           )
        })}
    </div>
  );
}

export default NavigationBar;