import React, { Component } from "react";

class Store extends Component {
  state = {}

  render() {
    const { store, storeImage, openModal} = this.props
    const uploadStatus = store.uploadStatus + "%"
    let storeSynced = false
    if (store.lastSync && store.lastSync.length > 0) 
        storeSynced = true

    return (
        <div className='row settings-store' onClick={() =>openModal(store)}>
            <div className='col-2 settings-img'>
              {storeImage(store.marketPlace)}
            </div>
            <div className='col-2 mt-2 label'>{store.storeName}</div>
            <div className='col-2 mt-2 label text-right'>
              {storeSynced ? "Last Synced" : "Importing"}
            </div>
            <div className='col-3 mt-2 bar'>
              {storeSynced ? (
                <div className='label upload pl-3'>{store.lastSync}</div>
              ) : (
                <React.Fragment>
                  <div className='progress mt-1'>
                    <div
                      className='progress-bar integrate'
                      role='progressbar'
                      style={{ width: uploadStatus }}
                      aria-valuenow={store.uploadStatus}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    ></div>
                  </div>
                  <div className='mt-2 pl-2 label upload status'>
                    {uploadStatus} complete
                  </div>
                </React.Fragment>
              )}
            </div>

            <div className='col-2 pl-4 uploadLabel upload'>
              <span> {store.uploadLabel}</span>
            </div>   
        </div>
    );
  }
}

export default Store;
