import React, { Component } from 'react';
import Image from 'react-bootstrap/Image';
import AllLinks from './AllLinks';
import AccountDiv from './AccountDiv';
import { logout, getUser } from '../../../services/authService';

class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            var_class: {
                dashboard: this.props.linkName === 'dashboard' ? 'active' : 'inactive',
                products: this.props.linkName === 'products' ? 'active' : 'inactive',
                orders: this.props.linkName === 'orders' ? 'active' : 'inactive',
                analytics: this.props.linkName === 'analytics' ? 'active' : 'inactive',
                predictions: this.props.linkName === 'predictions' ? 'active' : 'inactive',
                settings: this.props.linkName === 'settings' ? 'active' : 'inactive',
            },
            email: '',
            userName: '',
            iconLetter: '',
            showUserDiv: false,
        }
        this.changeClass = this.changeClass.bind(this)
        this.container = React.createRef()
        this.logout = this.logout.bind(this) 
    }
    changeClass = (type) => () => {
        if (type === 'dashboard') {
            this.setState({
                var_class: {
                    dashboard: 'active',
                    products: 'inactive',
                    orders: 'inactive',
                    analytics: 'inactive',
                    predictions: 'inactive',
                    settings: 'inactive'
                }
            })
        }
        else if (type === 'products') {
            this.setState({
                var_class: {
                    products: 'active',
                    dashboard: 'inactive',
                    orders: 'inactive',
                    analytics: 'inactive',
                    predictions: 'inactive',
                    settings: 'inactive'
                }
            })
        }
        else if (type === 'orders') {
            this.setState({
                var_class: {
                    orders: 'active',
                    dashboard: 'inactive',
                    products: 'inactive',
                    analytics: 'inactive',
                    predictions: 'inactive',
                    settings: 'inactive'
                }

            })
        }
        else if (type === 'analytics') {
            this.setState({
                var_class: {
                    analytics: 'active',
                    dashboard: 'inactive',
                    products: 'inactive',
                    orders: 'inactive',
                    predictions: 'inactive',
                    settings: 'inactive'
                }
            })
        }
        else if (type === 'predictions') {
            this.setState({
                var_class: {
                    predictions: 'active',
                    dashboard: 'inactive',
                    products: 'inactive',
                    orders: 'inactive',
                    analytics: 'inactive',
                    settings: 'inactive'
                }
            })
        }
        else if (type === 'settings') {
            this.setState({
                var_class: {
                    settings: 'active',
                    home: 'inactive',
                    products: 'inactive',
                    orders: 'inactive',
                    analytics: 'inactive',
                    predictions: 'inactive',
                }

            })
        }
    }
    openUserDiv = () => {
        this.setState({
            showUserDiv: !this.state.showUserDiv
        })
    }
    async componentDidMount() {
        const {userEmail, userName} = await getUser()
        this.setState({
            userName, 
            userEmail,
            iconLetter: userName[0].toUpperCase()
        })
        document.addEventListener("click", this.handleClickOutside)
    }
    componentWillUnmount() {
      document.removeEventListener("click", this.handleClickOutside)
    }
    handleClickOutside=(event) =>{
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({
              showUserDiv: false,
            })
        }
    }
    async logout(){
        logout()
        window.location="/login"
    }
    openSettings(){
        window.location ="/settings/account"
    }
    render() {
        const { iconLetter, showUserDiv, userName, userEmail } = this.state
        const {activeClass, linkName, close} = this.props
        return (
            <nav className={`sidebar ${activeClass}`} >
                <div className={`sidebar-div ${activeClass}`}>
                    {activeClass === 'active' ? 
                        <Image className="close-icon" src={require('../../../assets/close-icon-white.png')} thumbnail onClick={close}/> 
                        : null
                    }
                    <div className="sidebar-header">
                        <Image src={require('../../../assets/lyzer-logo.png')} thumbnail />
                    </div>
                    <AllLinks 
                        linkName = {linkName}
                        activeClass = {activeClass}
                    />
                    <div className="user-icon" onClick={this.openUserDiv} ref={this.container}>
                        <span>{iconLetter}</span>
                    </div>
                </div>
                <AccountDiv 
                    iconLetter = {iconLetter}
                    showUserDiv = {showUserDiv}
                    userEmail = {userEmail}
                    userName = {userName}
                    logout = {this.logout}
                    openSettings={this.openSettings}
                />
            </nav>
        );
    }
}

export default SideBar;