import React, { useReducer } from "react";
import { reducer, addNewUser } from "../../services/registrationService";

const initialState = {
  email: "",
  name: "",
  role: "",
};

const NewUserForm = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleChange = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };
  const handleInvite = async () => {
    try {
      const { email, role, name } = state;

      const formData = {
        companyKey: localStorage.getItem("activeCompany"),
        users: [
          {
            email,
            role,
            userName: localStorage.getItem("userName"),
            name,
          },
        ],
      };
      console.log(JSON.stringify(formData))
      const resp = await addNewUser(formData)

      console.log(resp)
      if(resp.data.result === 'Success'){
          props.notify('success', "Invitation sent")
          props.handleAddUser();
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  const { email, name, role } = state;

  return (
    <div className='row user'>
      <span className='user-pic'></span>
      <div className='col'>
        {" "}
        <input
          type='text'
          name='email'
          value={email}
          onChange={handleChange}
          className='form-control mt-3'
          id='email-newUser'
          placeholder='Email'
        />
      </div>
      <div className='col'>
        {" "}
        <input
          type='text'
          name='name'
          value={name}
          onChange={handleChange}
          className='form-control mt-3'
          id='name-newUser'
          placeholder='Name'
        />
      </div>
      <div className='col'>
        <select
          name='role'
          value={role}
          onChange={handleChange}
          className='form-control mt-3'
        >
          <option label='Roles' disabled />
          <option>Admin</option>
        </select>
      </div>
      <div className='col'>
        <button
          className='integration-button mt-4 ml-1'
          style={{ float: "left", width: "7rem" }}
          onClick={handleInvite}
        >
          Invite
        </button>
      </div>
    </div>
  );
};

export default NewUserForm;
