
export default function(d1){
    let date = d1.getDate()
    let month = d1.getMonth()+ 1
    let year = d1.getFullYear()

    let month2 = month.toString().length === 1 ? '0' + month : month

    let finalStr = date + '-' + month2 + '-' + year
    return finalStr
}