import React from 'react';
import ReactTooltip from 'react-tooltip';

function Title(props) {
  return (
    <ReactTooltip
        id='custom-color-no-arrow' 
        className='custom-color-no-arrow'
        border={true}
        textColor='#7767c1' 
        backgroundColor='#f5f5f5'
        place='bottom'
        effect='float'
        multiline={true}
    />
  );
}

export default Title;