import React from 'react';
import ModalClass from './Modal'
import { Form, Modal, ModalHeader, ModalBody } from "reactstrap";
import { integrate } from '../../../../services/registrationService';
import { toastify } from '../../../../common-functions/notify';

class WalmartModal extends ModalClass {
  state = {
    data : {},
    checkboxes : {}
  }

  componentDidMount(){
    this.setDataFields()
  }

  setDataFields=()=>{
     const {fields} = this.props
     let data = {} 
     let checkboxes = {} 

     for(let i=0; i < fields.length; i++){
         if(!fields[i].Invisibility && fields[i].FieldType === 'input'){
             data[fields[i].name] ={
                ...fields[i],
                value: ""
             }
         }

         else if(!fields[i].Invisibility && fields[i].FieldType === 'checkbox'){            
             checkboxes[fields[i].name] = {
               ...fields[i],
               checked : false
            }
         }
     }
     this.setState({data, checkboxes})
  }

  doSubmit = async () => {
    const { data } = this.state
    const { addStores} = this.props

    this.props.toggleModal()

    try{
      const formData = {
        companyKey: this.props.companyKey,
        marketplace: 'Walmart',
        credentials: {
          [data.storeName.value]: {
            ...this.setCredentials()
          },
        },
      }
      const resp = await integrate(formData)

      if (resp.data.result === "Active") {
        toastify('success', "Successfully Connected", "Your store is active in Walmart now.")
        let object = {
          marketPlace: 'Walmart',
          storeName : data.storeName,
          uploadStatus: 0, //0% initially
          uploadLabel: "Connecting",
          lastSync: "",
        };
        addStores(object, resp.data.processes[0])     
      }
    }
    catch(ex){
       toastify('error', "Oops. Connection failed.", "Please try again later.")
       console.log(ex)
    }
  }

  setCredentials = () => {
    const {data, checkboxes} = this.state

    let obj = {}

    for(let [key, value] of Object.entries(data)){
        obj[key] = value.value
    }
  
    for(let [key, value] of Object.entries(checkboxes)){
      obj[key] = value.checked
    }

    return obj
  }

  render() {
    const {className, isModalOpen, toggleModal} = this.props
    const { errors, data, checkboxes} = this.state
    return (
      <Modal
          isOpen={isModalOpen}
          toggle={toggleModal}
          className={`product-modal ${className}`}
        >
          <ModalHeader className='integration-modal-header'>
            Integrate with Walmart
          </ModalHeader>
          <ModalBody>
            {errors && <p className="error-msg">{errors}</p>}
            <Form className='m-3' onSubmit={this.handleSubmit}>
                {Object.entries(data).map((field, key) => {
                      return this.renderInput(field[1], key)                     
                   })}
                {this.renderCheckbox('Update', checkboxes,  'update', 'Walmart', 2)}
                {this.renderCheckbox('Sync', checkboxes,  'sync', 'Walmart', 2)} 
                
                <div className='modal-buttons mt-3 mb-3'>
                    {this.renderButton('modal-button-cancel', toggleModal, 'Cancel')}
                    {this.renderButton('modal-button-save', null , 'Save')}
                </div>
            </Form>
          </ModalBody>
        </Modal>
    );
  }
}

export default WalmartModal;