import http from "./httpService";

http.setHeader(getToken());

const apiEndPoint = http.pim_app_url + "/register/login";

export async function login(formData) {
  const res = await http.post(apiEndPoint, formData)
  if(res.data.result === 'success'){
    let set = new Set(res.data.companyKeyArray)
    let arr = Array.from(set)
    localStorage.setItem("email", formData.email)
    localStorage.setItem(
      "companyKeyArray",
      JSON.stringify(arr)
    );
    localStorage.setItem("companyArray", JSON.stringify(res.data.companyArray))      
    localStorage.setItem("userName", res.data.userName);
    localStorage.setItem("name", res.data.name);
    localStorage.setItem("token", res.headers["x-auth-token"]);

    setActiveCompany(res.data.active)
  }
  
  return res.data
}

export function setLoginData(data, token){
  localStorage.setItem("email", data.email);
  localStorage.setItem(
    "companyKeyArray",
    JSON.stringify(data.companyKeyArray)
  );
  localStorage.setItem("companyArray", JSON.stringify(data.companyArray))      
  localStorage.setItem("userName", data.userName);
  localStorage.setItem("name", data.name);
  localStorage.setItem("token", token);

  setActiveCompany(data.active)
  return 'success'
}

export function setToken(token) {
    localStorage.setItem("token", token);
}

export function getToken() {
    return localStorage.getItem("token");
}

export function logout() {
    localStorage.clear();
}

export function getUser() {
  return {
    userEmail: localStorage.getItem("email"),
    userName: localStorage.getItem("userName"),
  };
}

export function getActiveCompany() {
  const activeCompany = localStorage.getItem("activeCompany");
  if (activeCompany === "") {
    const companyArray = localStorage.getItem("companyArray");
    return companyArray[0];
  }
  return activeCompany;
}

export function getCompanyArray() {
  return JSON.parse(localStorage.getItem("companyKeyArray"));
}

export function getActiveCompanyInfo(company) {
  const companyInfo = JSON.parse(localStorage.getItem("companyArray"))[company];
  return companyInfo;
}

export function setActiveCompany(company) {
  if(company === ""){
    const companyArray = JSON.parse(localStorage.getItem("companyArray"))
    const arr = Object.keys(companyArray)
    localStorage.setItem("activeCompany", arr[0])
  }   
  else{
    localStorage.setItem("activeCompany", company)
  }
}
