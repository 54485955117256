import React, { Component } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Card, CardImg } from "reactstrap";

const imageMaxSize = 1000000000; //bytes
const acceptedFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
// const acceptedFileTypesArray = acceptedFileTypes.split(",").map(item => {
//   return item.trim();
// });

class ProductImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_arr: this.props.images
    };
    this.handleSave = this.handleSave.bind(this);
    this.checkImage = this.checkImage.bind(this);
  }

  handleDrop = acceptedFiles => {
    acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    let image_arr = this.state.image_arr;
    if (image_arr == null) image_arr = [];
    acceptedFiles.map(file => image_arr.push(file.preview));
    this.setState({ image_arr });
  };

  checkImage() {}
  handleSave() {}
  render() {
    const images = this.state.image_arr;

    return (
      <div className="container-fluid pl-2">
        {/* {console.log(images)} */}
        <div className="row pt-3">
          <div className="col-12 col-md-3 offset-md-9">
            {/* <span className="p-2"><button className="product-image-btns" id="product-add-image" onClick={this.handleSave}>+Add </button></span>
                            <span className="p-2"><button className="product-image-btns" id="product-delete-image" onClick={this.handleSave}>x Delete </button></span> */}
            <span className="p-2" style={{ float: "right" }}>
              <label className="custom-checkbox">
                Select All
                <input type="checkbox" name="all-images" />
                <span className="checkmark"></span>
              </label>
            </span>
          </div>
        </div>
        <div className="row pt-3">
          {images !== null
            ? images.map((item, key) => {
                return (
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 product-card-div" key={key}>
                    <Card className="image-card">
                      {/* <input type="checkbox"/> */}
                      <CardImg
                        top
                        width="100%"
                        src={item}
                        alt="Card image cap"
                      />
                    </Card>
                  </div>
                );
              })
            : null}

          <div
            className={
              images == null
                ? "col-12 product-card-div"
                : "col-12 col-md-4 col-lg-3 col-xl-2 product-card-div"
            }
          >
            <div
              className="new-image-card"
              style={{
                width: "100%",
                height: images == null ? "336px" : "100%"
              }}
            >
              <Dropzone
                onDrop={this.handleDrop}
                accept={acceptedFileTypes}
                maxSize={imageMaxSize}
              >
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={classNames("dropzone", {
                        "dropzone--isActive": isDragActive
                      })}
                    >
                      <input {...getInputProps()} name="file" />
                      {isDragActive ? (
                        ""
                      ) : (
                        <div className="pt-5">
                          <button style={uploadBtn}>Upload Images</button>
                          <p className="pt-2" style={dropzoneTxt}>
                            or drop images here
                          </p>
                        </div>
                      )}
                    </div>
                  );
                }}
              </Dropzone>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const uploadBtn = {
  color: "#4B3B94",
  fontWeight: "normal",
  fontSize: "16px",
  textDecoration: "none",
  textAlign: "center",
  background: "#FFFFFF",
  border: "1px solid #4B3B94",
  borderRadius: "6px"
};

const dropzoneTxt = {
  color: " #9E9E9E",
  fontSize: "15px"
};
export default ProductImages;
