import http from "./httpService";

const apiEndPoint = http.pim_app_url + "/notification";

export async function getAllNotifications(formData) {
  const res = await http.post(apiEndPoint+'/getNotification', formData)
  if(res.data.result === 'success')
      return res.data.processes
  return null
}

export async function getProductNotifications(formData) {
  const res = await http.post(apiEndPoint+'/getProducts', formData)
  console.log(res.data)
  if(res.data.result === 'success')
      return res.data.result
  return null
}

export async function getOrderNotifications(formData) {
  const res = await http.post(apiEndPoint+'/getOrders', formData)
  if(res.data.result === 'success')
      return res.data.result
  return null
}

export async function deleteNotification(formData) {
  const res = await http.post(apiEndPoint+'/DeleteNotification', formData)
  if(res.data.result === 'success')
      return res.data.result
  return null
}
