import React from 'react'
import Form from './form'
import ct from "countries-and-timezones"
import { registerUser, verifyCompanykey } from '../../services/registrationService'
// import bcrypt from "bcryptjs";

class RegisterForm extends Form {
  state = {
    data : { company : '', companyKey : '', country: '', timeZone: '', email: '', password: '', shopifyData: {}},
    countries : [],
    timezones : [],
    pages : ['page1', 'page2', 'page3'],
    count : 0,
    error : {
      email: "",
      password: "",
      company: "",
      companyKey: "",
      timeZone: "",
    }
 }

 componentDidMount(){
   if(this.props.shopifyData){
        const {shopifyData} = this.props
        let {data} = this.state
        data['company'] = shopifyData['company']
        data['companyKey'] = shopifyData['companyKey']
        data['country'] = shopifyData['country']
        data['timeZone'] = shopifyData['timezone']
        data['email'] = shopifyData['email']
        data['shopifyData'] = shopifyData['response']
        
        let arr = [...Object.values(ct.getAllCountries()).filter(item => item.name === data['country'])[0].timezones]
        let timezones = []
        for(let i=0; i < arr.length; i++){
            timezones.push({
              _id : i,
              name : arr[i]
            })  
        }

        this.setState({ data, timezones })
    }
  
    let countries = ct.getAllCountries()
    countries = Object.values(countries)
    countries.sort((country1, country2) => {
      if (country1.name < country2.name) return -1;
      else if (country1.name > country2.name) return +1;
      else return 0;
    });
    this.setState({ countries })
    
 }

 doSubmit = async () => {
    if(this.state.count === 2){
        try {
            let {
              password,
              companyKey,
              company,
              country,
              email,
              timeZone,
              shopifyData
            } = this.state.data

            // const salt = await bcrypt.genSalt(10);
            // new_password = await bcrypt.hash(password, salt);
    
            const formData = {
              companyKey: companyKey,
              company: company,
              country: country,
              email: email,
              password: password,
              timeZone: timeZone,
              shopifyData : shopifyData
            }
            const resp = await registerUser(formData)
            console.log(resp)
            window.location= '/settings'
        }
        catch(ex){
          console.log(ex)
        }
    }
 }

 page1=()=> {
    return(
      <React.Fragment>
          {this.renderLabel('Company Name')}
          {this.renderInput("company", "Company Name")}
          {this.renderError("company")}

          {this.renderLabel('Company Key')}
          {this.renderInput("companyKey", "Company Key")}
          {this.renderError("companyKey")}
      </React.Fragment>
    )
 }

 page2 = () => {
    const { countries, timezones, data} = this.state
    if(this.props.shopifyData)
      return(
        <React.Fragment>
            {this.renderLabel('Country')}
            {this.renderInput("country", "Country", data.country)}
            {this.renderLabel('Time Zone')}
            {this.renderInput("timeZone", "Time Zone", data.timeZone)}
        </React.Fragment>
      )
    return(
      <React.Fragment>
          {this.renderSelect("country", "Country", countries)}
          {this.renderSelect("timeZone", "Time Zone", timezones)}
      </React.Fragment>
    )
 }

 page3 = () => {
      return(
        <React.Fragment>
            {this.renderLabel('Email')}
            {this.renderInput("email", "Email", "email")}
            {this.renderLabel('Password')}
            {this.renderInput("password", "Password", "password")}
        </React.Fragment>
      )
 }

 generateCompanyKey = (value) => {
    let companyKey = "";
    value = value.trim();
    const arr = value.split(" ");
    if (arr[0] === arr[0].toUpperCase()) companyKey = arr[0];
    else if (arr.length === 1) companyKey = arr[0].substring(0, 3);
    else {
      let matches = value.match(/\b(\w)/g);
      companyKey = matches.join("");
    }
    companyKey = companyKey.toUpperCase();
    return companyKey;
 }

 companyKeyExists = async () => {
   const {companyKey} = this.state.data
   const {error} = this.state
  try {
      const formData = {
        companyKey, 
      }
      const resp = await verifyCompanykey(formData)
      if (resp.data.result === false) {
        const suggestions = resp.data.suggestions.join(", "); //to get the suggested company keys
        error.companyKey = `Company Key already exists. Try ${suggestions}`;
        this.setState({ error });
      } else {
        error.companyKey = ""
        this.setState({ error })
      }
    } catch (ex) {
       console.log(ex);
    }
 }

 getPage = (page) => {
    if(page === 'page1')
      return this.page1()
    else if(page === 'page2')
      return this.page2()
    else if(page === 'page3')
      return this.page3()
 }

 goToNext=()=> {
   const { count } = this.state    
   this.setState({
     count: count+1
   })
 }

 validate=()=>{
    const {error} = this.state
    for(let value of Object.values(error)){
       if(value.length === 0)
          return false
    }
    return true
 }

  render() {
    const { count, pages} = this.state
    return (
      <div className="register-form">
          <h1>Register</h1>
          <form onSubmit={this.handleSubmit}>
              {this.getPage(pages[count])}

            {count === 2 ?
                <div className="btns">
                    {this.renderButton("Register", 'register')}               
                </div>
                :
                <div className="btns">
                    <button className='next' onClick={this.goToNext}>
                        Next
                    </button>               
                </div>
            }
          </form>
          <p className='create-account'>
            Already have an account?{" "}
            <span
              className='clickable'
              onClick={() => window.location='/login'}
            >
              Login
            </span>
          </p>
      </div>
    );
  }
}

export default RegisterForm;