import React from "react";
import PropTypes from "prop-types";

const OrderDetailsLeft = (props) => {
  const {
    createdAt,
    location,
    status,
    marketPlace,
    totalPrice,
    currency,
  } = props.orderDetails;

  const { customer } = props;

  const OrderDetails = [
    { fieldName: createdAt, label: "Created At" },
    { fieldName: status, label: "Status" },
    { fieldName: marketPlace, label: "Marketplace" },
    // { fieldName: location, label: "Location" },
    { fieldName: totalPrice, label: "Total Amount" },
    { fieldName: currency, label: "Currency" },
  ];

  const CustomerDetails = [
    { fieldName: customer.name, label: "Name" }, 
    { fieldName: customer.email, label: "Email ID" },
    { fieldName: customer.phone, label: "Phone No." }
  ];

  const field = (fieldName, label, key) => (
    <div key={key} className='row pb-0'>
      <div className='col-12 col-sm-6'>
        <p className='subheading'>{label}</p>
      </div>
      <div className='col-12 col-sm-6'>
        <p className='subheading-detail' title={fieldName}>
          {fieldName ? fieldName : "N/A"}
        </p>
      </div>
    </div>
  );

  return (
    <div className='order-div-left'>
      <p className='order-heading mt-4'>Order Details</p>
      <div className='order-left-divs mt-4 pb-3'>
        {/* {field(createdAt, "Created At")} */}
        {OrderDetails.map((detail, key) =>
          field(detail.fieldName, detail.label, key)
        )}
      </div>
      <p className='order-heading mt-4'>Customer Details</p>
      <div
        className='order-left-divs mt-4 pb-3'
        style={{ borderBottom: "none" }}
      >
        {/* {field(createdAt, "Created At")} */}
        {CustomerDetails.map((detail, key) =>
          field(detail.fieldName, detail.label, key)
        )}
      </div>
    </div>
  );
};

OrderDetailsLeft.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};

export default OrderDetailsLeft;
