import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Tab, Tabs} from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';

const CustomTabs =({tabArray, onChange, value, handleChange, setComponent})=> {
  return (
    <React.Fragment>
          <CustomTab value={value} onChange={handleChange} variant="fullWidth" aria-label="custom tabs example">
              {tabArray.map((item, key) => {
                  return(
                        <Tab label={item} {...a11yProps(key)} key={key}/>
                  )
              })}
          </CustomTab>
          {tabArray.map((item, key) => {
              return(<TabPanel value={value} index={key} key={key}>
                          {setComponent(item)}
                      </TabPanel>)
          })}
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomTab = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

export default CustomTabs;