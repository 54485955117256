import React from 'react';
import CustomLinks from './CustomLinks';

const VariantsLink =({category, showAllVariants, shownVariants, showLabel, length, baseSku, variantSku}) => {
  return (
    <div className="variant-lists">
        {category === 'variant' ? 
              shownVariants.map((item, key)=> {
                return(
                    <div className={variantSku === item.attributes.variantKey.value ? `variant_links p-1 pl-4 active` : `variant_links p-1 pl-4` } key={key}>
                        <CustomLinks
                          link={`/products/pim/${baseSku}/${'variant'}/${item.attributes.variantKey.value}`}
                          baseSku={baseSku}
                          category={'variant'}
                          label={item.attributes.sku.value}
                        />
                    </div>)
            })
        : null}
        {category === 'variant' & length >= 5 ? <button type="button" onClick={showAllVariants}>{showLabel}</button> : null}
    </div>
  );
}

export default VariantsLink;