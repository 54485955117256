import React from 'react';
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

const CustomLinks = ({linkName, givenLink, image, title, module}) => {
  return (
    <Link to={`/${givenLink}/${module? module : ''}`} params={{module: module ? module: ''}} className='sidebar-a'>
        <li className={linkName === givenLink ? "active" : ""}>            
              <Image src={require('../../../assets/'+image)} thumbnail />
              <span>{title}</span>
        </li>
    </Link>
  );
}

export default CustomLinks;