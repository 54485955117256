import React, {useState, useEffect} from 'react';
import { Doughnut } from "react-chartjs-2"

function DoughnutChart(props) {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const {data : obj} = props
        const sum = obj['total']
        const temp_obj = obj['chartData']
      
        //get percent
        let arr = calculatePercent(temp_obj, sum)
        let labels = []
        let arr2 = []
        let objKeys = [...Object.keys(temp_obj)]
        let percentArr = []

        if(arr.length > 5){
            arr2 = getMaximumValues(1, arr)
            
            for(let i=0; i< arr2.length; i++){
                percentArr.push(arr2[i].value)
            }
            for(let i=0; i < arr2.length -1; i++){
                let index = arr2[i].index                  
                labels.push(objKeys[index])
            }
            labels.push('Others')
        } 
        else if(arr.length <= 5) {
            for(let i=0; i < arr.length; i++){
                if(arr[i] > 0){
                    labels.push(objKeys[i])
                }
                else {
                    arr.splice(i, 1)
                }
                arr2 = [...arr]
            }
            percentArr = [...arr2]
        } 
        percentArr = arrangeValues(percentArr) 
        const finalData = {
            labels : [...labels],
            values : [...percentArr],
        }
        setData(finalData)
        setLoading(false)
    }, [])

    const arrangeValues = (arr) => {
        let diff = arr.reduce((a, b) => a + b) - 100 
        if(diff !== 0){
            let temp = (diff/arr.length).toFixed(2)
            for(let i=0; i < arr.length; i++){
                arr[i] = arr[i] + temp
            }
        }
        return arr
    }
    const calculatePercent =(obj, sum) => {
        
        let percentArray = []

        for(let [key, value] of Object.entries(obj)){
            percentArray.push(Math.round((value['OrderPrice']/sum)*100))
        }
        return percentArray
    }
    const getMaximumValues = (percentCheck, arr) => {
        let temp_arr = []
        let sum =0
        
        for(let i=0; i < arr.length; i++){
            if(arr[i] > percentCheck){
                temp_arr.push({ index: i, value:arr[i]})
                sum += arr[i]
            }
        }

        if(temp_arr.length > 4){
            temp_arr.length = 0
            percentCheck += 1
            return getMaximumValues(percentCheck++, arr)
        }
       
        let finalArr = []
        finalArr = [...temp_arr, {index: temp_arr.length + 1, value: 100 - sum}] 
        return finalArr 
    }

    function setHeight(){
        let width = window.innerWidth

        if(width > 576 & width <= 768){
            return '170px'
        }
        else if(width <= 576){
            return '130px'
        }
        else 
            return '270px'
    }

    return (
        <div className="container-fluid m-3 hoc-doughnut-chart">
            <div className="row hoc-doughnut-chart_row">
                {loading === true ? null :
                <React.Fragment>
                    <div className="col-12">
                        <Doughnut 
                            data={{
                                datasets : [{
                                    data: [...data.values],
                                    backgroundColor: [...props.colors]
                                }],
                                labels: [...data.labels]
                            }}
                            options = {{
                                legend: false 
                            }}
                            height={
                                window.innerWidth > 576 & window.innerWidth <= 768 ? '170px' :
                                window.innerWidth <= 576 ? '130px' : '120px' }/>
                    </div>
                    <div className="col-10 mt-3 offset-1 pl-2 doughnut_data">
                        {data.labels.map((item, key) => {
                            return(
                            <div className="d-flex justify-content-between labels" key={key}>
                                <div className="p-2">
                                    <div className="color-box mt-2" style={{background : props.colors[key]}}></div>
                                </div>
                                <div className="p-2">
                                    {item}
                                </div>
                                <div className="p-2">
                                    {data.values[key]} %
                                </div>
                            </div>)
                        })}
                    </div>
                    
                </React.Fragment>
            } 
            </div>
        </div>
    );
}

export default DoughnutChart;