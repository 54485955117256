import React from 'react';
import { Image } from 'react-bootstrap';

const ProductImage = ({imageUrl})=> {
  return (
    <div className="enlarged-image">
        <Image src={imageUrl} />
    </div>
  );
}

export default ProductImage;