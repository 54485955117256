import React from 'react';

function SearchBox({searchedValue, searchVariants}){
  const search = (e) => {
     searchVariants(e.target.value)
  }
  return (
      <div className="search-variant form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input 
              className="form-control searchbar" 
              type="text" 
              placeholder="Search" 
              name="searchedValue" 
              value={searchedValue} 
              aria-label="Search" 
              onChange={search}
          />
      </div>
  );
}

export default SearchBox;