import React from 'react';

function LoadingContent({width, marginTop, height, marginLeft, marginBottom}) {
  return (
    <div className="loading-content" style={{width : width ? width : null, height : height ? height : null,marginTop: marginTop ? marginTop : null, marginLeft: marginLeft? marginLeft: null,
      marginBottom: marginBottom? marginBottom : null}}>
        
    </div>
  );
}

export default LoadingContent;