import React from 'react';
import amazon from "../../assets/amazon-icon.png";
import shopify from "../../assets/shopify.png";
import ebay from "../../assets/ebay-logo.png";
import walmart from "../../assets/walmart-logo.jpg";
import { Image } from 'react-bootstrap';

function ImportSelection({ importOptions, onImportSelect, onMpSelect, onSkip, onNext}) {
  const mpArr = [{
      label : 'Amazon',
      img : amazon
    },
    {
      label : 'Shopify',
      img : shopify
    },
    {
      label : 'Ebay',
      img : ebay
    },
    {
      label : 'Walmart',
      img : walmart
    }
  ]

  const options = ['Products', 'Orders', 'Customers']
  
  return (
    <div className="container-fluid import-selection">
        <div className="container-fluid center">
            <h6 className="heading1">What would you like to import ?
                <button className="btn-next" onClick={onNext}>Next</button>
                {/* <button className="btn-skip" onClick={onSkip}>Skip</button> */}
            </h6>
            <div className="row import-row">
              {options.map((item)=> {
                 return(
                      <div className="col-12 col-md-4">
                          <label className='container-radio-sort'>
                              {item} 
                              <input
                              type='radio'
                              name='sorting'
                              onChange={() => onImportSelect(item)}
                            />
                            <span className='checkmark-radio-sort' />
                          </label>
                      </div>
                 )
              })}
            </div>
            <h6 className="heading2">Where would you like to import ?</h6>
            <div className="row import-row">
                {mpArr.map((item)=> {
                    return(
                          <div className="col-12 col-md-4 mps" onClick={() =>onMpSelect(item.label)}>
                               <Image src={item.img} thumbnail />
                          </div>
                    )
                  })}
            </div>
        </div>
    </div>
  );
}

export default ImportSelection;