import React from 'react';
import { Image } from 'react-bootstrap';


function GABox({ data, title, image, loading }) {
  return (
    <div className="col-12 col-md-6 col-lg-3 ga_col">
        <h5>
            <Image src={require('./../../../../assets/'+image)} thumbnail/>
            <span>{title}</span>
        </h5>        
        <div className="d-flex flex-column data_points mt-3">
            <div className={`d-flex loading ${loading ? 'active' : ''}`}>
                <div className="child-div flex-fill"></div>
                <div className="child-div flex-fill"></div>
            </div>
            <div className={`d-flex loading ${loading ? 'active' : ''}`}>
                <div className="child-div flex-fill"></div>
                <div className="child-div flex-fill"></div>
            </div> 
            <div className={`d-flex loading ${loading ? 'active' : ''}`}>
                <div className="child-div flex-fill"></div>
                <div className="child-div flex-fill"></div>
            </div>
            <div className={`d-flex loading ${loading ? 'active' : ''}`}>
                <div className="child-div flex-fill"></div>
                <div className="child-div flex-fill"></div>
            </div>     
            { !loading && Object.entries(data).map((item, key)=> {
                return(
                  <div className="d-flex data_points_rows justify-content-around">
                      <div className="p-2 flex-fill label">
                          {item[1].label}
                      </div>
                      <div className="p-2 flex-fill data">
                          {item[1].value}
                      </div> 
                  </div>
                )
            })}
        </div>        
    </div>
  );
}

export default GABox;