import React from "react";
import PropTypes from "prop-types";

const OrderBilling = ({ billing, shipping }) => {
  const shippingAddress = `${shipping.address}, ${shipping.city}, ${shipping.country}`;
  const billingAddress = `${billing.address}, ${billing.city}, ${billing.country}`;

  return (
    <React.Fragment>
      <div className='row address mb-4'>
        <div className='col-12 col-sm-3'>
          <p className='order-heading'>Address</p>
        </div>
      </div>
      <div className='row address-fields mb-4'>
        <div className='col-6 field'>Shipping Address</div>
        <div className='col-6 field'>Billing Address</div>
      </div>
      <div className='row address-fields'>
        <div className='col-6'>{shippingAddress}</div>
        <div className='col-6'>{billingAddress}</div>
      </div>
    </React.Fragment>
  );
};

OrderBilling.propTypes = {
  billing: PropTypes.object.isRequired,
  shipping: PropTypes.object.isRequired,
};

export default OrderBilling;
