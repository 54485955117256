import React from 'react';
import AddMarketPlace from '../../../Modals/AddMarketPlace';
import Image from 'react-bootstrap/Image';

const marketPlaces = require('../../../../assets/AllMarketPlaces.json')

const ProductHeader =({images, title, brand, category, unselectedMarketPlaces, modalShow, onHide, openDiv, setModalShow}) => {
  return (
    <div className="row top_rows2">
        <div className="col-12 col-md-5 col-lg-2">
            <div className="image">
                <Image src={images !== null ?images[0] : null} alt="Card image cap" className="images"/>
            </div>
        </div>
        <div className="col-12 col-md-7 col-lg-10">
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-12 col-md-9 col-lg-8">
                        <h5>{title}</h5>
                        <h6 className="mt-4">{brand.toUpperCase()}</h6>
                        <div className="progress-div">
                            <div className="progress mt-3">
                                <div className="progress-bar" role="progressbar" aria-valuenow="60"
                                aria-valuemin="0" aria-valuemax="100" style={{width : '56%'}}></div>
                            </div>
                        </div>
                        <div className="progress-label">56% Complete</div>
                    </div>
                </div>
                <div className="row mt-3 addMarketPlace">
                    {category === 'marketplace' || category === 'variant' ? 
                        <div className="col-12 col-sm-9 offset-sm-3 col-lg-3 offset-lg-9" style={{textAlign: 'center'}}>
                            <button onClick={setModalShow}>+ New Marketplace</button>
                        </div> : null}

                        {/* modal */}
                        <AddMarketPlace marketPlaces={marketPlaces} unselectedMarketPlaces={unselectedMarketPlaces}
                        modalShow= {modalShow} onHide={onHide} openDiv={openDiv}/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ProductHeader;