import React from "react";

const TableHeader = ({
  defaultFields,
  selectedFields,
  sortIcon,
  searchOption,
  orderStatusOption,
}) => {
  return (
    <thead class='order-column-headings mr-5'>
      <tr>
        <th style={{ width: "30px" }} />
        {defaultFields.map((item, key) => {
          return (
            <th key={key} className='cell'>
              {item}
              {sortIcon(item)}
              {searchOption(item)}
            </th>
          );
        })}

        {selectedFields.map((item, key) => {
          return (
            <th key={key} className='cell'>
              {item}
              {sortIcon(item)}
              {searchOption(item)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
