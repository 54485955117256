import React from "react";

export default function SettingsHeader() {
  return (
    <div className='settings-header'>
      <div className='row lyzer-header'>
        <div className='col-5 col-lg-3'>
          <span className='heading'>Settings</span>
        </div>
      </div>
    </div>
  );
}
