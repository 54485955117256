import React, { useState } from 'react';
import Header from '../common/Header';
import EntryPage from './EntryPage';
import ImportSelection from './ImportSelection';

function MappingPortal({companyKey, notify}) {
    const [selectedImport, setSelectedImport] = useState([])
    const [selectedMp, setSelectedMp] = useState('')
    const [entryAllowed, setEntryStatus] = useState(false)

    const importOptions = [
    {
        label: 'Product Base',
        value:'Product-Base',
        importOption : 'Products',
        mappingStrategy: [{
            label: 'Lyzer Product ID',
            value:'ByID'
            },
            {
                label: 'Base Sku',
                value:'baseSku'
            },
            {
                label: 'Sheet Attributes',
                value: 'ByAttribute'
            }]
    },
    {
        label: 'Product Variant',
        value:'Product-Variant',
        importOption : 'Products',
        mappingStrategy: [{
            label: 'Lyzer Variant ID',
            value:'ByID'
            },
            {
                label: 'Variant Sku',
                value:'BySKU'
            },
            {
                label: 'Sheet Attributes',
                value: 'ByAttribute'
            }]
    },
    {
        label: 'Orders',
        value:'Orders',
        importOption : 'Orders',
        mappingStrategy: [{
            label:'Order ID',
            value: 'ByOrderID' 
        },
        {
            label:'Order No.',
            value: 'orderNo' 
        }]
    },
    {
        label: 'Order Items',
        value: 'Order-Items',
        importOption : 'Orders',
        mappingStrategy: [{label: 'Item ID', value: 'ByItemID'}]
    },
    {
        label: 'Customers',
        value: 'Customers',
        importOption : 'Customers',
        mappingStrategy: [{
            label: 'Customer ID',
            value: 'ByCustomerID'
        },
        {
            label: 'Email',
            value: 'ByCustomerEmail'
        },
        {
            label: 'Phone No.',
            value: 'ByMobile'
        }]
    }]

    const onMpSelect = (data) => {
        setSelectedMp(data)
    }
    
    const onImportSelect = (data) => {
        let arr = importOptions.filter(item => item.importOption === data)
        setSelectedImport([...arr])
    }

    const onSkip = () => {
        setSelectedImport({})
        setSelectedMp('')
        setEntryStatus(true)
    }

    const onNext = () => {
        if(Object.keys(selectedImport).length >0)
            setEntryStatus(true)
        else
            notify('error', "Import Type is required")
    }
    
    return (
        <div className="container-fluid padding mapping-portal">
            {!entryAllowed ?
            <React.Fragment>
                <div className="row mapping-header">                   
                    <Header 
                        title='Mapping Portal'
                        secondaryText={''}
                    />
                </div>
                <div className="row pl-1 mapping-body">                    
                    <ImportSelection 
                        importOptions={importOptions} 
                        onImportSelect={onImportSelect}
                        onMpSelect={onMpSelect}
                        onNext={onNext}
                        onSkip={onSkip}
                    /> 
                </div>
                
            </React.Fragment>
            : <EntryPage 
                selectedImport={selectedImport} 
                selectedMp={selectedMp}
                importOptions={importOptions}
                companyKey={companyKey}
                notify={notify}
            />}
        </div>
    );
}

export default MappingPortal;